import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminList, deleteAdmin } from '../../Redux/Action/SuperAdminAction';
function Admin() {
  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector((state) => state?.adminList?.adminList);

  const [showDelete, setDeleteShow] = useState(false);
  const [del, setDel] = useState('');

  const handleButtonEditClick = (id) => {
    history.push(`/admins/edit/${id}`);
  };
  
  const handleButtonDeleteClick = (id) => {
    dispatch(deleteAdmin(id)).then((res) => {
      dispatch(getAdminList());
      setDeleteShow(false);
    });
  };

  const handledeleteShow = (ror_id) => {
    setDel(ror_id);
    setDeleteShow(true);
  };

  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  const columns = [
    {
      id: 1,
      name: 'FIRST NAME',
      selector: (row) => <b>{row.firstName}</b>,
      sortable: false,
      reorder: true,
    },
    {
      id: 2,
      name: 'LAST NAME',
      selector: (row) => <b> {row.lastName}</b>,
      sortable: false,
      reorder: true,
    },
    {
      id: 3,
      name: 'EMAIL',
      selector: (row) => <b>{row.email}</b>,
      sortable: false,
      reorder: true,
    },
    {
      id: 5,
      name: 'ACTIONS',
      cell: (row) => (
        <>
          <span
            className='edit_delete'
            onClick={() => handleButtonEditClick(row._id)}
          >
            <EditIcon /> Edit
          </span>
          {'     '}
          <span
            className='ml-3 edit_delete'
            // onClick={() => handleButtonDeleteClick(row._id)}
            onClick={() => handledeleteShow(row._id)}
          >
            <DeleteIcon /> Delete
          </span>
        </>
      ),
    },
  ];

  useEffect(() => { dispatch(getAdminList()) }, []);
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title'>Admins</h3>
            </div>
            <div className='col-auto float-right ml-auto'>
              <Link to='/admins/create' className='btn add-btn'>
                {' '}
                Create
              </Link>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <DataTable
                    columns={columns}
                    data={data?.data}
                    defaultSortFieldId={1}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
        <Modal.Header closeButton className='b-none'></Modal.Header>
        <Modal.Body className='p-4 main_modal'>
          <div className='text_del text-center'>
            <h3>Are you sure?</h3>
          </div>
          <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
            <Button
              variant='secondary'
              className='btn btn-primary '
              onClick={handledeleteClose}
            >
              Cancel
            </Button>
            <Button
              variant='danger'
              onClick={() => handleButtonDeleteClick(del)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Admin;
