import moment from 'moment';
import React from 'react';

const Pdf_Report_2 = ({ workCarr, exportData, uniqueContractor, tableexportData, furtherAction, workCarriedOut }) => {
    const arrDesc = []
    const unique = [...new Set(tableexportData.map(item => item.jobType))];
    for (let i = 0; i < unique?.length; i++) {
        arrDesc?.push({ jobType: unique[i], data: tableexportData?.filter((item) => item.jobType == unique[i]) })
    }

    return (
        <>
            <div class="main-table-sec font-family-arial">
                <table cellSpacing={0} cellPadding={0} style={{ width: '210mm' }}>
                    <tbody>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px', textAlign: 'center' }}>
                                <p>{exportData?.job_id?.JobRefNo.toString().length == 1
                                    ? '000' + exportData?.job_id?.JobRefNo
                                    : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                                        ? '00' + exportData?.job_id?.JobRefNo
                                        : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                                            ? '0' + exportData?.job_id?.JobRefNo
                                            : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                                                ? exportData?.job_id?.JobRefNo
                                                : ''} </p>
                                <p>Job Sheet </p>
                            </td>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px', textAlign: 'center' }}>
                                <p >Target completion Date</p>
                                <p >  {moment(exportData?.completedBy).format("DD/MM/YYYY")}</p>
                            </td>
                            <td style={{ fontSize: '14px', color: '#e8582c', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px', textAlign: 'left' }}>
                                <p >Photo ref:</p>
                                <p >Photo reg complete:</p>
                                <p >Certificate No:</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p >Date {moment().format("DD.MM.YYYY")}</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p >Job Sheet No : {exportData?.job_id?.JobRefNo.toString().length == 1
                                    ? '000' + exportData?.job_id?.JobRefNo
                                    : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                                        ? '00' + exportData?.job_id?.JobRefNo
                                        : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                                            ? '0' + exportData?.job_id?.JobRefNo
                                            : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                                                ? exportData?.job_id?.JobRefNo
                                                : ''}</p>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <h3 style={{ fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif !important' }}>Customer Name:</h3>
                                <p>{exportData?.job_id?.clientId?.clientName}</p>
                                <p>{exportData?.job_id?.clientId?.streetAddress}</p>
                                <p>{exportData?.job_id?.clientId?.city}</p>
                                <p>{exportData?.job_id?.clientId?.pinCode}</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Job Ref : {exportData?.job_id?.JobRefNo.toString().length == 1
                                    ? '000' + exportData?.job_id?.JobRefNo
                                    : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                                        ? '00' + exportData?.job_id?.JobRefNo
                                        : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                                            ? '0' + exportData?.job_id?.JobRefNo
                                            : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                                                ? exportData?.job_id?.JobRefNo
                                                : ''}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Flamehold representative on site:</p>
                                {uniqueContractor?.length > 0 ? uniqueContractor?.map((item, i) => {
                                    return (
                                        <>
                                            <p>{item?.chat_id?.section_id?.contractorId?.firstName + " " + item?.chat_id?.section_id?.contractorId?.lastName}</p>
                                        </>
                                    )
                                }) : "No Contractor Available Yet!"}


                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Customer Contact Details:</p>
                                <p>{exportData?.job_id?.clientId?.firstName + " " + exportData?.job_id?.clientId?.lastName} {exportData?.job_id?.clientId?.phone}</p>
                                <p><a href="#" class="pdf_fw_700">{exportData?.job_id?.clientId?.email}</a></p>
                            </td>
                            <td rowSpan={2} colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Asbestos on site? {exportData?.job_id?.asbestosOnSite}</p>
                                <p>FRA Checked? {exportData?.job_id?.fraChecked}</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>{exportData?.job_id?.addressLine1}</p>
                                <p>{exportData?.job_id?.addressLine2}</p>
                                <p>{exportData?.job_id?.pinCode}</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Site Contact Details:</p>
                                <p>{exportData?.job_id?.sitedetail}</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Parking: {exportData?.job_id?.parking}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p className="word-elips">Work to be carried out: {workCarr}</p>
                                {arrDesc?.map((data, index) => {
                                    return (
                                        <>
                                            <p className="word-elips">{data?.jobType}</p>

                                            {data?.data?.map((item, i) => {
                                                return (
                                                    <>
                                                        <p className="word-elips">
                                                            {i + 1}.  {item?.title}
                                                        </p>
                                                    </>
                                                )
                                            })}
                                        </>
                                    )
                                })}


                            </td>
                        </tr>
                        <div className="page-break"></div>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Work carried out including material:</p>
                                <p className="word-elips">{workCarriedOut}</p>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Further Action required:</p>
                                <p className="word-elips">{furtherAction}</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Signature (Flame Hold representative):</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Signature customer representative:</p>
                            </td>
                        </tr>
                        
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p style={{ fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px', textAlign: 'center' }}>Method</p>
                                <p ><span >1.</span>Gain any contractor passes/parking passes, sign in/out of site, meet with clients & be made aware of site safety/emergency procedures.</p>
                                <p ><span >2.	</span>Ensure client is given contractors/sub-contractors office details in case of emergency.</p>
                                <p ><span >3.	</span>Ensure site specific inofrmation is seen e.g. Asbestos Register, restricted access areas etc. & attend any on <br />site training coures as appropriate.</p>
                                <p ><span >4.	</span>Ensure safety of others though appropriate communication/signage during work acitivity and adhere toany site specific rules.</p>
                                <p ><span >5.	</span>Reduce level of disruption to other by carrying out work acitivity during agreed hours</p>
                                <p ><span >6.	</span>Ensure all equipment/tools required for the job are taken to the work area before work is commenced.</p>
                                <p ><span >7.	</span>Fitters to wear safety/protective clothe (e.g overalls, safety hat, safety glasses/goggles, gloves,<br /> safety boots, dust masks (disposable) -  see point 7 on Risk Assessement - as appropriate and in accordance with site specific rules.</p>
                                <p ><span >8.	</span>Ensure the rechargeable tools are used to reduce trailin lead/trip hazards. If electrical power supply is required<br /> the will be from a local source and any leads protected within exclusion zones.</p>
                                <p ><span >9.	</span>Ensure that all equipment used is safe & fit for purpose.</p>
                                <p ><span >10.</span>	Ensure that clients property is protected form dust/spills/damage etc.</p>
                                <p ><span >11.</span>Aall eclectrical equipment has a current test certificate.</p>
                                <p ><span >12.</span>	Reduce risk of injury through appropriate manual handling operations.</p>
                                <p ><span >13.</span>When using products for the first time fillters to familiarise themselves with associated data sheets (COSHH, application instruction etc.)</p>
                            </td>
                        </tr>
                        <div className="page-break"></div>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px', textAlign: 'center' }}>Control measures</p>
                                <p >Specific areas of work/hours/timescales etc. To be agreed with Site Manager in order to minimise disruption to other staff <br />members and general day to day operating procedures within the building.</p>
                                <p >Fitters to familliarise themselves with site layout, emergency arrangement & welfare facilities.</p>
                                <p >Personal protective equipment to be used in accordance with site rules and as appropriate: safety goggles (during operations <br /> where dust or solvent is adjacent)., gloves, normal work wear, safety boots, high vis jackets, dust mass (disposable).</p>
                                <p >Barriers and hazard signs to be used to create an exculusion area where appropriate. Safely store tools and equipement not in use <br /> and remove from use any tools/equipment which are foud to be defective.</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px', textAlign: 'center' }}>Environmental</p>
                                <p >Remove waste from site, take back to base & dispose of in waste skip proved (Waste Carrier Licence No. CBDU173694).</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px', textAlign: 'center' }}>Sequence of works</p>
                                <p >Ensure all equipment/ products are available; Fit products in accordance with instruction; ensure all moving items operate <br /> satisfactorily; fix identification label to product/adjacent structure; take photographs of works & document works clearly & concisely; clear away waste; leave area clean & tidy.</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px', textAlign: 'center' }}>Personnel</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>Name</td>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>Responsibilities </td>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>Signature - FS-999-130721 RA</td>
                        </tr>
                        {uniqueContractor?.length > 0 ? uniqueContractor?.map((item, i) => {
                            return (
                                <>
                                    <tr>
                                        <td>
                                            {item?.chat_id?.section_id?.contractorId?.firstName + " " + item?.chat_id?.section_id?.contractorId?.lastName}
                                        </td>
                                        <td >
                                            {item?.chat_id?.section_id?.jobType}
                                        </td>
                                    </tr>
                                </>
                            )
                        }) : "No Contractor Available Yet!"}
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>This document was prepared by Anita Stokes  </p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Date: {moment().format("DD.MM.YYYY")}</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '20px' }}>
                                <p>Signature:</p>
                            </td>
                        </tr>






                        {/* <tr class="pdf_text_left pdf_bg_color_white">
                        <td >
                            <table cellSpacing={0} cellPadding={0} class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                <tbody>
                                  
                                    <tr>
                                        <td class="pdf_padding_10">
                                            <table cellSpacing={0} cellPadding={0} class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                                <tbody>
                                                    <tr>
                                                        <td >
                                                            <table cellSpacing={0} cellPadding={0} class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                                                <tbody>

                                                                    <tr>
                                                                        <td class="pdf_w_10">
                                                                            <table cellSpacing={0} cellPadding={0} class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                                                                <tbody>

                                                                                    <tr>
                                                                                        <td class="pdf_vertical_align pdf_width100">
                                                                                            <table cellSpacing={0} cellPadding={0} class="pdf_border_collapse pdf_border_light_grey pdf_width100 pdf_text_center second_table">
                                                                                                <thead class="pdf_vertical_align">
                                                                                                    <th class="pdf_fz_14 font18 pdf_fw_600 pdf_vertical_align pdf_padding7">Name</th>
                                                                                                    <th class="pdf_fz_14 font18 pdf_fw_600 pdf_vertical_align pdf_padding7">Responsibilities</th>
                                                                                                    <th class="pdf_fz_14 font18 pdf_fw_400 pdf_vertical_align pdf_padding7 pdf_text_left"><span class="pdf_fw_600">Signature - </span>I have read & understood this document & Risk assessment <span class="pdf_fw_600">FS-999-130721 RA</span></th>
                                                                                                </thead>
                                                                                                <tbody>

                                                                                                    {uniqueContractor?.length>0 ?  uniqueContractor?.map((item, i) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <tr>
                                                                                                                    <td>
                                                                                                                        {item?.chat_id?.section_id?.contractorId?.firstName + " " + item?.chat_id?.section_id?.contractorId?.lastName}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {item?.chat_id?.section_id?.jobType}
                                                                                                                    </td>
                                                                                                                    <td>

                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </>
                                                                                                        )
                                                                                                    }) : "No Contractor Available Yet!"}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </td>


                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </td>

                    </tr>
                    <tr class="pdf_text_left pdf_bg_color_white">
                        <td>
                            <table cellSpacing={0} cellPadding={0} class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                <tbody>
                                    <tr>
                                        <td class="pdf_padding7 pdf_w_601">
                                            <p>This document was prepared by Anita Stokes  </p>
                                        </td>
                                        <td class="pdf_w_40 pdf_padding7 pdf_text_right">
                                            <p>Date: {moment().format("DD.MM.YYYY")}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr class="pdf_text_left pdf_bg_color_white">
                        <td>
                            <table cellSpacing={0} cellPadding={0} class="pdf_width100 pdf_height100 pdf_border pdf_border_spacing">
                                <tbody>
                                    <tr>
                                        <td class="pdf_padding7 pdf_w_601">
                                            <p>Signature:</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr> */}

                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Pdf_Report_2;