import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getAllChatReports, getAllChatReportsForPdf, getAllSectionForPdf, getReportsList } from '../../Redux/Action/SuperAdminAction';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { IMAGE_URL } from '../../config';
import Pdf_report from './Pdf_report';
import Pdf_Report_2 from './Pdf_Report_2';
import Pdf_Report_3 from './Pdf_report_3';
import { PDFExport } from '@progress/kendo-react-pdf';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import JSZip from "jszip";
import { saveAs } from "save-as";
import JSZipUtils from "jszip-utils";
import './pdf.css'


const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')`
 
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const Report_export = () => {

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch()

  const [checkBox, setCheckBox] = useState([]);
  const [exportData, setExportData] = useState();
  const [tableexportData, setTableexportData] = useState();
  const [workCarriedOut, setWorkCarriedOut] = useState();
  const pdfExportComponent = React.useRef(null);
  const pdfExportComponent2 = React.useRef(null);
  const pdfExportComponent3 = React.useRef(null);
  const [furtherAction, setFurterAction] = useState();
  const [workCarr, setWorkCarr] = useState();
  const [uniqueContractor, setUniqueContractor] = useState();

  const reportsData = useSelector((state) => state?.adminList?.GetAllReports?.getsectionlist);
  const productUsed = useSelector((state) => state?.adminList?.reportsForPdf?.data?.jobdetail);

  const defaultAllPdf = useSelector((state) => state?.adminList?.allreportsForPdf?.data?.allSectionPdf);
  const uniqueJobTypes = useSelector((state) => state?.adminList?.GetReportsChat?.data);

  const top100Films = []

  for (let i = 0; i < tableexportData?.length; i++) {
    top100Films.push({ title: tableexportData[i]?.details, jobType: tableexportData[i]?.jobType })
  }

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    multiple: true,
    options: top100Films?.length > 0 && top100Films,
    getOptionLabel: (option) => option.title,
  });

  const handleReportsOverview = (row) => {
    localStorage.setItem("jobNo_PDF", row?.job_id?.JobRefNo);
    localStorage.setItem("chardNo_PDF", row?.chard_number);
    localStorage.setItem("jobType_PDF", row?.jobType);
    localStorage.removeItem("whichPage");
    localStorage.setItem("create_new_report", id);
    history.push(`/report_overview/${row?._id}`)
  }

  const handleReportId = (e) => {
    if (checkBox.includes(e)) {
      const index = checkBox.indexOf(e);
      checkBox.splice(index, 1)
      setCheckBox([...checkBox])
      dispatch(getAllChatReportsForPdf([...checkBox]))
    } else {
      setCheckBox([...checkBox, e]);
      dispatch(getAllChatReportsForPdf([...checkBox, e]))
    }
  }

  //UNIQUE PRODUCTS FOR PDF
  var totalProducts = [], productImages2 = [], productImages = [];

  for (let i = 0; i < productUsed?.length; i++) {
    if (productUsed[i]?.chat_id?.product_id != undefined) {
      totalProducts.push(productUsed[i]);
    }
    if (productUsed[i]?.chat_id?.Image != undefined || productUsed[i]?.chat_id?.Image != '' || productUsed[i]?.chat_id?.Image != null) {
      productImages2.push({ "image": `${IMAGE_URL}${productUsed[i]?.chat_id?.Image}` });
    }
  }

  const key = 'chat_id', key_2 = 'product_id', key_3 = '_id'
  const uniqueChatProducts = [...new Map(totalProducts?.map(item =>[item[key][key_2][key_3], item])).values()];

  //END

  //UNIQUE DEFAULT SECTION & PRODUCTS FOR PDF
  var totalProducts2 = [];

  for (let i = 0; i < defaultAllPdf?.length; i++) {
    if (defaultAllPdf[i]?.chat_id?.product_id != undefined) {
      totalProducts2.push(defaultAllPdf[i]);
    }
    if (defaultAllPdf[i]?.chat_id?.Image != undefined || defaultAllPdf[i]?.chat_id?.Image != '' || defaultAllPdf[i]?.chat_id?.Image != null) {
      productImages.push({ "image": `${IMAGE_URL}${defaultAllPdf[i]?.chat_id?.Image}` });
    }
  }

  const key4 = 'chat_id', key_5 = 'product_id', key_6 = '_id'
  const uniqueChatProducts2 = [...new Map(totalProducts2?.map(item =>[item[key4][key_5][key_6], item])).values()];


  //UNIQUE CONTRACTOR FOR SITE & PDF
  var contractorName = [];

  for (let i = 0; i < defaultAllPdf?.length; i++) {
    if (defaultAllPdf[i]?.chat_id?.section_id?.contractorId?._id != undefined) {
      contractorName.push(defaultAllPdf[i]);
    }
  }

  const key7 = 'chat_id', key_8 = 'section_id', key_9 = 'contractorId', key_10 = '_id';
  const uniqueContractorName = [...new Map(contractorName?.map(item => [item[key7][key_8][key_9][key_10], item])).values()];

  const exportPDFWithComponent = (type) => {
    if (type == "extract_image") {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    } else {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
      if (pdfExportComponent2.current) {
        pdfExportComponent2.current.save();
      }
      if (pdfExportComponent3.current) {
        pdfExportComponent3.current.save();
      }
    }
  };

  const ReportsForPdf = () => {
    exportPDFWithComponent();
  }

  async function downloadPhotos() {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "flamehold.zip";
    var urls = productImages2?.length > 0 ? productImages2 : productImages;

    urls.forEach(function (url) {
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, async function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        const imageBlob = await fetch(url.image).then((response) => response.blob());

        // create a new file from the blob object
        const imgData = new File([imageBlob], `count_${count}.jpg`);

        zip.file(`count_${count}.jpg`, imgData, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      });
    });
  }

  const columns = [
    {
      id: 1,
      name: 'JOB NO.',
      selector: (row) => row?.job_id?.JobRefNo.toString().length == 1
        ? '000' + row?.job_id?.JobRefNo
        : '' || row?.job_id?.JobRefNo.toString().length == 2
          ? '00' + row?.job_id?.JobRefNo
          : '' || row?.job_id?.JobRefNo.toString().length == 3
            ? '0' + row?.job_id?.JobRefNo
            : '' || row?.job_id?.JobRefNo.toString().length == 4
              ? row?.job_id?.JobRefNo
              : '',

    },
    {
      id: 2,
      name: 'ORCHARD NO.',
      selector: (row) => row?.chard_number,
    },
    {
      id: 2,
      name: 'TYPE',
      selector: (row) => row?.jobType
    },

    {
      id: 3,
      name: 'CLIENT',
      selector: (row) => row?.job_id?.clientId?.clientName,
    },
    {
      id: 4,
      name: 'LAST UPDATED',
      selector: (row) => moment(row?.updatedAt).format("Do MMM YY"),
    },
    {
      id: 5,

      selector: (row) => (<div onClick={() => handleReportsOverview(row)} className='dark-blue-anchor'>View</div>),
    },
    {
      id: 6,
      name: 'SHOW IN REPORT',
      selector: (row) => (<>
        <input checked={checkBox?.includes(row?._id) ? true : false} onChange={(e) => handleReportId(e.target.value)} type="checkbox" value={row?._id} id={`html-${row?._id}`} />
        <label for="html"></label>
      </>),
    }
  ];

  useEffect(() => {
    dispatch(getReportsList());
  }, []);

  useEffect(() => {
    const data = reportsData?.filter((item, i) => item?.job_id?._id == id)[0]
    const data2 = reportsData?.filter((item, i) => item?.job_id?._id == id);
    const key = 'contractorId', key_2 = '_id';

    const arrayUniqueByKey = [...new Map(data2?.map(item =>
      [item[key][key_2], item])).values()];


    setUniqueContractor(arrayUniqueByKey);
    setExportData(data)
    setTableexportData(data2);
  }, [id, reportsData, productUsed]);

  useEffect(() => {
    dispatch(getAllChatReports(exportData?._id));
    dispatch(getAllSectionForPdf({ id: exportData?.job_id?._id }))
  }, [exportData]);

  return (
    <>
      {top100Films?.length > 0 &&
        <div className='page-wrapper'>
          {/* <!-- Page Wrapper --> */}

          {/* <!-- Page Content --> */}
          <div class='content container-fluid'>
            {/* <!-- Page Header --> */}

            <div class='page-header'>
              <div class='row align-items-center d-flex justify-content-between'>
                <div class='col-md-6'>
                  <h3 class='page-title'>Create New Report</h3>
                </div>
                <div class='col-md-6'>
                  <div class='job_no'>
                    <p class="mb-0 job_no_title">Job Number : {exportData?.job_id?.JobRefNo.toString().length == 1
                      ? '000' + exportData?.job_id?.JobRefNo
                      : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                        ? '00' + exportData?.job_id?.JobRefNo
                        : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                          ? '0' + exportData?.job_id?.JobRefNo
                          : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                            ? exportData?.job_id?.JobRefNo
                            : ''}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="page_inner_details">
              <div class="row">
                <div class="col-md-4">
                  <div class="client_inner_details">
                    <h4 class="job_no_title">Client Details</h4>
                    <p class="mb-0 common_para">{exportData?.job_id?.clientId?.clientName}</p>
                    <p class="mb-0 address_details common_para">{exportData?.job_id?.clientId?.streetAddress}</p>

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="client_inner_details">
                    <h4 class="job_no_title">Contact Details </h4>
                    <p class="mb-0 common_para">{exportData?.job_id?.clientId?.firstName + " " + exportData?.job_id?.clientId?.lastName} </p>
                    <p class="mb-0 common_para">{exportData?.job_id?.clientId?.phone}</p>
                    <p class="mb-0 common_para">{exportData?.job_id?.clientId?.email}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="client_inner_details">
                    <h4 class="job_no_title">Target Completion Date</h4>
                    <p class="mb-0 common_para">{moment(exportData?.completedBy).format("DD/MM/YYYY")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-3 page_inner_details_row">
              <div class="row ">
                <div class="col-md-4">
                  <div class="client_inner_details">
                    <h4 class="job_no_title">Site Details</h4>
                    <p class="mb-0 common_para site_details">{exportData?.job_id?.sitedetail}</p>

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="client_inner_details">
                    <h4 class="job_no_title">Site Details </h4>
                    <p class="mb-0 common_para site_details">Asbestos on site?  {exportData?.job_id?.asbestosOnSite}</p>
                    <p class="mb-0 common_para site_details">FRA Checked? {exportData?.job_id?.fraChecked}</p>
                    <p class="mb-0 common_para site_details">Parking: {exportData?.job_id?.parking}</p>
                    <p class="mb-0 common_para site_details">Site Contact Details: 0151 728 9494</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="client_inner_details">
                    <h4 class="job_no_title">Flame Hold Representative(s):</h4>
                    {uniqueContractorName?.length > 0 ? uniqueContractorName?.map((item, i) => {
                      return (
                        <>
                          <p class="mb-0 common_para">{item?.chat_id?.section_id?.contractorId?.firstName + " " + item?.chat_id?.section_id?.contractorId?.lastName}</p>
                        </>
                      )
                    }) : "No Contractor Available Yet!"}

                  </div>
                </div>
              </div>
            </div>


            {/* TABLE START */}
            <div class="table_section pt-4">
              <h4 class="job_no_title">Sections</h4>
              <div class='table-responsive reports-align-center'>
                <DataTable
                  columns={columns}
                  data={tableexportData}
                  defaultSortFieldId={1}
                  sortIcon={<SortIcon style={{ fill: "white" }} />}
                  pagination={reportsData?.length > 0 ? 1 : ""}
                />

                {/* <table class='  datatable table table-stripped mb-0'>
                    <thead class='custom_color'>
                      <tr>
                        <th>JOB NO.</th>
                        <th>ORCHARD NO.</th>
                        <th>TYPE</th>
                        <th>CLIENT</th>
                        <th>LAST UPDATED</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportsData &&
                        reportsData?.map((item, i) => {
                          return (
                            <>
                              <tr>
                                <td>{item?.job_id?.JobRefNo}</td>
                                <td>{item?.chard_number}</td>
                                <td>{item?.jobType}</td>
                                <td>{item?.job_id?.JobRefNo}</td>
                                <td>{item?.updatedAt}</td>
                                <td><NavLink to="/report_overview" className='blue-anchor'>View</NavLink></td>
                                <td>

                                  <input onChange={(e) => handleReportId(e.target.value)} type="checkbox" value={item?._id} id="html1" />
                                  <label for="html"></label>

                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table> */}
                {
                  uniqueJobTypes?.length > 0 &&
                  <div
                    style={{
                      position: 'absolute',
                      left: '-100000px',
                      top: 0,
                    }}
                  >
                    <div class='table-responsive hidden-table'>
                      <table class=' table table-stripped mb-0'>
                        <thead class='custom_color'>
                          <tr>
                            <th>Section</th>
                            <th>Image</th>
                            <th>Type</th>
                            <th>Product Used</th>

                            <th>Comments</th>

                          </tr>
                        </thead>
                        <tbody>
                          {uniqueJobTypes?.map((item, i) => {
                            return (
                              <>
                                <tr class='text-middle-custom'>
                                  <td class='text-center'>{i + 1}</td>
                                  <td class='table-td-img'>
                                    <img
                                      src={`${IMAGE_URL}${item?.chat_id?.Image}`}
                                      alt=''
                                    />
                                  </td>
                                  <td>
                                    {item?.chat_id?.type == 1
                                      ? 'Job Started'
                                      : item?.chat_id?.type == 4
                                        ? 'General'
                                        : item?.chat_id?.type == 3
                                          ? 'Product Used'
                                          : item?.chat_id?.type == 5 &&
                                          'Job Completed'}
                                  </td>
                                  <td>{item?.chat_id?.product_id?.name}</td>
                                  <td class='comment-scroll-hide'>
                                    <div class='comment-part-td slimscroll'>
                                      {item?.Comment}
                                    </div>
                                  </td>
                                  {/* <td style={{cursor:"pointer"}} onClick={() =>
                                    history.push(
                                      `/report_view_edit_section/${item._id}`
                                    )
                                  }>
                                    <div class='blue-text-an'>
                                      Edit
                                    </div>
                                  </td>
                                   */}

                                  {/* <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleShow(item)}
                                  >
                                    <div class='blue-text-an'>Edit</div>
                                  </td>
                                  <td
                                    class='arrow_end'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => deleteReport(item?._id)}
                                  >
                                    <i class='la la-trash'></i>
                                  </td> */}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>


                }
              </div>
            </div>


            {/* TABLE END */}

            <div class="products_used">
              <div class="row">
                <div class="col-md-6">
                  <h4 class="job_no_title">Products Used</h4>
                  {uniqueChatProducts2?.length > 0 ? uniqueChatProducts2?.map((item, i) => {
                    return (
                      <>
                        <p class="mb-0 common_para ">{i + 1} x {item?.chat_id?.product_id?.name}</p>
                      </>
                    )
                  }) : "No Products Found!"}


                </div>
              </div>
            </div>
            <div class="input_row">
              <div class="input_fields mb-4">
                <div class="row">
                  <div class="col-md-6 d-flex flex-column">
                    {/* <label class="job_no_title mb-3">Work to be carried out</label> */}
                    {top100Films?.length > 0 &&
                      <NoSsr>

                        <div>
                          <div className='width_100' {...getRootProps()}>
                            <Label {...getInputLabelProps()} className="job_no_title mb-3">Work to be carried out</Label>
                            <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
                              {value.map((option, index) => (
                                <Tag label={option.title} {...getTagProps({ index })} />
                              ))}

                              <input {...getInputProps()} value={workCarr} onChange={(e) => setWorkCarr(e.target.value)} className="form-control" />
                            </InputWrapper>
                          </div>
                          {groupedOptions.length > 0 ? (
                            <Listbox {...getListboxProps()}>
                              {groupedOptions.map((option, index) => (
                                <li {...getOptionProps({ option, index })}>
                                  <span>{option.title}</span>
                                  <CheckIcon fontSize="small" />
                                </li>
                              ))}
                            </Listbox>
                          ) : null}
                        </div>
                      </NoSsr>
                    }
                  </div>
                </div>
              </div>
              <div class="input_fields  mb-4">
                <div class="row">
                  <div class="col-md-6 d-flex flex-column">
                    <label class="job_no_title mb-3">Work Carried Out Including Material</label>
                    <textarea onChange={(e) => setWorkCarriedOut(e.target.value)} class="form-control"></textarea>
                  </div>
                </div>
              </div>
              <div class="input_fields mb-4">
                <div class="row">
                  <div class="col-md-6 d-flex flex-column">
                    <label class="job_no_title mb-3">Further Action Required</label>
                    <textarea onChange={(e) => setFurterAction(e.target.value)} class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='anchor_tag '>
              <a href="#" class="btn add-btn ms-3" onClick={downloadPhotos} >Extract Images</a>
            </div>
            <div className='anchor_tag'>
              <a href="#" class="btn add-btn" onClick={
                () => {
                  ReportsForPdf();
                }
              } >Create Report</a>
            </div>
            <PDFExport
              ref={pdfExportComponent}
              paperSize='auto'
              forcePageBreak=".page-break"
              padding={40}
              fileName={`${exportData?.job_id?.JobRefNo.toString().length == 1
                ? '000' + exportData?.job_id?.JobRefNo
                : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                  ? '00' + exportData?.job_id?.JobRefNo
                  : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                    ? '0' + exportData?.job_id?.JobRefNo
                    : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                      ? exportData?.job_id?.JobRefNo
                      : ''}  Report`}
              author='KendoReact Team'
            >
              <div
                style={{
                  position: 'absolute',
                  left: '-100000px',
                  top: 0,
                }}
              >
                <Pdf_report defaultAllPdf={defaultAllPdf} exportData={exportData} uniqueJobTypes={uniqueJobTypes} productUsed={productUsed} />
              </div>
            </PDFExport>
            <PDFExport
              ref={pdfExportComponent2}
              paperSize='auto'
              forcePageBreak=".page-break"
              padding={40}
              fileName={`${exportData?.job_id?.JobRefNo.toString().length == 1
                ? '000' + exportData?.job_id?.JobRefNo
                : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                  ? '00' + exportData?.job_id?.JobRefNo
                  : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                    ? '0' + exportData?.job_id?.JobRefNo
                    : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                      ? exportData?.job_id?.JobRefNo
                      : ''}   Job Sheet`}
              author='KendoReact Team'
            >
              <div
                style={{
                  position: 'absolute',
                  left: '-100000px',
                  top: 0,
                }}
              >
                <Pdf_Report_2 workCarr={workCarr} productUsed={productUsed} furtherAction={furtherAction} workCarriedOut={workCarriedOut} tableexportData={value} exportData={exportData} uniqueContractor={uniqueContractorName} />
              </div>
            </PDFExport>
            <PDFExport
              ref={pdfExportComponent3}
              paperSize='auto'
              forcePageBreak=".page-break"
              padding={40}
              fileName={`${exportData?.job_id?.JobRefNo.toString().length == 1
                ? '000' + exportData?.job_id?.JobRefNo
                : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                  ? '00' + exportData?.job_id?.JobRefNo
                  : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                    ? '0' + exportData?.job_id?.JobRefNo
                    : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                      ? exportData?.job_id?.JobRefNo
                      : ''}   Certificate`}
              author='KendoReact Team'
            >
              <div
                style={{
                  position: 'absolute',
                  left: '-100000px',
                  top: 0,
                }}
              >
                <Pdf_Report_3 workCarriedOut={workCarriedOut} uniqueChatProducts={uniqueChatProducts} exportData={exportData} />
              </div>
            </PDFExport>
          </div>
        </div>

      }
    </>
  )
}

export default Report_export