import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {
  addTimeSheet,
  getAddTimeData,
  getcontractorjobrefno,
  getContractorList,
} from '../../Redux/Action/SuperAdminAction';
import { toast } from '../../components/ToastContainer/Toast';

function DayDetail(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [show, setshow] = useState(false);
  const [create_time, handleCreatedAt] = useState('');
  const [end_time, handleUpdatedAt] = useState('');
  const [sec_id, setSecId] = useState('');
  const [JobSections, setJobSections] = useState([]);
  const [rangeStart, setRangeStart] = useState('');
  const [calculateTime, setCalculateTime] = useState('');


  var timeParts = create_time.split(':');
  var created_at_time = +timeParts[0] * (60000 * 60) + +timeParts[1] * 60000;

  var timeParts2 = end_time.split(':');
  var updated_at_time = +timeParts2[0] * (60000 * 60) + +timeParts2[1] * 60000;

  const contractorListData = useSelector((state) => state?.adminList?.contractorList?.data);
  const jobNumber = useSelector((state) => state?.adminList?.GetAddTimeData?.total);

  const handleDispatchAddTime = (data) => {
    dispatch(getAddTimeData(data))
  };

  const handleDispatchJobs = (data) => {

    const jobSections = jobNumber?.totalSection?.filter((item) => item?.job_id?._id == data);
    setJobSections(jobSections);
  }

  const addAdditionalTime = () => {
    if (isNaN(created_at_time) || isNaN(updated_at_time) || sec_id == '') {
      toast.error('Please select all the fields.');
    } else {
      dispatch(
        addTimeSheet({
          createdAt: created_at_time + parseInt(new Date(
            rangeStart
          ).getTime()),
          updatedAt: updated_at_time + parseInt(new Date(
            rangeStart
          ).getTime()),
          job_section_id: sec_id,
        })
      );

      history.push('/time_sheet');
    }
  };

  const selectStartDate = (d) => {
    setRangeStart(d);
  };

  const handleUpdatedAtfun = (data) => {
    var timeParts2 = data.split(':');
    var updated_at_time = +timeParts2[0] * (60000 * 60) + +timeParts2[1] * 60000;

    function msToTime(s) {
      var ms = s % 1000;
      s = (s - ms) / 1000;
      var secs = s % 60;
      s = (s - secs) / 60;
      var mins = s % 60;
      var hrs = (s - mins) / 60;

      return hrs + 'hr' + ' ' + mins + 'min';
    }
    setCalculateTime(msToTime(updated_at_time - created_at_time))
    handleUpdatedAt(data);
  }

  useEffect(() => {
    dispatch(getContractorList())
  }, [])

  useEffect(() => {
    // dispatch(getcontractorjobrefnoData(props.match.params.secId));
    dispatch(getcontractorjobrefno(props.match.params.contractorId));
    // dispatch(
    //   timesheetlistdata(
    //     props.match.params.contractorId,
    //     props.match.params.date
    //   )
    // );
  }, [props.match.params.contractorId, props.match.params.date]);
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title'>Add Time</h3>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12 mb-3'>
                    <h4>New Time Sheet</h4>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label for=''>Contractor</label>

                      <select
                        name='clientId'
                        className='form-control'
                        onChange={(e) => handleDispatchAddTime(e.target.value)}
                      >
                        {contractorListData?.length > 0 && (
                          <>
                            <option value='' className='d-none'>
                              Select Contractor
                            </option>

                            {contractorListData?.map((contractorList) => (
                              <>
                                <option value={contractorList?._id}>
                                  {contractorList?.firstName + " " + contractorList?.lastName}
                                </option>
                              </>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Date Range</label>
                      <DatePicker
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        selectsStart
                        selected={rangeStart}
                        placeholderText='Start Date'
                        //  startDate={rangeStart}
                        //  endDate={rangeEnd}
                        onChange={selectStartDate}
                      // format='yyyy-MM-dd'
                      />
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Recorded Start Time</label>
                      <input
                        type='time'
                        onChange={(e) => handleCreatedAt(e.target.value)}
                        className='form-control'
                      />
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Recorded End Time</label>
                      <input
                        type='time'
                        className='form-control'
                        onChange={(e) => handleUpdatedAtfun(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Total Time</label>
                      <div className='total-time-view form-control'>
                        {calculateTime}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label for=''>Client</label>

                      <select
                        name='clientId'
                        className='form-control'
                      // onChange={(e) => handleDispatchAddTime(e.target.value)}
                      >
                        {
                          <>
                            <option value='' className='d-none'>
                              Select Client
                            </option>

                            {jobNumber?.arrayUniqueByKey?.map((contractorList) => (
                              <>
                                <option value={contractorList?._id}>
                                  {contractorList?.job_id?.clientId?.clientName}
                                </option>
                              </>
                            ))}
                          </>
                        }
                      </select>
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Job Number</label>

                      <select
                        name='clientId'
                        className='form-control'
                        onChange={(e) => handleDispatchJobs(e.target.value)}
                      >
                        {jobNumber?.arrayUniqueByKey?.length > 0 ? (
                          <>
                            <option value='' className='d-none'>
                              Select Job No.
                            </option>

                            {jobNumber?.arrayUniqueByKey?.map((clientList) => (
                              <>
                                <option value={clientList?.job_id?._id}>
                                  {clientList?.job_id?.JobRefNo}
                                </option>
                              </>
                            ))}
                          </>
                        ) : (
                          <option value='' className='d-none'>
                            Select Job No.
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Orchard Number</label>

                      <select
                        name='jobtype'
                        className='form-control'
                      //  onChange={(e) => setSecId(e.target.value)}
                      >
                        <option value='' className='d-none'>
                          (Optional)
                        </option>
                        {jobNumber?.arrayUniqueByKey?.map((data) => (
                          <>
                            <option value={data?._id}>{data?.chard_number}</option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label for=''>Select Section Id</label>

                      <select
                        name='section_id_data'
                        className='form-control'
                        onChange={(e) => setSecId(e.target.value)}
                      >
                        <option value='' className='d-none'>
                          Select Section id
                        </option>
                        {JobSections?.map((data) => (
                          <>
                            <option value={data?._id}>{data?._id}</option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>


                  <div className='col-md-12 mt-5'>
                    <div className='text-center'>
                      <Button
                        onClick={() => addAdditionalTime()}
                        className='btn-new'
                      >
                        {' '}
                        Add additional time
                      </Button>
                    </div>
                  </div>
                  {/* <div className='col-6'>
                    <div className='form-group'>
                      <label for=''>Recorded Start Location</label>
                      <input type='text' className='form-control' />
                    </div>
                  </div>

                  <div className='col-6'>
                    <div className='form-group'>
                      <label for=''>Recorded End Location</label>
                      <input type='text' className='form-control' />
                    </div>
                  </div> */}

                  {/* <div className='col-12 '>
                    <div className='text-right'>
                      <Button
                        onClick={() => setshow(!show)}
                        className='btn-new'
                      >
                        {' '}
                        <i className='fa fa-plus'></i> Add More
                      </Button>
                    </div>
                  </div> */}
                </div>
                {show ? (
                  <>
                    <div className='row'>
                      <div className='col-md-12 mb-3'>
                        <h4>Recorded in App</h4>
                      </div>
                      <div className='col-6'>
                        <div className='form-group'>
                          <label for=''>Job Sheet Number</label>
                          <input type='text' className='form-control' />
                        </div>
                      </div>

                      <div className='col-6'>
                        <div className='form-group'>
                          <label for=''>Total Time</label>
                          <input type='date' className='form-control' />
                        </div>
                      </div>

                      <div className='col-6'>
                        <div className='form-group'>
                          <label for=''> Start Time</label>
                          <input type='date' className='form-control' />
                        </div>
                      </div>

                      <div className='col-6'>
                        <div className='form-group'>
                          <label for=''>End Time</label>
                          <input type='date' className='form-control' />
                        </div>
                      </div>

                      <div className='col-6'>
                        <div className='form-group'>
                          <label for=''>Mileage</label>
                          <input type='text' className='form-control' />
                        </div>
                      </div>

                      <div className='col-12 '>
                        <div className='text-right'>
                          <Button className='btn-new'> Done</Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default DayDetail;
