import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import {
  deleteClient,
  getFaq,
  deleteFaq,
} from "../../Redux/Action/SuperAdminAction";
function Faqs() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [faqs, setFaqs] = useState([])

  const handleButtonEditClick = (row) => {
    history.push(`/edit-faq/${row?._id}?question=${row?.question}&answer=${row?.answer}`);
  };

  const [showDelete, setDeleteShow] = useState(false);
  const [del, setDel] = useState("");

  const handledeleteShow = async (id) => {
    try {
      await dispatch(deleteFaq(id))
      let filteredData = faqs.filter((res) => res?._id !== id)
      setFaqs(filteredData)

    } catch (error) {
      console.log(error);
    }
  };

  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  const handleButtonDeleteClick = (id) => {
    dispatch(deleteClient(id));
    handledeleteClose(false);
  };

  const columns = [
    {
      id: 1,
      name: "Question ",
      selector: (row) => <b>{row?.question || 'N/A'}</b>,

      reorder: true,
    },
    {
      id: 2,
      name: "Answer",
      selector: (row) => (
        <b>{row?.answer || 'N/A'}</b>
      ),
      reorder: true,
    },

    {
      id: 3,
      name: "ACTIONS",

      cell: (row) => (
        <>
          <span
            className="cell-right d-flex justify-content-end"
            onClick={() => handleButtonEditClick(row)}
          >
            {" "}
            Edit
          </span>
          {"     "}
          <span
            className="cell-right d-flex justify-content-start"
            style={{ color: "red" }}
            onClick={() => handledeleteShow(row._id)}
          >
            {" "}
            Delete
          </span>
          {"     "}
        </>
      ),
    },
  ];

  const getFaqs = async () => {
    try {
      const res = await dispatch(getFaq())
      setFaqs(res.data)
    } catch (error) {
      console.log(error);
    }
  }
  
  useEffect(() => {
    getFaqs()
  }, [])
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Faqs</h3>
            </div>
            <div className="col-auto float-right ml-auto">
              <Link to="/add-faq" className="btn add-btn">
                Create
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className=" card-table flex-fill">
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={faqs}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
        <Modal.Header closeButton className="b-none"></Modal.Header>
        <Modal.Body className="p-4 main_modal">
          <div className="text_del text-center">
            <h3>Are you sure?</h3>
          </div>
          <div className="d-flex pb-2 width_cus mt-4 justify-content-around">
            <Button
              variant="secondary"
              className="btn btn-primary "
              onClick={handledeleteClose}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleButtonDeleteClick(del)}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Faqs;
