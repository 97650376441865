import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { category, productCreate } from '../../Redux/Action/SuperAdminAction';
import { useFormik } from 'formik';
import * as Yup from 'yup';
function AddProduct() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(category());
  }, []);

  const categorydata = useSelector((state) => state.adminList.GetCATEGORY);

  const formik = useFormik({
    initialValues: {
      name: '',
      lable: '',
      category: '',
      status: '',
      stock: '',
      price: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is Required'),
      lable: Yup.string().required('Lable is Required'),
      status: Yup.string().required('Status is Required!'),
      stock: Yup.string().required('Stock is Required'),
      price: Yup.string().required('Price is Required'),
      category: Yup.string().required('category is Required'),
    }),
    onSubmit: (values) => {
      dispatch(productCreate(values, history));
    },
  });

  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header in second-page-header '>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title '>
                Product Information
              </h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>
                    Use a permanent address where you can recieve mail.
                  </span>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  {' '}
                  Name <span className='text-danger'>*</span>
                </label>

                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='name'
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p style={{ color: 'red' }}>{formik.errors.name}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Label <span className='text-danger'>*</span>
                </label>

                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='lable'
                  value={formik.values.lable}
                />
                {formik.touched.lable && formik.errors.lable ? (
                  <p style={{ color: 'red' }}>{formik.errors.lable}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Category <span className='text-danger'>*</span>
                </label>
                <select
                  name='category'
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id=''
                >
                  <option value=''>Please select category</option>
                  {categorydata?.data?.map((categorydata) => (
                    <>
                      <option value={categorydata._id}>
                        {categorydata?.categoryname}
                      </option>
                    </>
                  ))}
                </select>
                {formik.touched.category && formik.errors.category ? (
                  <p style={{ color: 'red' }}>{formik.errors.category}</p>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Status <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='text'
                  name='status'
                  value={formik.values.status}
                />

                {formik.touched.status && formik.errors.status ? (
                  <p style={{ color: 'red' }}>{formik.errors.status}</p>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Stock <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='text'
                  name='stock'
                  value={formik.values.stock}
                />

                {formik.touched.stock && formik.errors.stock ? (
                  <p style={{ color: 'red' }}>{formik.errors.stock}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Price <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='text'
                  name='price'
                  value={formik.values.price}
                />

                {formik.touched.price && formik.errors.price ? (
                  <p style={{ color: 'red' }}>{formik.errors.price}</p>
                ) : null}
              </div>
            </div>
            <div className='border-t-c'>
              <div className='col-md-12'>
                <div className='mt-3'>
                  <button
                    className='btn custom_btn  mr-3 btn-md'
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn btn-primary btn-md'
                    disabled={
                      !formik.isValid ||
                      (Object.keys(formik.touched).length === 0 &&
                        formik.touched.constructor === Object)
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddProduct;
