import { combineReducers } from 'redux';
import loaderReducer from './Reducer/loaderReducer';
import superadminReducer from './Reducer/superAdminReducer';
import userSigninReducer from './Reducer/AuthReducer';
const reducer = combineReducers({
  loading: loaderReducer,
  userSigninReducer: userSigninReducer,
  adminList: superadminReducer,
});
export default reducer;
