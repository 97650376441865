import React, { useEffect } from 'react';
import { getReportChatById } from '../../Redux/Action/SuperAdminAction';
import { useDispatch } from 'react-redux';

const Report_view_edit_sec = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getReportChatById(props.match.params.id));
    }, [props.match.params.id]);

    return (
        <>
            {/* <!-- Page Wrapper --> */}
            <div class='page-wrapper'>
                {/* <!-- Page Content --> */}
                <div class='content container-fluid'>
                    {/* <!-- Page Header --> */}

                    <div class='page-header'>
                        edit
                    </div>
                </div>
                {/* <!-- /Page Content --> */}
            </div>
            {/* <!-- /Page Wrapper --> */}
        </>
    )
}

export default Report_view_edit_sec;