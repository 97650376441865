export function msToTime(createdAt) {
    var date = new Date(createdAt); // some mock date
    var milliseconds = date.getTime();
    let hours = (milliseconds / (1000 * 60 * 60)).toFixed(1);
    return Number(hours);
}
export const getJobRefDigits = {
    1: "000",
    2: "00",
    3: "",
    4: "0",
}