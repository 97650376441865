import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { category, editPRODUCT, getproductbyid } from '../../Redux/Action/SuperAdminAction';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Editproduct() {

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const categorydata = useSelector((state) => state.adminList.GetCATEGORY);

  const formik = useFormik({
    initialValues: {
      name: '',
      lable: '',
      category: '',
      status: '',
      stock: '',
      Price: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is Required'),
      lable: Yup.string().required('Lable is Required'),
      status: Yup.string().required('Status is Required!'),
      stock: Yup.string().required('Stock is Required'),
      Price: Yup.string().required('Price is Required'),
      category: Yup.string().required('category is Required'),
    }),
    onSubmit: (values) => {
      values.id = id;
      dispatch(editPRODUCT(values, history));
    },
  });

  useEffect(() => { dispatch(category()) }, []);

  useEffect(() => {
    dispatch(getproductbyid(id)).then((prodcut) => {
      const fields = ['name', 'lable', 'category', 'status', 'stock', 'Price'];
      fields.forEach((field) =>
        formik.setFieldValue(field, prodcut[field], false)
      );
    });
  }, [id]);
  
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header in second-page-header '>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title '>
                Product Information
              </h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>
                    Use a permanent address where you can recieve mail.
                  </span>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  {' '}
                  Name <span className='text-danger'>*</span>
                </label>

                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='name'
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p style={{ color: 'red' }}>{formik.errors.name}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Label <span className='text-danger'>*</span>
                </label>

                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='lable'
                  value={formik.values.lable}
                />
                {formik.touched.lable && formik.errors.lable ? (
                  <p style={{ color: 'red' }}>{formik.errors.lable}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Category <span className='text-danger'>*</span>
                </label>
                <select
                  name='category'
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id=''
                >
                  <option>Please select category</option>
                  {categorydata?.data?.map((categorydata) => (
                    <>
                      <option
                        value={categorydata._id}
                        selected={categorydata._id == formik.values.category}
                      >
                        {categorydata?.categoryname}
                      </option>
                    </>
                  ))}
                </select>
                {formik.touched.category && formik.errors.category ? (
                  <p style={{ color: 'red' }}>{formik.errors.category}</p>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Status <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='text'
                  name='status'
                  value={formik.values.status}
                />

                {formik.touched.status && formik.errors.status ? (
                  <p style={{ color: 'red' }}>{formik.errors.status}</p>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Stock <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='text'
                  name='stock'
                  value={formik.values.stock}
                />

                {formik.touched.stock && formik.errors.stock ? (
                  <p style={{ color: 'red' }}>{formik.errors.stock}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Price <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type='text'
                  name='Price'
                  value={formik.values.Price}
                />

                {formik.touched.Price && formik.errors.Price ? (
                  <p style={{ color: 'red' }}>{formik.errors.Price}</p>
                ) : null}
              </div>
            </div>
            <div className='border-t-c'>
              <div className='col-md-12'>
                <div className='mt-3'>
                  <button
                    className='btn custom_btn  mr-3 btn-md'
                    onClick={() => history.push('/products/list')}
                  >
                    Cancel
                  </button>
                  <button className='btn btn-primary btn-md'>Save</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Editproduct;
