import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { NotificationsList, getClinetList, getContractorList } from '../../Redux/Action/SuperAdminAction';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import loader from '../../components/assets/img/loader.svg';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';

const Archieve_notify = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [dateRange, setDateRangeValue] = useState([]);

  const [filter, setFilter] = useState({
    client: undefined,
    contractor: undefined,
    range: undefined,
    message: undefined,
    notification_type: undefined
  });

  const notifications = useSelector((state) => state?.adminList?.notify?.archive);
  const notification_length = useSelector((state) => state?.adminList?.notify?.archive_length);
  const clientName = useSelector((state) => state.adminList.clientList);
  const contractorList = useSelector((state) => state?.adminList?.contractorList);

  const handlePageClick = event => setPage(event.selected + 1);

  const handleFilter = (e, type) => {
    if (e.key == 'Enter') {
      return dispatch(NotificationsList({ page: 1, filter: { ...filter, ['message']: e.target.value } }));
    }
    if (type == 'range') {
      setFilter({ ...filter, [type]: e });
      dispatch(NotificationsList({ page: 1, filter: { ...filter, [type]: e } }));
    } else {
      let { value, name } = e.target;
      setFilter({ ...filter, [name]: value })
      if (type != 'message') {
        dispatch(NotificationsList({ page: 1, filter: { ...filter, [name]: value } }));
      }

      setPage(1)
    }
  }

  useEffect(() => {
    dispatch(NotificationsList({ page, filter }));
  }, [page]);

  useEffect(() => {
    dispatch(getClinetList());
    dispatch(getContractorList());
  }, [])

  return (
    <>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header '>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title '>Archived Notifications</h3>
              </div>
            </div>
          </div>
          <div className='page-header bnone'>
            <div class='row align-items-center d-flex '>
              <div className='col-md-2'>
                <div className='search-input-in'>
                  <input
                    name="message"
                    type='text'
                    onChange={(e) => handleFilter(e, 'message')}
                    onKeyDown={(e) => handleFilter(e)}
                    class='form-control border-l-none'
                    placeholder='Keyword Search..'
                    value={filter?.message}
                  />
                </div>
              </div>
              <div className='col-md-2'>
                {/* <select className='select-c-in'>
                <option>Job Created Date </option>
                <option>Job Created Date </option>
                <option>Job Created Date </option>
              </select> */}
                <DateRangePicker
                  name="range"
                  placeholder="Select Date Range"
                  value={dateRange}
                  onChange={(e) => handleFilter(e, 'range')}
                  cleanable={true}
                />
              </div>
              <div className='col-md-2'>
                <select
                  name='client'
                  className='form-control select-c-in'
                  onChange={(e) => handleFilter(e)}
                >
                  <option value='' >
                    Select Client
                  </option>
                  {clientName?.data?.map((clientList) => (
                    <>
                      <option value={clientList._id}>
                        {clientList?.clientName}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              <div className='col-md-2'>
                <select
                  name='contractor'
                  className='form-control select-c-in'
                  onChange={(e) => handleFilter(e)}
                >
                  <option value='' >
                    Select Contractor
                  </option>
                  {contractorList?.data?.map((clientList) => (
                    <>
                      <option value={clientList._id}>
                        {clientList?.firstName + " " + clientList?.lastName}
                      </option>
                    </>
                  ))}
                </select>
              </div>
              <div className='col-md-2'>
                <select
                  name='notification_type'
                  className='form-control select-c-in' placeholder='Notification Type'
                  onChange={(e) => handleFilter(e)}
                >

                  <option value='' >Notification Type</option>
                  <option value={1}>Status Change</option>
                  <option value={2}>Message</option>
                </select>
              </div>

              <div className='col-md-2'>
                <button onClick={() => window.location.reload()} className='btn add-btn reset-btn-outline'>
                  {' '}
                  Reset Filters
                </button>
              </div>
            </div>
          </div>

          {
            notifications?.length > 0 ?
              notifications?.map((item, i) => {
                return (
                  <>
                    {item?.message != '' && item?.message != undefined && (
                      <div className='row'>
                        <div className='col-md-12'>
                          {/* <div className='date-notification-label'>Today</div> */}
                          <div className='notification-card'>
                            <HashLink
                              to={item?.holiday_req == "true" ? `/holiday` : `/jobs/jobsdetail/${item?.job_id}#${item?.section_id}`}
                            >
                              <div className='row d-flex align-items-center'>
                                <div className='col-md-2'>
                                  <div className='date-notification-text-page'>

                                    <p className='m-0'>
                                      {moment(item?.createdAt).format('D MMM YYYY')}{' '}
                                      <br />
                                      {moment(item?.createdAt).format('h:mm a')}
                                    </p>
                                  </div>
                                </div>
                                <div className='col-md-5'>
                                  <div className='notification-text-page'>
                                    {item?.message}
                                  </div>
                                </div>
                                <div className='col-md-4'>
                                  <div className='notification-text-page'>
                                    {item?.jobInfo?.clientId?.clientName}
                                  </div>
                                </div>
                                <div className='col-md-1'>
                                  <div className='notification-side-img-bell'>
                                    <svg
                                      class='MuiSvgIcon-root notifications-h'
                                      focusable='false'
                                      viewBox='0 0 24 24'
                                      aria-hidden='true'
                                    >
                                      <path d='M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z'></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </HashLink>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })
              :
              notifications?.length == 0 ?
                <div className='datanotfound'><p>No results found</p></div>
                :
                <div className='loader_center'><img src={loader} /></div>
          }

          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={notification_length / 10}
            previousLabel="< Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>
    </>
  );
};

export default Archieve_notify;
