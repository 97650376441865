import React, { useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Button, Modal } from 'react-bootstrap';
import User from '../assets/img/svg-img/user.svg';
import pause from '../assets/img/svg-img/pause.svg';
import accept from '../assets/img/svg-img/accepted.svg';
import jobdone from '../assets/img/svg-img/job_done.svg';
import jobstarted from '../assets/img/svg-img/job_started.svg';
import Lightbox from 'react-image-lightbox';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'react-image-lightbox/style.css';
import { useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import {
  viewJob,
  getChatList,
  getCompleteForms,
  getProducts,
  getSubForms,
  getdetailofjob,
  latestActivityData,
} from '../../Redux/Action/SuperAdminAction';
import socketio from 'socket.io-client';
import SubForm from './SubForm';
import { IMAGE_URL } from '../../config';
import Maping from '../../Maping';
import { getJobRefDigits, msToTime } from '../../utils/commonFiles';

const moment2 = require('moment-timezone');
const socket = socketio.connect(process.env.REACT_APP_SOCKET_URL);

function ViewJob(props) {
  const $ = window.$;
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [chatShow, setchatShow] = useState('');
  const [chatList, setChatList] = useState([]);
  const [chatpopup, setchatPopup] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [firstTime, setFirstTime] = useState();
  const [imageGallery, setimageGallery] = useState([]);
  const [qrcodePopup, setqrcodePoppup] = useState(false);
  const [qrcodeImage, setqrcodeImage] = useState(false);
  const [allSubForm, setSeeAllSubForm] = useState(false);
  const [sectionId, setsectionId] = useState(false);
  const [ajChat, setAjChat] = useState(undefined);
  const [indexValue, setIndexValue] = useState();
  const [writeMsg, setWriteMsg] = useState(false);
  const [start_lat, setLat] = useState();
  const [start_long, setLong] = useState();
  const pdfExportComponent = React.useRef(null);
  const [showDelete, setDeleteShow] = useState(false);
  const [eventName, setEventName] = useState();
  const [type, setType] = useState();
  const [confirmData, setConfirmData] = useState();
  let url = process.env.REACT_APP_SOCKET_URL

  const GetSingleJob = useSelector((state) => state?.adminList?.GetSingleJob);
  const getLatestActivity = useSelector((state) => state?.adminList?.getLatestActivity?.data);
  const qr_code = useSelector((state) => state.adminList.GetQrCodes);
  const completeForms = useSelector((state) => state?.adminList?.getCompleteForms?.data);
  const getSubForms_data = useSelector((state) => state?.adminList?.GetSubFormByParams?.data);

  const handledeleteShow = (e, type, data) => {
    setEventName(e);
    setType(type);
    setConfirmData(data);
    setDeleteShow(true);
  };

  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  const handleMessageChat = (e, section_id) => {
    e.preventDefault();
    var data = {
      section_id,
      message: document.getElementById('textValue').value,
      id: localStorage.getItem('userId'),
      type: 2,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime()
    };
    setchatShow(section_id);
    if (data.message == '') {
      alert('Please Enter Message');
    }
    if (data.message && data.section_id) {
      socket.emit('new_message', data);
      // handleMessage(section_id)
    }
  };

  const handleJobStatus = (e, type, detail) => {
    e.preventDefault();
    if (type == "pause" || type == "complete") {
      var data = {
        admin_type:type == "pause" ? 8 : type == "complete" && 5,
        section_id: detail?._id,
        message: type == "pause" ? "Paused Job" : type == "complete" && "Complete Job",
        id: localStorage.getItem('userId'),
        type: type == "pause" ? 8 : type == "complete" && 5,
        status: type == "pause" ? 4 : type == "complete" && 5,
        created_at: Date.now(),
        updated_at: Date.now()
      };
      if (data.message && data.section_id) {
        socket.emit('new_message', data);
        dispatch(viewJob(id));
      }
    } else if (type == "paused") {
      alert('Your job is paused. Please restart job from android side.');
    }
    setDeleteShow(false);
  };

  const handleInputChangeMessage = () => {
    const value = document.getElementById('textValue').value;
  };

  const handleButtonEditClick = () => {
    dispatch(getdetailofjob(id));
    history.push(`/jobs/edit/${id}`);
  };

  var inc = 0;

  for (let i = 0; i < getLatestActivity?.length; i++) {
    if (getLatestActivity[i].jobstatus == 1) {
      inc = inc + 1;
    }
    if (getLatestActivity[i].jobstatus == 2) {
      inc = inc + 1;
    }
  }

  var latestActivityArr = [];

  {
    getLatestActivity?.length &&
      getLatestActivity?.map((jobstatuscheck) => {
        return (
          <>
            {jobstatuscheck?.timesheet?.length > 0 &&
              jobstatuscheck?.timesheet?.map((item2, index) => {
                const differ = item2?.created_at
                jobstatuscheck.sortTimesheet = differ;
                return (
                  latestActivityArr.push({ jobstatuscheck, timeData: item2, sortTimesheet: item2?.created_at })
                );
              })
            }

          </>

        );
      })

    GetSingleJob?.length &&
      GetSingleJob?.map((jobstatuscheck) => {
        //  jobstatuscheck.sortTimesheet = Date.parse(jobstatuscheck?.createdAt);
        return (
          latestActivityArr.push({ jobstatuscheck, sortTimesheet: Date.parse(jobstatuscheck?.createdAt) })
        );
      })
  }

  const handleImageIndex = (index) => {
    setisOpen(!isOpen);
    setIndexValue(index);
  }

  latestActivityArr?.sort(function (a, b) {
    return new Date(b?.sortTimesheet) - new Date(a?.sortTimesheet);
  });

  const handleDropdown = () => {
    $(".rotate").toggleClass("down");
  }

  const exportPDFWithComponent = (type) => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const ReportsForPdf = () => {
    exportPDFWithComponent();
  }

  const TextChatShow = () => {
    return (
      <>
        {chatShow != undefined && (
          <>
            <div className='form-group'>
              <textarea
                id='textValue'
                name='message'
                autofocus
                onChange={() => handleInputChangeMessage()}
                className='form-control inpt_txtarea'
                cols='30'
                rows='4'
              ></textarea>
            </div>
            <div class='msg-sent-btn'>
              {' '}
              <button onClick={(e) => handleMessageChat(e, chatShow)}>
                Send Message
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  const Message = (data, sectionChat) => {
    const login_userid = localStorage.getItem('userId');
    if (writeMsg == true) {
      let jobstatuscheck = data?.jobstatuscheck?.filter((item) => item.section == chatShow);
      var contractorchat = {
        jobstatuscheck: jobstatuscheck[0]?.data,
        sectionChat: data?.sectionChat
      }
    } else {
      var contractorchat = data
    }
    return (
      <>
        <div className='msg-output'>
          {contractorchat?.jobstatuscheck != undefined &&
            contractorchat.jobstatuscheck &&
            contractorchat.jobstatuscheck?.map((chatShowMap) => (
              <>
                {chatShowMap?.sender_id != login_userid ?
                  (
                    chatShowMap?.section_id == chatShow &&
                    <div className='row mb-2'>
                      { }
                      <div className='chatdisply_user_left'>
                        <div className='main_left_side'>
                          <div className='user_img_data_left'>
                            <div className='profile-placeholder'>
                              {/* <img src={user_profile} alt='' /> */}
                              {/* <div className='placeholder-profile-name'>AS</div> */}
                              {chatShowMap?.sender_name != '' && (
                                <div className='user-name mt-0'>
                                  {`${chatShowMap?.sender_name?.split(" ")[0] == undefined ? "" : chatShowMap?.sender_name?.split(" ")[0]?.charAt(0).toUpperCase()}${chatShowMap?.sender_name?.split(" ")[1] == undefined ? "" : chatShowMap?.sender_name?.split(" ")[1]?.charAt(0).toUpperCase()}`}
                                </div>
                              )}
                            </div>
                            <div className='user_name_show'>
                              {/* <h4 className='m-0'>
                              {chatShowMap?.sender_name != '' && (
                                <div className='user-name'>
                                  {chatShowMap?.sender_name}
                                </div>
                              )}
                            </h4> */}
                              <p className='m-0'>
                                {moment(chatShowMap?.msg_time).format('D MMM YYYY')}{' '}
                                <br />
                                {moment(chatShowMap?.msg_time).format('h:mm a')}
                              </p>
                            </div>
                          </div>
                          <div className='message_item_show '>
                            <div className='msg-show'  >{chatShowMap?.type == 8 && 'Job Paused :'} {chatShowMap?.mesg || ''}</div>
                            <div
                              class='img-colum img-colum_left'
                              key={chatShowMap?._id}
                            >
                              {chatShowMap?.image == '' ||
                                chatShowMap?.image == undefined ? (
                                ''
                              ) : (
                                <img
                                  id='myModal'
                                  // onClick={() => setisOpen(!isOpen)}
                                  src={`${chatShowMap?.image.includes('amazonaws') ? chatShowMap?.image : IMAGE_URL + chatShowMap?.image}`}
                                  alt=''
                                />
                              )}
                            </div>
                          </div>

                          {/* <div className='comme_item'>
                              <div className='comment-time'>
                                {chatShowMap.msg_time}
                                Commented {moment(chatShowMap?.msg_time).fromNow()}{' '}
                               
                              </div>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    chatShowMap?.section_id == chatShow &&
                    <div className='row mb-2'>
                      <div className='chatdisply_user_right'>
                        <div className='main_left_side '>
                          {/* <div className='comme_item'>
                              <div className='comment-time'>
                                Commented {moment(chatShowMap?.msg_time).fromNow()}{' '}
                              </div>
                            </div> */}
                          <p className='m-0 font-11'>
                            {moment(chatShowMap?.msg_time).format('D MMM YYYY')}{' '}
                            <br />
                            {moment(chatShowMap?.msg_time).format('h:mm a')}
                          </p>
                          <div className='message_item_show message_item_right'>
                            <div className='msg-show'>{chatShowMap?.mesg || ''}</div>
                            <div class='img-colum ' key={chatShowMap?._id}>
                              {chatShowMap?.image == '' ||
                                chatShowMap?.image == undefined ? (
                                ''
                              ) : (
                                <img
                                  id='myModal'
                                  // onClick={() => setisOpen(!isOpen)}
                                  src={`${chatShowMap?.image.includes('amazonaws') ? chatShowMap?.image : IMAGE_URL + chatShowMap?.image}`}
                                  alt=''
                                />
                              )}
                            </div>
                          </div>
                          <div className='user_img_data_right'>
                            <div className='profile-placeholder'>
                              {/* <div className='placeholder-profile-name'>AS</div> */}
                              {/* <img src={user_profile} alt='' /> */}
                              {chatShowMap?.sender_name != '' && (
                                <div className='user-name mt-0'>
                                  {`${chatShowMap?.sender_name?.split(" ")[0]?.charAt(0).toUpperCase()}${chatShowMap?.sender_name?.split(" ")[1] == undefined ? "" : chatShowMap?.sender_name?.split(" ")[1]?.charAt(0).toUpperCase()}`}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </>
            ))}

          <TextChatShow />
        </div>
      </>
    );
  };

  const Picture = (viewPicture) => {
    const pictureArrHandle = [];
    {
      viewPicture.jobstatuscheck != undefined ? (
        viewPicture.jobstatuscheck?.map((picture, main_i) => (
          picture?.image != '' && picture?.image && (
            pictureArrHandle.push(picture)
          )
        ))
      ) : (
        <></>
      )
    }

    return (
      <>

        <div class='img_gallary_pictures'>
          {viewPicture.jobstatuscheck != undefined ? (
            pictureArrHandle?.map((picture, main_i) => (
              <>
                {(
                  <>
                    <div className='card mb-2'>
                      <div className='card-body p-2'>
                        <div className='row align-items-center'>
                          <div className='col-md-3'>
                            <div class='img-colum-pict' key={picture?.chat_id}>
                              <img
                                className='img-fluid'
                                id='myModal'
                                onClick={() => handleImageIndex(main_i)}
                                src={`${picture?.image.includes('amazonaws') ? picture?.image : IMAGE_URL + picture?.image}`}
                                alt=''
                              />
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className='text_name_user'>
                              <h3 className='mb-1'>
                                {' '}
                                {picture?.type == 1
                                  ? 'Job Started'
                                  : picture?.type == 4
                                    ? 'General'
                                    : picture?.type == 3
                                      ? 'Product Used'
                                      : picture?.type == 5 && 'Job Completed'}
                              </h3>
                              <p className='mb-1'> {picture?.product_name}</p>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div class='descr_user_data' key={picture?.chat_id}>
                              {picture?.mesg}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ))
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };
  const FormTable = (props) => {
    return (
      <>
        <table className='product_table border-none-flame'>
          {allSubForm == false
            ? props?.formdata?.map((item, i) => {
              const getSubFormsData = (form_id, section_id) => {
                dispatch(getSubForms(form_id, section_id));
                setsectionId(section_id);
                setSeeAllSubForm(true);
              };

              return (
                <>
                  {props?.formdata[i]?.section_id == props?.section_data && (
                    <div className='colal_form'>
                      <div class='accordion' id='accordionExample'>
                        <div class='card mb-2 form-card-flame '>
                          <div
                            class='card-header d-flex justify-content-between align-items-center'
                            id='headingOne'
                          >
                            <h2 class='mb-0 '>
                              <button
                                onClick={() =>
                                  getSubFormsData(
                                    item?.form_id?._id,
                                    item?.section_id
                                  )
                                }
                                class=' black btn btn-link btn-block text-left p-0 flame-btn'
                                type='button'
                                data-toggle='collapse'
                                data-target='#collapseOne'
                                aria-expanded='true'
                                aria-controls='collapseOne'
                              >
                                {item?.form_id?.fromname}
                              </button>
                            </h2>

                            <p className='arrow_right m-0'>
                              {' '}
                              <i
                                class='fa fa-angle-right'
                                aria-hidden='true'
                              ></i>{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })
            : props?.section_data == sectionId && (
              <SubForm
                allSubForm={getSubForms_data && getSubForms_data}
                section_id_data={props?.section_data}
                setSeeAllSubForm={setSeeAllSubForm}
              />
            )}
        </table>
      </>
    );
  };

  const ProductsUsed = (viewPicture) => (
    <>
      {viewPicture?.jobstatuscheck != undefined ? (
        viewPicture?.jobstatuscheck?.map((product) => (
          <>
            {product?.product_name != '' && product?.product_name && product?.type == 3 && (
              <>
                <div className='card mb-2'>
                  <div className='card-body p-2'>
                    <div className='row align-items-center'>
                      <div className='col-md-3'>
                        <div class='img-colum-pict'>
                          <img
                            className='img-fluid'
                            id='myModal'
                            onClick={() => setisOpen(!isOpen)}
                            src={`${product?.image.includes('amazonaws') ? product?.image : IMAGE_URL + product?.image}`}
                            alt=''
                          />
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='text_name_user'>
                          <h3 className='mb-1'>Products Used</h3>
                          <p className='mb-1'> {product?.product_name}</p>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div class='descr_user_data'>{product?.mesg}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ))
      ) : (
        <></>
      )}
    </>
  );

  // const ProductsUsed = (viewPicture) => (
  //   <>
  //     {viewPicture.jobstatuscheck != undefined ? (
  //       viewPicture.jobstatuscheck?.map((product) => (
  //         <>
  //           {/* <table className='product_table'> */}
  //           {product?.product_name != '' && product?.product_name && (
  //             <>
  //               {/* <tr className='product_tr'>
  //                   <td className='product_td bg-table' >{product?.product_name}</td>
  //                 </tr> */}

  //               <div >
  //                 <div className='table_picture'>
  //                   <div className='item_pic'>
  //                     <table class="table custom_table_picture">

  //                       <tbody>
  //                         <tr>

  //                           <td><img className='img_picture_set' src={`https://rukminim1.flixcart.com/image/800/960/kxaq7ww0/fabric/8/f/j/no-2-35-m-unstitched-na-romeo-trijal-fab-original-imag9sy2zdk8v9xa.jpeg?q=50`} alt="no img" /></td>
  //                           <td>{product?.product_name}</td>
  //                           <td>loureum ipsdum</td>
  //                         </tr>

  //                       </tbody>
  //                     </table>
  //                   </div>
  //                 </div>
  //               </div>

  //             </>
  //           )}
  //           {/* </table> */}
  //         </>

  //       ))
  //     ) : (
  //       <></>
  //     )}

  //   </>
  // );

  const handleClose = () => setqrcodePoppup(false);

  const handleQrcodePopup = (qrImage) => {
    setqrcodePoppup(true);
    setqrcodeImage(qrImage);
  };

  function handleMessage(job_section) {
    setchatShow(job_section)
    dispatch(getChatList(job_section)).then((res) => {
      var imagedata = [];
      setChatList([res]);
      res?.forEach((element) => {
        if (element?.image != undefined && element?.image) {
          imagedata.push(
            `${element?.image.includes('amazonaws') ? element?.image : IMAGE_URL + element?.image}`
          );
        }
      });
      setimageGallery(imagedata);
    });
  }

  useEffect(() => {
    if (ajChat != undefined) {
      handleMessage(chatShow && chatShow);
    }
  }, [ajChat]);

  useEffect(() => {
    setchatShow(id);
    setAjChat(id);
    if (chatShow !== undefined) {
      setchatPopup(true);
      setFirstTime("first")
    }
    dispatch(viewJob(id));
    dispatch(getCompleteForms());
    dispatch(getProducts());
    dispatch(latestActivityData(id));
  }, [id]);

  useEffect(() => {
    // const socket = socketio.connect(process.env.REACT_APP_SOCKET_URL);
    // socket.on('connect_error', (err) => {
    //   console.log(err.message, 'connect_error :) socket');
    // });
    // socket.on('connect', () => {
    //   console.log(socket.id, 'conected id :) socket'); //Doesn't enter here
    // });
    socket.on('chat_list', (payload) => {
      if (payload?.section_id) {
        handleMessage(payload?.section_id)
      }
      if (id == payload[0]?.job_id) {
        setWriteMsg(true)
        setChatList([payload]);
      }
    });
  }, []);

  useEffect(() => {
    GetSingleJob?.length > 0 && ReportsForPdf()
  }, [GetSingleJob])

  return (
    <>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header '>
            <div className='row align-items-end'>
              <div className='col-8'>
                <h3 className='page-title second-page-title'>
                  {/* {GetSingleJob[0]?.job_id?.JobRefNo} */}
                  {GetSingleJob[0]?.job_id?.JobRefNo.toString().length <= 4 ? getJobRefDigits[GetSingleJob[0]?.job_id?.JobRefNo.toString().length] + GetSingleJob[0]?.job_id?.JobRefNo : ''}
                  {/* {GetSingleJob[0]?.job_id?.JobRefNo.toString().length == 1
                    ? '000' + GetSingleJob[0]?.job_id?.JobRefNo
                    : '' ||
                      GetSingleJob[0]?.job_id?.JobRefNo.toString().length == 2
                      ? '00' + GetSingleJob[0]?.job_id?.JobRefNo
                      : '' ||
                        GetSingleJob[0]?.job_id?.JobRefNo.toString().length == 3
                        ? GetSingleJob[0]?.job_id?.JobRefNo
                        : '' ||
                          GetSingleJob[0]?.job_id?.JobRefNo.toString().length == 4
                          ? '0' + GetSingleJob[0]?.job_id?.JobRefNo
                          : ''} */}
                </h3>
                <ul className='cusom_ul'>
                  <h3 className='m-0 font18'>
                    {GetSingleJob[0]?.job_id?.clientId?.clientName}
                  </h3>
                  <p className='m-0'>
                    <strong>Target Completion date(s);</strong>
                  </p>
                  {GetSingleJob?.length &&
                    GetSingleJob?.map((contractor) => {
                      return (
                        <span>
                          {contractor?.jobType} work carried by{' '}
                          <strong>
                            {
                              contractor?.contractorId?.map((item, i) => {
                                return (
                                  item?.firstName == undefined
                                    ? 'No contractor assigned'
                                    : item?.firstName + ' ' + item?.lastName + ', by'
                                )
                              })
                            }
                          </strong>{' '}
                          {msToTime(contractor?.completedBy) > 24
                            ? ' ' +
                            moment(contractor?.completedBy).format('D MMM YYYY')
                            : moment(contractor?.completedBy).fromNow()}
                        </span>
                      );
                    })}
                </ul>
              </div>
              <div class='col-4'>
                <div
                  class='d-flex text-right justify-content-end'

                >
                  <Button onClick={() => history.push(`/report_export/${id}`)} className='blue-btn opacity-down drk_btn'>Create Report</Button>
                  <Button className='blue-btn opacity-down drk_btn'>Mark as Complete</Button>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-7 col-lg-6 col-md-6'>
              <div className='card m-0'>
                <div className='card-header d-flex justify-content-between justify-center align-items-center'>
                  <h5 className='m-0'> Job informations</h5>
                  <h5 className='m-0' style={{ cursor: 'pointer' }}>
                    <span
                      className='cursor-pointer'
                      onClick={() => handleButtonEditClick()}
                    >
                      <EditIcon style={{ marginLeft: '5px' }} /> Edit
                    </span>
                  </h5>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-xl-6'>
                      <div className='form-group-custom'>
                        <label for=''>Site info</label>
                        <p className='m-0'>
                          {GetSingleJob[0]?.job_id?.addressLine1}<br />
                          {GetSingleJob[0]?.job_id?.addressLine2}
                        </p>
                        {/* <p>WA7 6EH</p> */}
                      </div>

                      <div className='form-group-custom'>
                        <label for=''>Parking</label>
                        <p className=''>{GetSingleJob[0]?.job_id?.parking} </p>
                      </div>

                      <div className='form-group-custom'>
                        <label for=''>Site details</label>
                        <p className=''>{GetSingleJob[0]?.job_id?.sitedetail} </p>
                      </div>
                    </div>
                    <div className='col-xl-6'>
                      <div className='form-group-custom'>
                        <label for=''>FRA Checked</label>
                        <p className=''>{GetSingleJob[0]?.job_id?.fraChecked} </p>
                      </div>
                      <div className='form-group-custom'>
                        <label for=''>Asbestos</label>
                        <p className=''>
                          {GetSingleJob[0]?.job_id?.asbestosOnSite || ''}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {GetSingleJob?.length &&
                GetSingleJob?.map((jobstatuscheck, i) => {
                  const dddd = qr_code;
                  return (
                    <div
                      className='new_assigned_list mt-4'
                      id={jobstatuscheck?._id}
                      key={i}
                    >
                      <div className='card m-0'>
                        <div
                          className='card-body'
                        // onClick={(e) => {
                        //   handleDropdown()
                        //   handleMessage(jobstatuscheck?._id);
                        //   setWriteMsg(false);
                        //   setchatShow(jobstatuscheck?._id);
                        //   setchatPopup(firstTime == "first" ? true : !chatpopup);
                        //   setFirstTime();
                        // }}
                        >
                          <div className='d-flex justify-content-between mb-2'>
                            <div className='secton-nu'>Section: {i + 1}</div>
                            <div>
                              {jobstatuscheck.jobstatus == 1 ?
                                <Button onClick={(e) => handledeleteShow(e, "complete", jobstatuscheck)}
                                  className='blue-btn opacity-down btn btn-primary'>Complete
                                </Button> : jobstatuscheck.jobstatus == 2 ? <Button onClick={(e) => handledeleteShow(e, "complete", jobstatuscheck)}
                                  className='blue-btn opacity-down btn btn-primary'>Complete
                                </Button> : jobstatuscheck.jobstatus == 3 ? (
                                  <>
                                    <Button onClick={(e) => handledeleteShow(e, "pause", jobstatuscheck)}
                                      className='light_btn opacity-down btn btn-primary'>Pause
                                    </Button>
                                    <Button onClick={(e) => handledeleteShow(e, "complete", jobstatuscheck)}
                                      className='blue-btn opacity-down btn btn-primary'>Complete
                                    </Button>
                                  </>
                                ) : jobstatuscheck.jobstatus == 4 ? (
                                  <> <Button onClick={(e) => handledeleteShow(e, "paused", jobstatuscheck)}
                                    className='light_btn opacity-down btn btn-primary'>Paused
                                  </Button>
                                    <Button onClick={(e) => handledeleteShow(e, "complete", jobstatuscheck)}
                                      className='blue-btn opacity-down btn btn-primary'>Complete
                                    </Button>
                                  </>
                                ) : jobstatuscheck.jobstatus == 5 ? "" : (
                                  <Button onClick={(e) => handledeleteShow(e, "complete", jobstatuscheck)}
                                    className='blue-btn opacity-down btn btn-primary'>Complete
                                  </Button>
                                )}
                              {
                                jobstatuscheck.jobstatus == 5 && qr_code.length > 0 && qr_code[i][0] && qr_code[i][0].qr_image != undefined &&
                                <Button
                                  className='blue-btn'
                                  onClick={() =>
                                    handleQrcodePopup(
                                      qr_code.length > 0
                                        ? qr_code[i][0] && qr_code[i][0].qr_image
                                        : ''
                                    )
                                  }
                                >
                                  QR Code
                                </Button>
                              }
                            </div>

                          </div>
                          <div className='text_joinery'>
                            <div className='d-flex justify-content-between'>
                              <div className='use_data width80'>
                                <h6>{jobstatuscheck?.jobType}</h6>
                                <p className='m-0'>
                                  <i className='fa fa-user mr-1'></i>
                                  {
                                    jobstatuscheck?.contractorId?.map((item, i) => {
                                      return (

                                        item?.firstName ==
                                          undefined
                                          ? 'No contractor assigned'
                                          : item?.firstName +
                                          ' ' +
                                          item?.lastName + ', '

                                      )

                                    })
                                  }
                                </p>
                                <div className='mt-3'>
                                  <b>Job details</b>
                                  <p>{jobstatuscheck.details}</p>
                                </div>
                              </div>

                              <div className='use_data width20'>
                                <h6 className='text-right'>
                                  <span className='badge'>
                                    {jobstatuscheck.jobstatus == 1 ? (
                                      <div className='blue-status'>Created </div>
                                    ) : jobstatuscheck.jobstatus == 2 ? (
                                      <div className='darkblue-status'>
                                        Accepted
                                      </div>
                                    ) : jobstatuscheck.jobstatus == 3 ? (
                                      <div className='sky-blue-status'>
                                        Started
                                      </div>
                                    ) : jobstatuscheck.jobstatus == 4 ? (
                                      <>
                                        <div className='yellow-status'>Pause</div>
                                      </>
                                    ) : jobstatuscheck.jobstatus == 5 ? (
                                      <div className='green-status'>
                                        Completed
                                      </div>
                                    ) : jobstatuscheck.jobstatus == 9 ? (
                                      <div className='sky-blue-status'>
                                        Job Restarted
                                      </div>
                                    ) :
                                      (
                                        ''
                                      )}
                                  </span>
                                </h6>
                                {(jobstatuscheck?.job_id?.paused_reason && jobstatuscheck.jobstatus == 4) && <p className='pt-2'><b> Job Paused </b> : {jobstatuscheck?.job_id?.paused_reason}</p>}
                                <p className='m-0'>
                                  <i className='fa fa-calendar mr-1'></i>Complete{' '}
                                  <u>
                                    {' '}
                                    <a>
                                      <b>
                                        by{' '}
                                        {moment(
                                          jobstatuscheck?.completedBy
                                        ).format('D MMM YYYY')}
                                      </b>
                                    </a>
                                  </u>
                                </p>
                                {
                                  jobstatuscheck?.latitude && jobstatuscheck?.longitude &&
                                  < div className='start-loc'>
                                    <p>Start Location Recorded:</p>
                                    <p>{jobstatuscheck?.latitude}, {jobstatuscheck?.longitude}</p>
                                    <a href="#google_map" onClick={() => {
                                      setLat(jobstatuscheck.latitude);
                                      setLong(jobstatuscheck.longitude)
                                    }}>View on Map</a>
                                  </div>
                                }

                              </div>
                            </div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between'>
                            <div>
                              <b>Orchard Number </b>
                              <p>
                                {jobstatuscheck.chard_number != ''
                                  ? jobstatuscheck.chard_number
                                  : 'N/A'}
                              </p>
                            </div>
                            {/* {chatShow === jobstatuscheck?._id && chatpopup && */}
                            <div className='down_btn'>
                              <div
                                onClick={(e) => {
                                  handleDropdown()
                                  handleMessage(jobstatuscheck?._id);
                                  setWriteMsg(false);
                                  setchatShow(jobstatuscheck?._id);
                                  setchatPopup(firstTime == "first" ? true : !chatpopup);
                                  setFirstTime();
                                }}
                                class={(chatShow === jobstatuscheck?._id && chatpopup == true) ? "fa fa-chevron-down rotate down" : "fa fa-chevron-down "}></div>

                            </div>
                            {/* } */}
                          </div>
                        </div>
                      </div>
                      {chatShow === jobstatuscheck?._id && chatpopup ? (
                        <>
                          <div className='chat-tab'>
                            <Tabs
                              defaultActiveKey='message'
                              id='uncontrolled-tab-example'
                              className='mb-3'
                            >
                              <Tab eventKey='message' title='Messages'>
                                <Message
                                  jobstatuscheck={chatList[0]}
                                  sectionChat={jobstatuscheck}
                                />
                              </Tab>
                              <Tab eventKey='pictures' title='Pictures'>
                                <Picture jobstatuscheck={chatList[0]} />
                              </Tab>
                              <Tab eventKey='forms' title='Forms'>
                                <FormTable
                                  formdata={
                                    // jobstatuscheck?._id == completeForms[i]?.section_id
                                    //   ?
                                    //   completeForms[i]?.form_id?.fromname
                                    //   :
                                    //   "Form is not completed"

                                    completeForms
                                  }
                                  section_data={jobstatuscheck?._id}
                                />
                              </Tab>
                              <Tab eventKey='productsused' title='Products Used'>
                                <ProductsUsed
                                  jobstatuscheck={chatList[0]}
                                  sectionChat={jobstatuscheck}
                                // product={
                                //   jobstatuscheck?._id == product_det[i]?.section_id
                                //     &&
                                //     product_det[i]?.section_id
                                //     }
                                />
                              </Tab>
                            </Tabs>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
            </div>
            <div className='col-xl-5 col-lg-6 col-md-6 '>
              <div className='activity_custom'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='card m-0'>
                      <div class='card-header d-flex justify-content-between justify-center align-items-center'>
                        <h5 class='m-0'>Latest Activity</h5>
                      </div>

                      <div className='overflow_scroll'>
                        {
                          latestActivityArr?.map((item2, index) => {
                            return (
                              <>
                                {
                                // (item2?.timeData?.status == 8 || item2?.jobstatuscheck?.jobstatus == 2) ? "" :
                                  (item2?.timeData?.status == 8 ) ? "" :
                                    item2?.timeData == undefined ?
                                      <div className='activity'>
                                        <div className='activity-box'>
                                          <ul className='activity-list'>
                                            {/* {jobstatuscheck.jobstatus == 1 && ( */}
                                            <li className='pt-2  p-0'>
                                              <div className='left-line-c'></div>
                                              <div className='activity-user'>
                                                {item2?.jobstatuscheck?.jobstatus == 1 ? (
                                                  <a
                                                    type='submit'
                                                    title='Lesley Grauer'
                                                    data-toggle='tooltip'
                                                    className='avatar lightly-blue-bg'
                                                  >
                                                    <img src={User} alt='' />
                                                  </a>
                                                ) : item2?.jobstatuscheck?.jobstatus == 2 ? (
                                                  <a
                                                    type='submit'
                                                    title='Lesley Grauer'
                                                    data-toggle='tooltip'
                                                    className='avatar lightly-blue-bg'
                                                  >
                                                    <img src={User} alt='' />
                                                  </a>
                                                ) : item2?.jobstatuscheck?.jobstatus == 3 ? (
                                                  <a
                                                    type='submit'
                                                    title='Lesley Grauer'
                                                    data-toggle='tooltip'
                                                    className='avatar lightly-blue-bg'
                                                  >
                                                    <img src={User} alt='' />
                                                  </a>
                                                ) : item2?.jobstatuscheck?.jobstatus == 4 ? (
                                                  <a
                                                    type='submit'
                                                    title='Lesley Grauer'
                                                    data-toggle='tooltip'
                                                    className='avatar lightly-blue-bg'
                                                  >
                                                    <img src={User} alt='' />
                                                  </a>
                                                ) : (
                                                  item2?.jobstatuscheck?.jobstatus == 5 ? (
                                                    <a
                                                      type='submit'
                                                      title='Lesley Grauer'
                                                      data-toggle='tooltip'
                                                      className='avatar lightly-blue-bg'
                                                    >
                                                      <img src={User} alt='' />
                                                    </a>
                                                  ) : item2?.jobstatuscheck?.jobstatus == 9 && (
                                                    (
                                                      <a
                                                        type='submit'
                                                        title='Lesley Grauer'
                                                        data-toggle='tooltip'
                                                        className='avatar lightly-blue-bg'
                                                      >
                                                        <img src={User} alt='' />
                                                      </a>
                                                    )
                                                  )
                                                )}
                                              </div>
                                              <div className='activity-content'>
                                                <div className='timeline-content d-flex justify-content-between latest-join'>
                                                  <div className='createby'>
                                                    <a type='submit' className='name' />{' '}
                                                    <a
                                                      className='timeline-text-c'
                                                      type='submit'
                                                    >
                                                      {item2?.jobstatuscheck?.jobType}
                                                      <br />
                                                      {item2?.jobstatuscheck?.jobstatus == 1
                                                        ? (
                                                          item2?.jobstatuscheck?.updatedCreateBy != null ? `Job created by  ${item2?.jobstatuscheck?.updatedCreateBy?.firstName} ${item2?.jobstatuscheck?.updatedCreateBy?.lastName}` :
                                                            `Job created by  ${item2?.jobstatuscheck?.job_id?.createBy?.firstName} ${item2?.jobstatuscheck?.job_id?.createBy?.lastName}`)
                                                        : item2?.jobstatuscheck?.jobstatus != 1 &&

                                                        (item2?.jobstatuscheck?.updatedCreateBy != null ? `Job created by  ${item2?.jobstatuscheck?.updatedCreateBy?.firstName} ${item2?.jobstatuscheck?.updatedCreateBy?.lastName}`
                                                          :
                                                          `Job created by  ${item2?.jobstatuscheck?.job_id?.createBy?.firstName} ${item2?.jobstatuscheck?.job_id?.createBy?.lastName}`)}

                                                      {/* {localStorage
                                                .getItem('roles')
                                                .toUpperCase()}{' '} */}
                                                    </a>
                                                    <span></span>
                                                  </div>
                                                  <div className='time'>
                                                    <span>
                                                      {/* { `${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[0].split("/")[1]}-
                                              ${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[0].split("/")[0]}-
                                              ${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[0].split("/")[2]} 
                                                 ${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[1].split(":")[0]}: 
                                                ${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[0].split("/")[1]}`}
                                              */}
                                                      {/* {moment(
                                                item2?.jobstatuscheck?.updatedAt
                                              ).format('MMMM Do YYYY, h:mm   a')} */}
                                                      {moment2(item2?.sortTimesheet).tz('Europe/London').format('MMMM Do YYYY, h:mm   a')}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                            {/* )} */}
                                            {/* {jobstatuscheck.jobstatus == 2 && (
      
                                      <li className='pt-2  p-0'>
                                       
                                        <div className='activity-user'>
                                          <a
                                            type='submit'
                                            className='avatar dark-blue-bg-two'
                                            title='Jeffery Lalor'
                                            data-toggle='tooltip'
                                          >
                                            <img src={Pause} alt='' />
                                          </a>
                                        </div>
                                        <div className='activity-content'>
                                          <div className='timeline-content d-flex justify-content-between'>
                                            <div className='createby'>
                                              <a
                                                type='submit'
                                                className='name timeline-text-c'
                                              >
                                                Joinery Accepted{' '}
                                                {jobstatuscheck?.contractorId
                                                  ?.firstName +
                                                  ' ' +
                                                  jobstatuscheck?.contractorId
                                                    ?.lastName}
                                              </a>
                                            </div>
                                            <div className='time'>
                                              <span>
                                                {moment(
                                                  jobstatuscheck.contractor
                                                    ?.completedBy
                                                ).format('D MMM YYYY')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                    {jobstatuscheck.jobstatus == 3 && (
                                      <li className='pt-2  p-0'>
                                        <div className='activity-user'>
                                          <a
                                            type='submit'
                                            title='Catherine Manseau'
                                            data-toggle='tooltip'
                                            className='avatar light-green-bg'
                                          >
                                            <img src={File} alt='' />
                                          </a>
                                        </div>
                                        <div className='activity-content'>
                                          <div className='timeline-content d-flex justify-content-between'>
                                            <div className='createby'>
                                              <a
                                                type='submit'
                                                className='name timeline-text-c'
                                              >
                                                Joinery Started{' '}
                                                {jobstatuscheck?.contractorId
                                                  ?.firstName +
                                                  ' ' +
                                                  jobstatuscheck?.contractorId
                                                    ?.lastName}
                                              </a>
                                            </div>
                                            <div className='time'>
                                              <span>
                                                {moment(
                                                  jobstatuscheck.contractor
                                                    ?.completedBy
                                                ).format('D MMM YYYY')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )}
      
                                    {jobstatuscheck.jobstatus == 4 && (
                                      <li className='pt-2  p-0'>
                                        <div className='activity-user'>
                                          <a
                                            href='javascript:void(0)'
                                            title='Jeffery Lalor'
                                            data-toggle='tooltip'
                                            className='avatar light-yellow-bg'
                                          >
                                            <img src={Play} alt='' />
                                          </a>
                                        </div>
                                        <div className='activity-content'>
                                          <div className='timeline-content d-flex justify-content-between'>
                                            <div className='createby'>
                                              <a
                                                type='submit'
                                                className='name timeline-text-c'
                                              >
                                                Joinery Paused{' '}
                                                {jobstatuscheck?.contractorId
                                                  ?.firstName +
                                                  ' ' +
                                                  jobstatuscheck?.contractorId
                                                    ?.lastName}
                                              </a>
                                            </div>
                                            <div className='time'>
                                              <span>
                                                {moment(
                                                  jobstatuscheck.contractor
                                                    ?.completedBy
                                                ).format('D MMM YYYY')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                    {jobstatuscheck.jobstatus == 5 && (
                                      <li className='pt-2  p-0'>
                                        <div className='activity-user'>
                                          <a
                                            style={{ background: 'green' }}
                                            href='javascript:void(0)'
                                            title='Jeffery Lalor'
                                            data-toggle='tooltip'
                                            className='avatar'
                                          >
                                            <img src={User} alt='' />
                                          </a>
                                        </div>
                                        <div className='activity-content'>
                                          <div className='timeline-content d-flex justify-content-between'>
                                            <div className='createby'>
                                              <a
                                                type='submit'
                                                className='name timeline-text-c'
                                              >
                                                Joinery Completed{' '}
                                                {jobstatuscheck?.contractorId
                                                  ?.firstName +
                                                  ' ' +
                                                  jobstatuscheck?.contractorId
                                                    ?.lastName}
                                              </a>
                                            </div>
                                            <div className='time'>
                                              <span>
                                                {moment(
                                                  jobstatuscheck.contractor
                                                    ?.completedBy
                                                ).format('D MMM YYYY')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )} */}
                                          </ul>
                                        </div>
                                      </div>
                                      :
                                      <div className='activity'>
                                        <div className='activity-box'>
                                          <ul className='activity-list'>
                                            <li className='pt-2  p-0'>
                                              <div className='left-line-c'></div>
                                              <div className='activity-user'>
                                                {item2?.timeData?.status == 1 ? (
                                                  <a
                                                    type='submit'
                                                    title='Lesley Grauer'
                                                    data-toggle='tooltip'
                                                    className='avatar lightly-blue-bg'
                                                  >
                                                    <img src={User} alt='' />
                                                  </a>
                                                ) : item2?.timeData?.status == 2 ? (
                                                  <a
                                                    type='submit'
                                                    title='Lesley Grauer'
                                                    data-toggle='tooltip'
                                                    className='avatar dark-blue-bg'
                                                  >
                                                    <img src={accept} alt='' />
                                                  </a>
                                                ) : item2?.timeData?.status == 3 ? (
                                                  <a
                                                    type='submit'
                                                    title='Lesley Grauer'
                                                    data-toggle='tooltip'
                                                    className='avatar light-green-bg'
                                                  >
                                                    <img src={jobstarted} alt='' />
                                                  </a>
                                                ) : item2?.timeData?.status == 4 ? (
                                                  <a
                                                    type='submit'
                                                    title='Lesley Grauer'
                                                    data-toggle='tooltip'
                                                    className='avatar light-yellow-bg'
                                                  >
                                                    <img src={pause} alt='' />
                                                  </a>
                                                ) : (
                                                  item2?.timeData?.status == 5 ? (
                                                    <a
                                                      type='submit'
                                                      title='Lesley Grauer'
                                                      data-toggle='tooltip'
                                                      className='avatar lightly-green-bg'
                                                    >
                                                      <img src={jobdone} alt='' />
                                                    </a>
                                                  ) : item2?.timeData?.status == 9 &&
                                                  (
                                                    <a
                                                      type='submit'
                                                      title='Lesley Grauer'
                                                      data-toggle='tooltip'
                                                      className='avatar light-green-bg'
                                                    >
                                                      <img src={jobstarted} alt='' />
                                                    </a>
                                                  )
                                                )}
                                              </div>
                                              <div className='activity-content'>
                                                <div className='timeline-content d-flex justify-content-between latest-join'>
                                                  <div className='createby'>
                                                    <a
                                                      type='submit'
                                                      className='name'
                                                    />{' '}
                                                    <a
                                                      className='timeline-text-c'
                                                      type='submit'
                                                    >
                                                      {item2?.jobstatuscheck?.jobType}
                                                      <br />
                                                      {item2?.timeData?.status == 1
                                                        ? 'Job created by'
                                                        : item2?.timeData?.status == 2
                                                          ? ' Accepted'
                                                          : item2?.timeData?.status == 3
                                                            ? ' Started'
                                                            : item2?.timeData?.status == 4
                                                              ? ' Paused'
                                                              : item2?.timeData?.status == 5 ?
                                                                ' Completed'
                                                                : item2?.timeData?.status == 9 &&
                                                                `Job Restarted`
                                                      }
                                                      {'  '}

                                                      {item2?.jobstatuscheck?.contractordetail?.map(
                                                        (ite32, index) => {
                                                          return (
                                                            <>
                                                              {ite32?.firstName +
                                                                ' ' +
                                                                ite32?.lastName}
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                      {item2?.timeData?.status == 4 && item2?.jobstatuscheck?.job_id?.paused_reason &&
                                                        <>
                                                          <p cl>
                                                            {" "}  Reason :  {item2?.jobstatuscheck?.job_id?.paused_reason || 'N/A'}
                                                          </p>
                                                        </>
                                                      }
                                                    </a>
                                                    <span></span>
                                                  </div>
                                                  <div className='time'>
                                                    <span>
                                                      {moment2(item2?.sortTimesheet).tz('Europe/London').format('MMMM Do YYYY, h:mm   a')}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                }
                              </>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='map_img mt-4'>
                <div className='row'>
                  <div className='col-md-12'>

                    <div className='map-overscroll'>
                      {
                        latestActivityArr && latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img1 != undefined && latestActivityArr && latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img1 != 'false' &&
                        // <img src={`${process.env.REACT_APP_SOCKET_URL}${latestActivityArr && latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img1}`} className='img-fluid' />
                        <img src={` ${!latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img1.startsWith('https://flamehold.s3.us-west-2.amazonaws.com') ? `${process.env.REACT_APP_SOCKET_URL}${latestActivityArr && latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img1}` : latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img1}`} className='img-fluid' />
                      }
                      {
                        latestActivityArr && latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img2 != undefined && latestActivityArr && latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img2 != 'false' &&
                        // <img src={`${process.env.REACT_APP_SOCKET_URL}${latestActivityArr && latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img2}`} className='img-fluid ' />
                        <img src={`${!latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img2.startsWith('https://flamehold.s3.us-west-2.amazonaws.com') ? `${process.env.REACT_APP_SOCKET_URL}${latestActivityArr && latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img2}` : latestActivityArr[0]?.jobstatuscheck?.job_id?.floor_img2}`} className='img-fluid ' />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='mapview_inner' id="google_map">
                <Maping GetSingleJob={GetSingleJob} start_lat={start_lat} start_long={start_long} />
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-xl-8'>

            </div>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={imageGallery[indexValue]}
            nextSrc={imageGallery[(indexValue + 1) % imageGallery.length]}
            prevSrc={
              imageGallery[
              (indexValue + imageGallery.length - 1) % imageGallery.length
              ]
            }
            onCloseRequest={() => setisOpen(false)}
            onMovePrevRequest={() =>
              setIndexValue(
                (indexValue + imageGallery.length - 1) % imageGallery.length
              )
            }
            onMoveNextRequest={() =>
              setIndexValue((indexValue + 1) % imageGallery.length)
            }
          //onClick={handleCurrentImage}

          />

        )}

        {/* <div onClick={ReportsForPdf}>SOCKET</div>
        {GetSingleJob?.length > 0 &&
          // <PDFExport
          //   ref={pdfExportComponent}
          //   paperSize='auto'
          //   padding={40}
          //   fileName={`Pdf risk `}
          //   author='KendoReact Team'
          // >
            <div >
              <div style={{
                position: 'absolute',
                left: '-100000px',
                top: 0,
              }}>
                <Risk_pdf GetSingleJob={GetSingleJob} />
              </div>
            </div>
         
          // </PDFExport>
          } */}




        {/* <button onClick={socketTest}>SOCKET HIT</button> */}
        <Modal show={qrcodePopup} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Qr Code</Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center'>
            {/* <QRCode value={qrcodeImage} /> */}
            <img
              src={`${'https://flamehold.s3.us-west-2.amazonaws.com/new-flame-hold/'}${qrcodeImage}`}
              alt='no image in db'
              style={{ width: '300px', height: '300px' }}
            />
          </Modal.Body>
        </Modal>
        {/* ARE YOU SURE */}

        <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
          <Modal.Header closeButton className='b-none'></Modal.Header>
          <Modal.Body className='p-4 main_modal'>
            <div className='text_del text-center'>
              <h3>Are you sure?</h3>
            </div>
            <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
              <Button
                variant='secondary'
                className='btn btn-primary '
                onClick={handledeleteClose}
              >
                NO
              </Button>

              <Button
                variant='danger'
                onClick={() => handleJobStatus(eventName, type, confirmData)}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>

      </div>

    </>
  );
}

export default ViewJob
