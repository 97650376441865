import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { Button, Modal } from 'react-bootstrap';
import { getAllHoliday, approveoreject, } from '../../Redux/Action/SuperAdminAction';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
function Holiday() {
  const dispatch = useDispatch();
  const documnetData = useSelector((state) => state.adminList.GetAllHoliday);
  const [showDisapprove, setshowDisapprove] = useState(false);
  
  const [DisapproveID, setDisapproveID] = useState();
  const [reason, setreason] = useState('');
  const handleClose = () => setshowDisapprove(false);
  const [showNotes, setshowNotes] = useState(false);
  const [showNotesdata, setshowNotesdata] = useState('');
  const handleCloseNotes = () => setshowNotes(false);
  
  const handleShow = (id) => {
    setshowDisapprove(true);
    setDisapproveID(id);
  };
  const handleShowNotes = (notes) => {
    setshowNotes(true);
    setshowNotesdata(notes);
  };

  const approveRequest = (id) => {
    let data = {
      id: id,
      status: 1,
    };
    dispatch(approveoreject(data)).then((res) => {
      dispatch(getAllHoliday());
    });
  };
  const columns = [
    {
      id: 1,
      name: 'NAME',
      selector: (row) => (
        <b>{row.contractorId?.firstName + ' ' + row.contractorId?.lastName}</b>
      ),
      reorder: true,
    },
    {
      id: 2,
      name: 'From Date',
      selector: (row) => <b>{moment(row?.from_date).format('DD/MM/YYYY')}</b>,
      reorder: true,
    },
    {
      id: 2,
      name: 'To Date',
      selector: (row) => <b>{moment(row?.to_date).format('DD/MM/YYYY')}</b>,
      reorder: true,
    },

    {
      id: 3,
      name: 'ACTIONS',
      className: 'cell-right',
      cell: (row) => (
        <>
          <Button
            className={
              row.status == 1 ? 'btn-add-approve mr-3' : 'btn-new mr-3'
            }
            onClick={() => approveRequest(row?._id)}
          >
            {' '}
            Approve
          </Button>
          <Button
            className={row.status == 2 ? 'btn-add-decline' : 'btn-new'}
            onClick={() => handleShow(row?._id)}
          >
            {' '}
            Decline
          </Button>
        </>
      ),
    },
    {
      id: 2,
      name: 'View',
      selector: (row) => (
        <b
          onClick={() => handleShowNotes(row.note_for_admin)}
          className='eye-view-c'
        >
          <i className='fa fa-eye'></i>
        </b>
      ),

      reorder: true,
    },
  ];

  useEffect(() => {dispatch(getAllHoliday())}, []);

  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title'>Holiday Requests</h3>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <DataTable
                    columns={columns}
                    data={documnetData}
                    className='hide_coloumn'
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showDisapprove} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Decline Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='item_priview '>
              <label>Description</label>
              <textarea
                onChange={(e) => setreason(e.target.value)}
                className='form-control'
                cols={20}
                row={20}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='btn-new'
              onClick={(e) => {
                e.preventDefault();
                let data = {
                  id: DisapproveID,
                  status: 2,
                  reason: reason,
                };
                dispatch(approveoreject(data)).then((res) => {
                  setshowDisapprove(false);
                  dispatch(getAllHoliday());
                });
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* notes  */}
        <Modal show={showNotes} onHide={handleCloseNotes} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Contractor Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='item_priview '>
              <p>{showNotesdata}</p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Holiday;
