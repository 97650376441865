import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCalenderByMonthYear } from '../../Redux/Action/SuperAdminAction';
import backArrow from '../assets/img/back-ar.svg';
import nextArrow from '../assets/img/next-ar.svg';
import moment from 'moment';
import { HashLink } from 'react-router-hash-link';

function Calender() {
  const dispatch = useDispatch();
  const [newMonth, setNewMonth] = useState(null);
  const [newYear, setNewYear] = useState(null);
  const [days, setNewDays] = useState(null);

  const calender_details = useSelector((state) => state?.adminList?.calender_details?.data);


  var arrbyaj = [];

  //SORT DATA ACCORDING TO TIME
  for (let i = 0; i < calender_details?.length; i++) {
    var calender_filter = [];
    calender_details &&
      calender_details[i]?.timesheet?.filter(function (a) {
        a.sortingData = a?.createdAt?.toString().split('T')[0];
        return (a.jobstatus == 3 || a.jobstatus == 4 || a.jobstatus == 5) && calender_filter.push(a);
      });
    calender_details &&
      calender_details[i]?.holidaydata?.filter(function (a) {
        a.sortingData = a?.from_date;
        return a.from_date && calender_filter.push(a);
      });

    arrbyaj.push({
      name: calender_details && calender_details[i]?.name,
      calender_filter,
    });


  }

  var lastCalenderData = [];

  for (let i = 0; i < calender_details?.length; i++) {

    var sameDate = [];

    arrbyaj &&
      arrbyaj[i]?.calender_filter?.sort(function (a, b) {
        return new Date(a?.sortingData) - new Date(b?.sortingData);
      });


    //SAME DATE MULTIPLE STATUS COMPLETE, HOLD, START ETC. FILTER

    const arrayUniqueByKey = [...new Map(arrbyaj[i]?.calender_filter?.map(item =>
      [item["sortingData"], item])).values()];


    for (let j = 0; j < arrayUniqueByKey?.length; j++) {
      const sameDataWithUnique = arrbyaj[i]?.calender_filter?.filter(function (a) {

        return a.sortingData == arrayUniqueByKey[j]?.sortingData;;
      });

      sameDate?.push(sameDataWithUnique?.length > 1 ? { ...sameDataWithUnique[0], sameArr: sameDataWithUnique } : sameDataWithUnique[0])
    }


    lastCalenderData.push({
      name: calender_details && calender_details[i]?.name,
      sameDate,
    });

  }

  // //FILTER
  // const arrayUniqueByKey = [...new Map(calender_filter.map(item =>
  //   [item["sortingData"], item])).values()];
  //END SORT

  //Calender functionality starts from here
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  var today = new Date();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var currentMonth = mm;
  var str = '' + currentMonth;

  var ansMonth = '00'.substring(0, '00'.length - str.length) + str;


  if (newMonth == null) {
    setNewMonth(currentMonth);
    setNewYear(yyyy);
    dispatch(
      getCalenderByMonthYear({
        date: `${yyyy.toString()}-${ansMonth.toString()}`,
      })
    );
    switch (currentMonth) {
      case 1:
        setNewDays(31);
        break;
      case 2:
        function checkLeapYear(year) {
          //three conditions to find out the leap year
          if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
            setNewDays(29);
          } else {
            setNewDays(28);
          }
        }
        checkLeapYear(yyyy);

        break;
      case 3:
        setNewDays(31);
        break;
      case 4:
        setNewDays(30);
        break;
      case 5:
        setNewDays(31);
        break;
      case 6:
        setNewDays(30);
        break;
      case 7:
        setNewDays(31);
        break;
      case 8:
        setNewDays(31);

        break;
      case 9:
        setNewDays(30);

        break;
      case 10:
        setNewDays(31);

        break;
      case 11:
        setNewDays(30);
      case 12:
        setNewDays(31);

        break;
    }
  }

  const handleNextMonth = () => {
    if (newMonth + 1 == 12) {
      setNewMonth(12);
      setNewYear(newYear);
      dispatch(getCalenderByMonthYear({ date: `${newYear}-12` }));

      setNewDays(31);
    } else {
      if (newMonth + 1 != 13) {
        dispatch(
          getCalenderByMonthYear({
            date: `${newYear.toString()}-${parseInt(newMonth + 1)}`,
          })
        );
        setNewMonth(newMonth + 1);
      }

      switch (newMonth + 1) {
        case 13:
          setNewMonth(1);
          dispatch(getCalenderByMonthYear({ date: `${newYear + 1}-01` }));
          setNewDays(31);
          setNewYear(newYear + 1);
          break;
        case 2:
          function checkLeapYear(year) {
            //three conditions to find out the leap year
            if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
              setNewDays(29);
            } else {
              setNewDays(28);
            }
          }
          checkLeapYear(newYear);
          break;
        case 3:
          setNewDays(31);
          break;
        case 4:
          setNewDays(30);
          break;
        case 5:
          setNewDays(31);
          break;
        case 6:
          setNewDays(30);
          break;
        case 7:
          setNewDays(31);
          break;
        case 8:
          setNewDays(31);

          break;
        case 9:
          setNewDays(30);

          break;
        case 10:
          setNewDays(31);

          break;
        case 11:
          setNewDays(30);
          break;
      }
    }
  };

  const handleBackMonth = () => {
    if (newMonth == 1) {
      setNewMonth(12);
      setNewYear(newYear - 1);
      dispatch(
        getCalenderByMonthYear({
          date: `${newYear - 1}-${'0'.substring(
            0,
            '0'.length - '' + (newMonth + 11).toString().length
          ) +
            '' +
            (newMonth + 11).toString()
            }`,
        })
      );
      setNewDays(31);
    } else {
      setNewMonth(newMonth - 1);
      dispatch(
        getCalenderByMonthYear({
          date: `${newYear.toString()}-${parseInt(newMonth - 1)}`,
        })
      );
      switch (newMonth - 1) {
        case 1:
          setNewDays(31);
          break;
        case 2:
          function checkLeapYear(year) {
            //three conditions to find out the leap year
            if ((0 == year % 4 && 0 != year % 100) || 0 == year % 400) {
              setNewDays(29);
            } else {
              setNewDays(28);
            }
          }
          checkLeapYear(newYear);
          break;
        case 3:
          setNewDays(31);
          break;
        case 4:
          setNewDays(30);
          break;
        case 5:
          setNewDays(31);
          break;
        case 6:
          setNewDays(30);
          break;
        case 7:
          setNewDays(31);
          break;
        case 8:
          setNewDays(31);

          break;
        case 9:
          setNewDays(30);

          break;
        case 10:
          setNewDays(31);

          break;
        case 11:
          setNewDays(30);
        case 12:
          setNewDays(31);

          break;
      }
    }
  };
  //Calender functionality in end now
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid p-0'>
        <div className='Calendar_data position-relative'>
          <div className='page-header pl-30 calendar-title'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>Calendar</h3>
              </div>
            </div>
          </div>
          <div className='sticky_date'>
            <div className='d-flex justify-content-between'>
              <div className='calender-title'>
                Staff Calendar - {months[newMonth - 1]} {newYear}
              </div>
              <ul className='tab-part-month'>
                <li className='chg-tab active' onClick={() => handleBackMonth()}>
                  <img src={backArrow} />
                </li>
                <li className='chg-tab' onClick={() => handleNextMonth()}>
                  <img src={nextArrow} />
                </li>
              </ul>
            </div>
          </div>
          <div className='row margin_top_2001'>
            <div className='col-lg-12'>
              <div className='table table-striped1 calender-table primary-ui-calandar'>
                {/* <table className='table table-striped1 custom-table table-nowrap mb-0 calender-table'> */}
                <table>
                  <thead>
                    <tr className='th-font'>
                      <th></th>

                      {(function (rows, i, len) {
                        while (i <= len) {
                          rows.push(
                            <th>
                              {moment(
                                `${newYear}-${months[newMonth - 1]}-${i}`
                              ).format('ddd Do')}
                            </th>
                          );
                          i++;
                        }
                        return rows;
                      })([], 1, days)}
                    </tr>
                  </thead>
                  <tbody>
                    {lastCalenderData &&
                      lastCalenderData?.map((item, first_i) => {
                        return (
                          <>
                            <tr className='background-grey'>
                              <td className='client-name-left'>
                                <h2 className='table-avatar'>
                                  <a className='user-name-font' href='#'>
                                    {item.name}
                                  </a>
                                </h2>
                              </td>
                              {
                                item?.sameDate?.length == 0 ?
                                  Array(days).fill(0).map((item, i) =>
                                    <td>
                                    </td>
                                  ) :
                                  item?.sameDate?.map((data, second_i) => {
                                    return (
                                      <>

                                        {
                                          second_i == 0 && (data?.sortingData.split('-')[2] - 1) != 0 ?

                                            Array(second_i == 0
                                              && parseInt(
                                                data?.sortingData.split('-')[2]
                                              ) -
                                              parseInt(
                                                1
                                              )).fill(0).map((item, i) =>
                                                <td></td>
                                              )

                                            :
                                            (data?.sortingData.split('-')[2] - 1) == 0 ||
                                              (parseInt(
                                                data?.sortingData.split('-')[2]
                                              ) -
                                                parseInt(
                                                  lastCalenderData[first_i]?.sameDate[second_i - 1]?.sortingData.split('-')[2]
                                                ) - 1) == 0 ? "" :
                                              Array(parseInt(
                                                data?.sortingData.split('-')[2]
                                              ) -
                                                parseInt(
                                                  lastCalenderData[first_i]?.sameDate[second_i - 1]?.to_date == undefined ?
                                                    lastCalenderData[first_i]?.sameDate[second_i - 1]?.sortingData.split('-')[2]
                                                    :
                                                    lastCalenderData[first_i]?.sameDate[second_i - 1]?.to_date.split('-')[2]
                                                ) - 1).fill(0).map((item, i) =>
                                                  <td></td>
                                                )

                                        }

                                        {
                                          data?.sameArr?.length > 0 ?

                                            <td colspan='1'>
                                              <div className='outer-td-overflow'>
                                                {
                                                  data?.sameArr?.map((data, sameArr_i) => {
                                                    return (
                                                      <>

                                                        {
                                                          data?.jobstatus == '3' ?
                                                            (
                                                              <HashLink to={`/jobs/jobsdetail/${data?.job_id?._id}#${data?._id}`}>
                                                                <div className='data_item_calendar me-2 calendar-purple start-blue-td'>
                                                                  {/* <h6 className="m-0 white">{`${parseInt(data?.sortingData.split("-")[2])} ${parseInt(lastCalenderData[first_i]?.sameDate[second_i-1]?.sortingData.split("-")[2])}`}</h6> */}
                                                                  <h6 className='m-0 text-black-cal'>
                                                                    <div className='d-flex'>
                                                                      {' '}
                                                                      <div className='client-name-table fixed-wid'>
                                                                        Client:
                                                                      </div>
                                                                      {
                                                                        data?.job_id?.clientId
                                                                          ?.clientName
                                                                      }
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                      {' '}
                                                                      <div className='ref fixed-wid'>
                                                                        Ref:
                                                                      </div>
                                                                      {'00'.substring(
                                                                        0,
                                                                        '00'.length -
                                                                        '' +
                                                                        data?.job_id?.JobRefNo.toString()
                                                                          .length
                                                                      ) +
                                                                        '' +
                                                                        data?.job_id?.JobRefNo.toString()}
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                      {' '}
                                                                      <div className='status-table-c fixed-wid'>
                                                                        Status:
                                                                      </div>{' '}
                                                                      Start
                                                                    </div>
                                                                  </h6>
                                                                </div>
                                                              </HashLink>
                                                            ) :
                                                            data?.jobstatus == '4' ? (
                                                              <HashLink to={`/jobs/jobsdetail/${data?.job_id?._id}#${data?._id}`}>
                                                                <div className='data_item_calendar me-2 calendar-purple pause-yellow-td'>
                                                                  {/* <h6 className="m-0 white">{`${parseInt(data?.sortingData.split("-")[2])} ${parseInt(lastCalenderData[first_i]?.sameDate[second_i-1]?.sortingData.split("-")[2])}`}</h6> */}
                                                                  <h6 className='m-0 text-black-cal'>
                                                                    <div className='d-flex'>
                                                                      {' '}
                                                                      <div className='client-name-table fixed-wid'>
                                                                        Client:
                                                                      </div>
                                                                      {
                                                                        data?.job_id?.clientId
                                                                          ?.clientName
                                                                      }{' '}
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                      {' '}
                                                                      <div className='ref fixed-wid'>
                                                                        Ref:
                                                                      </div>
                                                                      {'00'.substring(
                                                                        0,
                                                                        '00'.length -
                                                                        '' +
                                                                        data?.job_id?.JobRefNo.toString()
                                                                          .length
                                                                      ) +
                                                                        '' +
                                                                        data?.job_id?.JobRefNo.toString()}{' '}
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                      {' '}
                                                                      <div className='status-table-c fixed-wid'>
                                                                        Status:
                                                                      </div>{' '}
                                                                      Paused
                                                                    </div>
                                                                  </h6>
                                                                </div>
                                                              </HashLink>
                                                            ) :
                                                              data?.jobstatus == '5' ? (
                                                                <HashLink to={`/jobs/jobsdetail/${data?.job_id?._id}#${data?._id}`}>
                                                                  <div className='data_item_calendar me-2 calendar-purple complete-green-td'>
                                                                    {/* <h6 className="m-0 white">{`${parseInt(data?.sortingData.split("-")[2])} ${parseInt(lastCalenderData[first_i]?.sameDate[second_i-1]?.sortingData.split("-")[2])}`}</h6> */}
                                                                    <h6 className='m-0 text-black-cal'>
                                                                      <div className='d-flex'>
                                                                        {' '}
                                                                        <div className='client-name-table fixed-wid'>
                                                                          Client:
                                                                        </div>
                                                                        {
                                                                          data?.job_id?.clientId
                                                                            ?.clientName
                                                                        }{' '}
                                                                      </div>
                                                                      <div className='d-flex'>
                                                                        {' '}
                                                                        <div className='ref fixed-wid'>
                                                                          Ref:
                                                                        </div>
                                                                        {'00'.substring(
                                                                          0,
                                                                          '00'.length -
                                                                          '' +
                                                                          data?.job_id?.JobRefNo.toString()
                                                                            .length
                                                                        ) +
                                                                          '' +
                                                                          data?.job_id?.JobRefNo.toString()}
                                                                      </div>
                                                                      <div className='d-flex'>
                                                                        {' '}
                                                                        <div className='status-table-c fixed-wid'>
                                                                          Status:
                                                                        </div>{' '}
                                                                        Complete
                                                                      </div>
                                                                    </h6>
                                                                  </div>
                                                                </HashLink>
                                                              ) : (
                                                                data?.to_date && (

                                                                  <div className='data_item_calendar calendar-purple holiday-orange-td'>
                                                                    <h6 className='m-0 text-black-cal'>
                                                                      {data?.note_for_admin}
                                                                    </h6>
                                                                  </div>

                                                                )
                                                              )
                                                        }

                                                      </>
                                                    )
                                                  })
                                                }
                                              </div>
                                            </td>
                                            :

                                            data?.jobstatus == '3' ? (
                                              <td colspan='1'>
                                                <HashLink to={`/jobs/jobsdetail/${data?.job_id?._id}#${data?._id}`}>
                                                  <div className='outer-td-overflow fggdfgdf'>
                                                    <div className='data_item_calendar calendar-purple start-blue-td'>
                                                      {/* <h6 className="m-0 white">{`${parseInt(data?.sortingData.split("-")[2])} ${parseInt(lastCalenderData[first_i]?.sameDate[second_i-1]?.sortingData.split("-")[2])}`}</h6> */}
                                                      <h6 className='m-0 text-black-cal'>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='client-name-table fixed-wid'>
                                                            Client:
                                                          </div>
                                                          {
                                                            data?.job_id?.clientId
                                                              ?.clientName
                                                          }
                                                        </div>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='ref fixed-wid'>
                                                            Ref:
                                                          </div>
                                                          {'00'.substring(
                                                            0,
                                                            '00'.length -
                                                            '' +
                                                            data?.job_id?.JobRefNo.toString()
                                                              .length
                                                          ) +
                                                            '' +
                                                            data?.job_id?.JobRefNo.toString()}
                                                        </div>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='status-table-c fixed-wid'>
                                                            Status:
                                                          </div>{' '}
                                                          Start
                                                        </div>
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </HashLink>
                                              </td>
                                            ) : data?.jobstatus == '4' ? (
                                              <td colspan='1'>
                                                <HashLink to={`/jobs/jobsdetail/${data?.job_id?._id}#${data?._id}`}>
                                                  <div className='outer-td-overflow ffgdfgdfg'>
                                                    <div className='data_item_calendar me-2 calendar-purple pause-yellow-td'>
                                                      {/* <h6 className="m-0 white">{`${parseInt(data?.sortingData.split("-")[2])} ${parseInt(lastCalenderData[first_i]?.sameDate[second_i-1]?.sortingData.split("-")[2])}`}</h6> */}
                                                      <h6 className='m-0 text-black-cal'>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='client-name-table fixed-wid'>
                                                            Client:
                                                          </div>
                                                          {
                                                            data?.job_id?.clientId
                                                              ?.clientName
                                                          }{' '}
                                                        </div>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='ref fixed-wid'>
                                                            Ref:
                                                          </div>
                                                          {'00'.substring(
                                                            0,
                                                            '00'.length -
                                                            '' +
                                                            data?.job_id?.JobRefNo.toString()
                                                              .length
                                                          ) +
                                                            '' +
                                                            data?.job_id?.JobRefNo.toString()}{' '}
                                                        </div>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='status-table-c fixed-wid'>
                                                            Status:
                                                          </div>{' '}
                                                          Paused
                                                        </div>
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </HashLink>
                                              </td>
                                            ) : data?.jobstatus == '5' ? (


                                              <td colspan='1'>
                                                <HashLink to={`/jobs/jobsdetail/${data?.job_id?._id}#${data?._id}`}>
                                                  <div className='outer-td-overflow frgfdgfd'>
                                                    <div className='data_item_calendar me-2 calendar-purple complete-green-td'>
                                                      {/* <h6 className="m-0 white">{`${parseInt(data?.sortingData.split("-")[2])} ${parseInt(lastCalenderData[first_i]?.sameDate[second_i-1]?.sortingData.split("-")[2])}`}</h6> */}
                                                      <h6 className='m-0 text-black-cal'>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='client-name-table fixed-wid'>
                                                            Client:
                                                          </div>
                                                          {
                                                            data?.job_id?.clientId
                                                              ?.clientName
                                                          }{' '}
                                                        </div>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='ref fixed-wid'>
                                                            Ref:
                                                          </div>
                                                          {'00'.substring(
                                                            0,
                                                            '00'.length -
                                                            '' +
                                                            data?.job_id?.JobRefNo.toString()
                                                              .length
                                                          ) +
                                                            '' +
                                                            data?.job_id?.JobRefNo.toString()}
                                                        </div>
                                                        <div className='d-flex'>
                                                          {' '}
                                                          <div className='status-table-c fixed-wid'>
                                                            Status:
                                                          </div>{' '}
                                                          Complete
                                                        </div>
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </HashLink>
                                              </td>
                                            ) : (
                                              data?.to_date && (
                                                Array(parseInt(
                                                  data?.to_date?.split('-')[2]
                                                ) -
                                                  parseInt(
                                                    data?.sortingData?.split('-')[2]
                                                  ) +
                                                  1).fill(0).map((item, i) =>
                                                    <td
                                                    // colspan={
                                                    //   parseInt(
                                                    //     data?.to_date?.split('-')[2]
                                                    //   ) -
                                                    //   parseInt(
                                                    //     data?.sortingData?.split('-')[2]
                                                    //   ) +
                                                    //   1
                                                    // }

                                                    >

                                                      <div className='outer-td-overflow-no-work'>
                                                        <div className={data?.status == 0 ? "data_item_calendar  holiday-orange-td" : data?.status == 1 && "data_item_calendar  holiday-green-td"}>
                                                          <h6 className='m-0 text-black-cal'>
                                                            {data?.status == 0 ? "Holiday Request Pending Approval" : data?.status == 1 && "Holiday approved"}
                                                          </h6>
                                                        </div>
                                                      </div>

                                                    </td>
                                                  )

                                              )
                                            )}
                                      </>
                                    );
                                  })}
                              {/* {item?.sameDate?.split("-")[2]} */}
                              {
                                item?.sameDate[item?.sameDate?.length - 1]?.sortingData?.split("-")[2] != undefined &&
                                Array(days - parseInt(item?.sameDate[item?.sameDate?.length - 1]?.sortingData?.split("-")[2])).fill(0).map((item, i) =>
                                  <td>
                                  </td>
                                )

                              }
                            </tr>
                          </>
                        );
                      })}


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calender;
