import React from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "../assets/img/sidebar_icon/home.svg";
import AdminIcon from "../assets/img/sidebar_icon/admin.svg";
import ClientIcon from "../assets/img/sidebar_icon/client.svg";
import ContractorIcon from "../assets/img/sidebar_icon/contractors.svg";
import JobIcon from "../assets/img/sidebar_icon/job.svg";
import ProductIcon from "../assets/img/sidebar_icon/product.svg";
import CalendarIcon from "../assets/img/sidebar_icon/calender.svg";
import DocumnetIcon from "../assets/img/sidebar_icon/document.svg";
import Notification from "../assets/img/notification.svg";
import Archived_notification from "../assets/img/archived_notification.svg";
import Information from "../assets/img/information.svg";
import ReportIcon from "../assets/img/report.svg";
import HolidayIcon from "../assets/img/sidebar_icon/holiday.svg";
import TimeSheetIcon from "../assets/img/sidebar_icon/timesheet.svg";

function SideBar() {
  const activeLinRoute = [
    {
      id: 1,
      name: "Dashboard",
      to: "/dashboard",
      className: HomeIcon,
    },
    {
      id: 2,
      name: "Admins",
      to: "/admins/list",
      className: AdminIcon,
    },
    {
      id: 3,
      name: "Clients",
      to: "/clients/list",
      className: ClientIcon,
    },
    {
      id: 4,
      name: "Contractors",
      to: "/contractors/list",
      className: ContractorIcon,
    },
    {
      id: 5,
      name: "Jobs",
      to: "/jobs/list?page=1",
      className: JobIcon,
    },
    {
      id: 6,
      name: "Products",
      to: "/products/list",
      className: ProductIcon,
    },
    {
      id: 7,
      name: "Calendar",
      to: "/calendar",
      className: CalendarIcon,
    },
    {
      id: 8,
      name: "Documents",
      to: "/documents",
      className: DocumnetIcon,
    },
    {
      id: 9,
      name: "Reports",
      to: "/reports",
      className: ReportIcon,
    },
    {
      id: 10,
      name: "Time Sheets",
      to: "/time_sheet",
      className: TimeSheetIcon,
    },
    {
      id: 11,
      name: "Holiday Requests",
      to: "/holiday",
      className: HolidayIcon,
    },
    // {
    //   id: 12,
    //   name: 'Add issue',
    //   to: '/get_issue',
    //   className: Issuedata,
    // },
    {
      id: 13,
      name: "Job Sections",
      to: "/jobsection/list",
      className: JobIcon,
    },
    {
      id: 14,
      name: "Notifications",
      to: "/notification/list",
      className: Notification,
    },
    {
      id: 15,
      name: "Archived Notifications",
      to: "/notification/archive",
      className: Archived_notification,
    },
    {
      id: 16,
      name: "Faqs",
      to: "/faqs",
      className: Information,
    },
  ];

  const handleLogout = (e) => {
    localStorage.removeItem("accessToken");
    window.location.reload(false);
    window.localStorage.clear();
    window.location.href = "/signin";
  };
  var roles;
  if (localStorage.getItem("roles") == "superadmin") {
    roles = "superadmin";
  } else if (localStorage.getItem("roles") == "admin") {
    roles = "admin";
  }
  const { pathname } = useLocation();

  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          {activeLinRoute?.map((link) => (
            <div id="sidebar-menu" className="sidebar-menu" key={link.id}>
              <ul>
                {roles == "superadmin" ? (
                  <>
                    <li className={link.to === pathname ? "active" : ""}>
                      <Link to={link.to}>
                        <img
                          src={link.className}
                          alt="sidebar icon"
                          className="sidebar__icon"
                        />
                        <span>{link.name}</span>
                      </Link>
                    </li>
                  </>
                ) : roles == "admin" ? (
                  <li className={link.to === pathname ? "active" : ""}>
                    <Link
                      className={
                        link.name != "Admins" && link.name != "Products"
                          ? ""
                          : "d-none"
                      }
                      to={
                        link.name != "Admins" &&
                        link.name != "Products" &&
                        link.to
                      }
                    >
                      <img
                        src={
                          link.name != "Admins" &&
                          link.name != "Products" &&
                          link.className
                        }
                        alt="sidebar icon"
                        className={
                          link.name != "Admins" && link.name != "Products"
                            ? "sidebar__icon"
                            : "d-none"
                        }
                      />
                      <span>
                        {link.name != "Admins" &&
                          link.name != "Products" &&
                          link.name}
                      </span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          ))}
        </div>
        <div className="logout" onClick={() => handleLogout()}>
          <a href="#">Log Out</a>
        </div>
      </div>
    </>
  );
}

export default SideBar;
