import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { getContractorById, editContractor } from '../../Redux/Action/SuperAdminAction';
import * as Yup from "yup";
function EditContractor() {
    const history = useHistory();
    const dispatch = useDispatch()
    const { contractorId } = useParams()

    const formik = useFormik({
        initialValues: {
            city: "",
            pinCode: "",
            streetAddress: "",
            email: "",
            dob: "",
            firstName: "",
            lastName: "",
            phone: "",
            Hourly_Rate: "",
            NI_Number: "",
            UTR_Number: ""
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required("FirstName is Required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
            lastName: Yup.string()
                .required("LastName is Required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
            city: Yup.string()
                .required("City is Required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
            streetAddress: Yup.string()
                .required("StreetAddress is Required"),
            email: Yup.string()
                .email("Invalid email address")
                .required("Email Required!"),
            pinCode: Yup.string()
                .required("Post Code is Required"),
            phone: Yup.string().required("Phone is Required").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid'),
            Hourly_Rate: Yup.number().required("Hour Rate is Required"),
            NI_Number: Yup.string()
                .required("NI_Number  is Required"),
            dob: Yup.string()
                .required("Date of Birth  is Required"),
            UTR_Number: Yup.string()
                .required("UTR_Number is Required"),
        }),
        onSubmit: values => {
            values.id = contractorId
            dispatch(editContractor(values, history))
        },
    });

    useEffect(() => {
        dispatch(getContractorById(contractorId)).then((admin) => {
            const fields = ['email', 'firstName', "pinCode", 'lastName', 'phone', "dob", "Hourly_Rate", "NI_Number", "UTR_Number", "city", "streetAddress"];
            fields.forEach(field => formik.setFieldValue(field, admin[field], false));
        })
    }, [contractorId])
    return (
        <div className="page-wrapper">
            <form className="content container-fluid" onSubmit={formik.handleSubmit}>
                <div className="page-header second-page-header ">
                    <div className="row align-items-center">
                        <div className="col">

                            <h3 className="page-title second-page-title ">Personal Information</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item "> <span>Use a permanent address where you can recieve mail.</span>    </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">First Name <span className="text-danger">*</span></label>
                            <input className="form-control" type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="firstName"
                                value={formik.values.firstName}
                            />
                            {formik.touched.firstName && formik.errors.firstName ? (
                                <p style={{ color: "red" }}>{formik.errors.firstName}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">Last Name <span className="text-danger">*</span></label>
                            <input className="form-control" type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="lastName"
                                value={formik.values.lastName}
                            />
                            {formik.touched.lastName && formik.errors.lastName ? (
                                <p style={{ color: "red" }}>{formik.errors.lastName}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">Email <span className="text-danger">*</span></label>
                            <input className="form-control" type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="email"
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <p style={{ color: "red" }}>{formik.errors.email}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">Phone Number <span className="text-danger">*</span></label>
                            <input className="form-control" type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                                name="phone"
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <p style={{ color: "red" }}>{formik.errors.phone}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">NI Number <span className="text-danger">*</span></label>
                            <input className="form-control" type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.NI_Number}
                                name="NI_Number"
                            />
                            {formik.touched.NI_Number && formik.errors.NI_Number ? (
                                <p style={{ color: "red" }}>{formik.errors.NI_Number}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">UTR Number <span className="text-danger">*</span></label>
                            <input className="form-control" type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.UTR_Number}
                                name="UTR_Number"
                            />
                            {formik.touched.UTR_Number && formik.errors.UTR_Number ? (
                                <p style={{ color: "red" }}>{formik.errors.UTR_Number}</p>
                            ) : null}
                        </div>
                    </div>



                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">Hourly Rate <span className="text-danger">*</span></label>
                            <input className="form-control" type="number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Hourly_Rate}
                                name="Hourly_Rate"
                            />
                            {formik.touched.Hourly_Rate && formik.errors.Hourly_Rate ? (
                                <p style={{ color: "red" }}>{formik.errors.Hourly_Rate}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">DATE OF BIRTH <span className="text-danger">*</span></label>
                            <input className="form-control" type="date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.dob}
                                name="dob"
                            />
                            {formik.touched.dob && formik.errors.dob ? (
                                <p style={{ color: "red" }}>{formik.errors.dob}</p>
                            ) : null}
                        </div>
                    </div>

                </div>

                <div className="page-header mt-5 second-page-header">
                    <div className="row align-items-center">
                        <div className="col">

                            <h3 className="page-title second-page-title">Address  Information</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item "> <span>Use a permanent address where you can recieve mail.</span>    </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="row">


                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">City <span className="text-danger">*</span></label>
                            <input className="form-control"
                                name='city'
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                            />
                            {formik.touched.city && formik.errors.city ? (
                                <p style={{ color: "red" }}>{formik.errors.city}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="col-form-label">Post Code <span className="text-danger">*</span></label>
                            <input className="form-control"
                                name='pinCode'
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.pinCode}
                            />
                            {formik.touched.pinCode && formik.errors.pinCode ? (
                                <p style={{ color: "red" }}>{formik.errors.pinCode}</p>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="col-form-label">Street Address <span className="text-danger">*</span></label>
                            <input className="form-control" type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name='streetAddress'
                                value={formik.values.streetAddress}
                            />
                            {formik.touched.streetAddress && formik.errors.streetAddress ? (
                                <p style={{ color: "red" }}>{formik.errors.streetAddress}</p>
                            ) : null}
                        </div>
                    </div>
                    <div className="border-t-c">
                        <div className="col-md-12">
                            <div className="mt-3">

                                <button className="btn custom_btn  mr-3 btn-md" onClick={() => history.push("/contractors/list")}>Cancel</button>
                                <button className="btn btn-primary btn-md">Save</button>
                            </div>
                        </div>
                    </div>
                </div>







            </form>

        </div>
    )
}

export default EditContractor
