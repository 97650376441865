import React from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { resetPassword } from "../../Redux/Action/SuperAdminAction"
function ForgetPassword() {
    const { id } = useParams()
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            new_password: '',
            confirm_password: ""
        },
        validationSchema: Yup.object({
            new_password: Yup.string().required('Password is required'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        }),
        onSubmit: values => {
            values.id = id
            dispatch(resetPassword(values))
        },
    });
    return (
        <div className="page-wrapper">
            <div className="content container-fluid" >
                <div className="page-header">
                    <div className=" out-box">
                        <h3 className="page-title second-page-title">Reset Password</h3>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group mt-4">
                                <label className="col-form-label">New Password<span className="text-danger">*</span></label>
                                <input className="form-control mb-3" name='new_password' type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.new_password}
                                />
                                {formik.touched.new_password && formik.errors.new_password ? (
                                    <p style={{ color: "red" }}>{formik.errors.new_password}</p>
                                ) : null}
                                <label className="col-form-label ">Confirm New Password<span className="text-danger">*</span></label>
                                <input className="form-control" name='confirm_password' type="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirm_password}
                                />
                                {formik.touched.confirm_password && formik.errors.confirm_password ? (
                                    <p style={{ color: "red" }}>{formik.errors.confirm_password}</p>
                                ) : null}
                                <button style={{ marginTop: "15px" }} className="btn btn-primary form-control" >Update</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>


        </div>
    )
}

export default ForgetPassword
