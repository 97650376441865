import axios from 'axios';
import { toast } from '../components/ToastContainer/Toast';
import { API_URL } from '../config';
const API = axios.create({
  baseURL: `${API_URL}`,
  // timeout: 2000,
  headers: {
    device_token: localStorage.getItem('device_token'),
    authorization: localStorage.getItem('accessToken'),
  },
});
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      // toast.error('Session Expired');
      setTimeout(() => {
        localStorage.clear();
        window.location.reload(false);
        window.location.href = '/signin';
      }, 1000);
    }

    if (error?.response?.status === 404) {
    }
    // console.log('error', error.status);
    //return Promise.reject(error);
  }
);

export default API;
