import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  getClinetList,
  getContractorList,
  createJob,
} from '../../Redux/Action/SuperAdminAction';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { toast } from '../../components/ToastContainer/Toast';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
const PDFJS = window.pdfjsLib;

function AddJob() {

  const history = useHistory();
  const dispatch = useDispatch();
  const [selectcontractor, setselectcontractor] = useState(false);
  const [forvar, setforvar] = useState({
    clientId: '',
    pinCode: '',
    addressLine1: '',
    addressLine2: '',
    sitedetail: '',
    asbestosOnSite: '',
    parking: '',
    completedBY: '',
    fraChecked: '',
    town_or_city: '',
    pdf_file: ""
  });
  const wrapperRef = useRef(null);
  const [showSection, setShowSection] = useState(false);
  const [typeSuper, setType] = useState('');
  const [addressApi, setAddressApi] = useState('');
  const [pdfImg, setPdfImg] = useState();
  const [floor1, setFloor1] = useState();
  const [floor2, setFloor2] = useState();
  const [selectcontractorIndex, setselectcontractorIndex] = useState();
  const handleClose = () => setselectcontractor(false);
  const [inputList, setInputList] = useState([]);
  const [pdf, setPdf] = React.useState("");
  const [sectionOpen, setsectionOpen] = useState(false);
  const [rangeStart, setRangeStart] = useState('');
  const [images, setImages] = React.useState([]);
  const [typeImg, setTypeImg] = useState();

  const clientName = useSelector((state) => state.adminList.clientList);
  const contractor_history = useSelector((state) => state.adminList.contractorHistory);
  const contractorName = useSelector((state) => state?.adminList?.contractorList);

  const handleShow = (x, i) => {
    setselectcontractorIndex(i);
    setselectcontractor(true);
    setType('');
  };

  const handleShow2 = (x, i) => {
    setselectcontractorIndex(i);
    setselectcontractor(true);
    setType('super');
  };


  const handleInputChange = (e, index, datepicker) => {
    if (datepicker == 'datepicker') {
      const list = [...inputList];

      list[index]['completedBy'] = e;
      setInputList(list);
    } else {
      const { name, value } = e.target;
      const list = [...inputList];

      list[index][name] = value;
      setInputList(list);
    }
  };

  const handleRemoveClick = (index, e) => {
    e.preventDefault()
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };


  const selectStartDate = (i, d) => {
    function convert(str) {
      var date = new Date(str),
        mnth = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join('-');
    }

    setRangeStart(d);
    handleInputChange(convert(d), i, 'datepicker');
  };

  const handleChangeForm = (e, type) => {
    if (type == 'custom') {
      setforvar({
        ...forvar,
        ["addressLine1"]: addressApi?.addresses[e.target.value]?.line_1,
        ["addressLine2"]: addressApi?.addresses[e.target.value]?.line_2,
        ["town_or_city"]: addressApi?.addresses[e.target.value]?.town_or_city,
        ["latitude"]: addressApi?.latitude,
        ["longitude"]: addressApi?.longitude
      });
    } else if (type == 'file') {
      setforvar({
        ...forvar,
        ["pdf_file"]: e,
      });

    } else if (type == 'floor1') {
      setforvar({
        ...forvar,
        ["floor_img1"]: e,
      });
      setFloor1(e?.name);
    } else if (type == 'floor2') {
      setforvar({
        ...forvar,
        ["floor_img2"]: e,
      });
      setFloor2(e?.name);
    } else {
      const { name, value } = e.target;
      setforvar({
        ...forvar,
        [name]: e.target.value,
      });

    }

  };

  async function showPdf(event, type) {
    try {
      const file = event.target.files[0];
      const uri = URL.createObjectURL(file);
      var _PDF_DOC = await PDFJS.getDocument({ url: uri });
      setPdf(_PDF_DOC);
      setTypeImg(type)
      if (type == "floor1") {
        localStorage.setItem("floor1", file?.name)
      } else {
        localStorage.setItem("floor2", file?.name)
      }

      async function renderPage() {
        const imagesList = [];
        const canvas = document.createElement("canvas");
        canvas.setAttribute("className", "canv");

        for (let i = 1; i <= _PDF_DOC.numPages; i++) {
          var page = await _PDF_DOC.getPage(i);
          var viewport = page.getViewport({ scale: 1 });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          var render_context = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport
          };
          await page.render(render_context).promise;
          let img = canvas.toDataURL("image/png");
          imagesList.push(img);
        }
        setImages(imagesList);
        handleChangeForm(imagesList[0], type)
      }

      renderPage();

      document.getElementById("file-to-upload").value = "";
    } catch (error) {
      //  alert(error.message);
    }
  }
  // async function renderPage() {
  //   const imagesList = [];
  //   const canvas = document.createElement("canvas");
  //   canvas.setAttribute("className", "canv");

  //   for (let i = 1; i <= pdf.numPages; i++) {
  //     var page = await pdf.getPage(i);
  //     var viewport = page.getViewport({ scale: 1 });
  //     canvas.height = viewport.height;
  //     canvas.width = viewport.width;
  //     var render_context = {
  //       canvasContext: canvas.getContext("2d"),
  //       viewport: viewport
  //     };
  //     console.log("page lenght", pdf.numPages);

  //     await page.render(render_context).promise;
  //     let img = canvas.toDataURL("image/png");
  //     imagesList.push(img);
  //   }
  //   setImages(imagesList);
  //   console.log(typeImg, imagesList, "typeImg")
  //   handleChangeForm(imagesList[0], typeImg)
  // }

  // useEffect(() => {
  //   pdf && renderPage(typeImg);
  //   // eslint-disable-next-line
  // }, [pdf, typeImg]);
  function createSection() {
    return inputList?.map((x, i) => (
      <div key={i} className='row'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='card-body'>
              <div className='com_sect'>
                <div className='section-title-c'>
                  <strong>Section {i + 1}</strong>
                </div>
                <div className='mt-3 form-group'>
                  <label for='' className='me-2'>Complete By</label>
                  <DatePicker
                    dateFormat={'dd.MM.yyyy'}
                    className='form-control'
                    selectsStart
                    selected={
                      x.completedBy == '' ? '' : new Date(x.completedBy)
                    }
                    placeholderText='Select Date'
                    onChange={(d) => selectStartDate(i, d)}
                  />
                </div>
                <div className=' form-group'>
                  <label for=''>Job Type</label>
                  <select
                    onChange={(e) => handleInputChange(e, i)}
                    name='jobType'
                    className='form-control'
                    id=''
                  >
                    <option value='' className='d-none'>
                      Select Job Type
                    </option>
                    <option value='Joinery'>Joinery</option>
                    <option value='Fire Stopping'>Fire Stopping</option>
                    <option value='Electrical/Alarm'>Electrical/Alarm</option>
                    <option value='Decorations'>Decorations</option>
                    <option value='Surveying'>Surveying</option>
                  </select>
                </div>
                {/* ORCHARD NUMBER OPTIONAL */}
                <div className='mt-3 form-group'>
                  <label for=''>Orchard number(Optional)</label>
                  <input
                    value={x.chard_number}
                    name='chard_number'
                    onChange={(e) => handleInputChange(e, i)}
                    className='form-control'
                    type='text'
                  />
                </div>
                {/* END OF ORCHARD NUMBER OPTIONAL */}


                <div className=' form-group'>
                  <label for=''>Assigned to</label>
                  <div
                    className='static-input form-control'
                    onClick={() => handleShow(x, i)}
                  >
                    {inputList[i]['comtractorName']
                      ? ` ${inputList[i]['comtractorName'].join(" ,")}`
                      : 'Select Contractor'}
                  </div>
                </div>
                <div className=' form-group'>
                  <label for=''>Assigned Supervisor (optional)</label>
                  <div
                    className='static-input form-control'
                    onClick={() => handleShow2(x, i)}
                  >
                    {inputList[i]['comtractorName2']
                      ? inputList[i]['comtractorName2']
                      : 'Select Contractor'}
                  </div>
                </div>

                <div className='form-group'>
                  <label for=''>Details</label>
                  <textarea
                    value={x.details}
                    onChange={(e) => handleInputChange(e, i)}
                    name='details'
                    className='form-control'
                    id=''
                    cols='30'
                    rows='3'
                  ></textarea>
                </div>

                <div className=' form-group'>
                  <button
                    className='btn btn-danger'
                    onClick={(e) => handleRemoveClick(i, e)}
                  >
                    Remove Section
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
  const addClick = (e) => {
    e.preventDefault();
    setInputList([
      ...inputList,
      {
        completedBy: '',
        jobType: '',
        contractorId: [],
        comtractorName: [],
        supervisor: '',
        supervisor_name: '',
        chard_number: '',
        details: '',
      },
    ]);
    setsectionOpen(e.target.id);
    setShowSection(true);
    createSection();
  };

  const handleContarctor = (e, contractor, selectcontractorIndex) => {
    const list = [...inputList];

    if (typeSuper == 'super') {
      list[selectcontractorIndex]['supervisor'] = contractor?._id;
      list[selectcontractorIndex]['comtractorName2'] =
        contractor?.firstName + ' ' + contractor?.lastName;
    } else {
      // list[selectcontractorIndex]['contractorId'].push(contractor?._id)
      // list[selectcontractorIndex]['comtractorName'].push(contractor?.firstName + ' ' + contractor?.lastName)
      if (!list[selectcontractorIndex]['contractorId'].includes(contractor?._id)) {

        list[selectcontractorIndex]['contractorId'].push(contractor?._id)
        list[selectcontractorIndex]['comtractorName'].push(contractor?.firstName + ' ' + contractor?.lastName)

      } else {
        list[selectcontractorIndex]['contractorId'].splice(list[selectcontractorIndex]['contractorId'].indexOf(contractor?._id), 1);
        list[selectcontractorIndex]['comtractorName'].splice(list[selectcontractorIndex]['comtractorName'].indexOf(contractor?.firstName + ' ' + contractor?.lastName), 1);
      }

    }
    setInputList(list);
    if (contractor?._id) {
      // setselectcontractor(false);
    }
  };

  const submitDetail = (e, type) => {
    let userId = localStorage.getItem('userId')
    e.preventDefault();
    let data = {
      ...forvar,
    };
    data.JobSection = inputList;
    data.createBy = userId || '';
    if (
      forvar.clientId == ''
    ) {
      return toast.info('Please select client first.');
    }
    if (forvar && inputList) {
      // if (data.JobSection.length > 0) {
        for (let i = 0; i < data.JobSection.length; i++) {
          if (data.JobSection[i].completedBy === '') {
            return toast.info('Please select Complete By first.');
          }else if (data.JobSection[i].jobType === '') {
            return toast.info('Please select Job Type first.');
          }
        }

      let formData = new FormData();
      for (const [key, value] of Object.entries(data)) {

        if (value?.length > 0 && key == "JobSection") {

          formData.append(key, JSON.stringify(value));
        } else {

          formData.append(key, value);
        }

      }
      if (forvar?.floor_img1 != undefined || forvar?.floor_img2 != undefined) {
        if (data?.JobSection?.length > 0) {
          dispatch(createJob(data, history, "maintain_state"));
          history.push("/preview-image")
        }
        else {
          dispatch(createJob(formData, history));
        }

      } else {
        dispatch(createJob(formData, history));
      }
    }
  };

  // const handlePostCodeSearch = (e) => {
  //   setpostCode_search(e.target.value);
  // }

  const postCodeClick = async (e) => {
    try {
      e.preventDefault()
      const url = `https://api.getaddress.io/find/${forvar?.pinCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      const response = await fetch(url);
      const json = await response.json();
      setAddressApi(json);
    } catch (error) {
      console.log('error', error);
    }
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          alert("You clicked outside of me!");
          //  setDisplay(true);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const _handleUpload = (e, type) => {
    if (type == "file") {
      setPdfImg(e.target.files[0]?.name);
      let fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0])
      fileReader.onload = () => {
        let baseURL = fileReader.result;
        handleChangeForm(baseURL, type)
      };
    } else {
      handleChangeForm(e.target.files[0], type)
    }

  }

  useEffect(() => {
    dispatch(getClinetList());
    dispatch(getContractorList());
    localStorage.setItem("floor1", "");
    localStorage.setItem("floor2", "");
  }, []);

  return (
    <div className='page-wrapper'>
      <form className='content container-fluid'>
        <div className='page-header second-page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title'>Client Details</h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>Information on the client and job site.</span>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>

          <div className='col-md-6'>
            <div className='form-group'>
              <label className='col-form-label'>
                Client <span className='text-danger'>*</span>
              </label>
              <select
                name='clientId'
                className='form-control'
                onChange={(e) => handleChangeForm(e)}
              >
                <option value='' className='d-none'>
                  Select Client
                </option>
                {clientName?.data?.map((clientList) => (
                  <>
                    <option value={clientList._id}>
                      {clientList?.clientName}
                    </option>
                  </>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className='page-header mt-5 second-page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title'>Job Address </h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>Confirm the address details for the job</span>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-5'>
            <div className='form-group mb-2'>
              <label className='col-form-label mb-0'>
                Post Code Search <span className='text-danger' >*</span>
              </label>
              <div className='d-flex justify-content-between'>
                <div className='w80'>
                  <input
                    type='text'
                    className='form-control select-drop-input'
                    onChange={(e) => handleChangeForm(e)}
                    name='pinCode'
                    value={forvar?.pinCode}
                  />
                  <select
                    className='custom-select-in'
                    onChange={(e) => handleChangeForm(e, "custom")}
                  >
                    {addressApi?.addresses?.length > 0 && addressApi?.addresses?.map((item, i) => {
                      return (
                        <option value={i} >{`${item?.line_1},${item?.line_2},${item?.town_or_city}`}</option>
                      )
                    })}
                  </select>


                </div>
                <div className='w20'>
                  <button type="submit" onClick={(e) => postCodeClick(e)} className='btn btn-primary submit-btn-part'>Search</button>
                </div>

              </div>

            </div>
            <div className='form-group'>
              <label className='col-form-label mb-0'>
                Address<span className='text-danger'>*</span>
              </label>
              {/* <input
                type='text'
                className='form-control'
                onChange={(e) => handleChangeForm(e)}
                name='addressLine1'
                value={forvar.addressLine1}
              /> */}
              <input
                type='text'
                value={forvar?.addressLine1}
                className='form-control mb-2'
                placeholder='First Line'
                onChange={(e) => handleChangeForm(e)}
                name='addressLine1'
              />
              <input
                type='text'
                value={forvar?.addressLine2}
                className='form-control mb-2'
                placeholder='Second Line'
                onChange={(e) => handleChangeForm(e)}
                name='addressLine2'
              />
              <input
                type='text'
                value={forvar?.town_or_city}
                className='form-control mb-2'
                placeholder='City/Town'
                onChange={(e) => handleChangeForm(e)}
                name='town_or_city'
              />
              <input
                type='text'
                value={forvar?.pinCode}
                className='form-control'
                placeholder='Post Code'
                onChange={(e) => handleChangeForm(e)}
                name='pinCode'
              />
            </div>
          </div>
          <div className='col-md-5'>
            <div className='form-group mb-2'>
              <label className='col-form-label mb-0'>
                Site Details <span className='text-danger'>*</span>
              </label>
              <textarea
                type='text'
                className='form-control full-height'
                onChange={(e) => handleChangeForm(e)}
                name='sitedetail'
                value={forvar.sitedetail}
              />
            </div>
            <div className='form-group'>
              <label className='col-form-label mb-0'>
                Parking <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                onChange={(e) => handleChangeForm(e)}
                name='parking'
                value={forvar.parking}
              />
            </div>
          </div>
        </div>
        <div className='page-header mt-5 second-page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title'>
                Flamehold & Contractors{' '}
              </h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '> </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='col-form-label'>
                <strong>Asbestos on Site </strong>
                <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='radio-group'>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='asbestosOnSite'
                      value='Yes'
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Yes</label>
                  </div>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='asbestosOnSite'
                      value='No'
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>No</label>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='radio-group'>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='asbestosOnSite'
                      value='Unknown'
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Unknown</label>
                  </div>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='asbestosOnSite'
                      value={'Presumed High'}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Presumed High</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='col-form-label'>
                <strong>FRA Checked</strong>{' '}
                <span className='text-danger'>*</span>
              </label>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='radio-group'>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='fraChecked'
                      value={'Yes'}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Yes</label>
                  </div>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='fraChecked'
                      onChange={(e) => handleChangeForm(e)}
                      value={'No'}
                    />
                    <label for=''>No</label>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='radio-group'>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='fraChecked'
                      value={'Unknown'}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Unknown</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="assessment_card mt-5">
          <h3 className='mb-0'>
            Risk Assessment
          </h3>
          <p className=''>
            Upload a risk assessment for the job
          </p>
          <div className="d-flex">
            <div className="input_card floor_input_card">
              <p className="text-cenetr mb-0 floor_input">{pdfImg}</p>
            </div>
            <div className="upload-btn-wrapper">
              <button className="btn">Upload</button>
              <input type="file" onChange={(e) => {
                _handleUpload(e, "file");
              }} name="myfile" />
            </div>
          </div>

        </div>

        <div className="assessment_card mt-4 assessment_card2">
          <h3 className='mb-0'>
            Floor Plans
          </h3>
          <p className=''>
            Upload plans for the property
          </p>
          <div className='d-flex '>
            <div className="d-flex">
              <div className="input_card floor_input_card">
                <p className="text-cenetr mb-0 floor_input">{floor1 == undefined ? localStorage.getItem("floor1") : floor1}</p>
              </div>
              <div className="upload-btn-wrapper">
                <div className="upload-btn-default">Upload</div>
                <input type="file" onChange={(e) => {

                  e.target.files[0]?.type == "application/pdf" ? showPdf(e, 'floor1') :
                    _handleUpload(e, "floor1");

                }} name="myfile" />
              </div>
            </div>
            <div className="d-flex ms-4">
              <div className="input_card floor_input_card">
                <p className="text-cenetr mb-0 floor_input">{floor2 == undefined ? localStorage.getItem("floor2") : floor2}</p>
              </div>
              <div className="upload-btn-wrapper">
                <div className="upload-btn-default">Upload</div>
                <input type="file" onChange={(e) => {

                  e.target.files[0]?.type == "application/pdf" ? showPdf(e, 'floor2') :
                    _handleUpload(e, "floor2")
                }} name="myfile" />
              </div>
            </div>



          </div>



        </div>

        <div className='page-header mt-5 second-page-header'>
          <div className='row align-items-center'>
            <div className='col-10 '>
              <h3 className='page-title second-page-title'>Job Sections</h3>
              {/* <ul className='breadcrumb'>
                <li className='breadcrumb-item '> </li>
              </ul> */}
            </div>
          </div>
        </div>
        {showSection == true && createSection()}
        {/* <div className='d-flex justify-content-end mt-4'>
        
        </div> */}
        <div className='border-t-c'></div>
        <div className='fixed-bottom-btn'>
          <div className='row d-flex align-items-center'>
            <div className='col-md-8'>
              <div className='mt-2 mb-2'>
                <button
                  className='btn custom_btn  mr-3 btn-md'
                  onClick={(e) => {
                    e.preventDefault()
                    history.goBack()
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={!inputList}
                  onClick={(e) => submitDetail(e)}
                  type='submit'
                  className='btn btn-primary btn-md'
                >
                  Save
                </button>
              </div>
            </div>
            <div className='col-md-4'>
              <button
                className='btn btn-primary p-2'
                id='addclick'
                onClick={(e) => addClick(e)}
              >
                {' '}
                Add Section
              </button>
            </div>

            {/* <div className='col-md-3'>
              <div className='mt-2 mb-2'>
                <button
                  onClick={(e) => submitDetail(e, "maintain_state")}
                  className='floor-btn'>
                  Floor Plans
                </button>
              </div>
            </div> */}
          </div>
        </div>

        <Modal show={selectcontractor} onHide={handleClose} animation={false} className="add_contractor">
          <Modal.Header closeButton>
            <Modal.Title>Contractor</Modal.Title>
          </Modal.Header>
          <Modal.Body className='overflowScroll h-455'>
            <Accordion defaultActiveKey='0'>
              {contractorName?.data?.map((contractName, i) => {
                return (
                  <>
                    <Accordion.Item eventKey={i}>
                      {typeSuper == 'super'
                        ? inputList &&
                        inputList[selectcontractorIndex] &&
                        inputList[selectcontractorIndex]['contractorId'] !=
                        contractName._id && (
                          <>
                            <Accordion.Header>
                              <div className='d-flex'>
                                <div className='two-colum'>
                                  <div
                                    className='d-flex'
                                    id='contractorId'
                                    onClick={(e) =>
                                      handleContarctor(
                                        e,
                                        contractName,
                                        selectcontractorIndex
                                      )
                                    }
                                  >

                                    {/* <form>
                                      <div className=' d-flex justify-content-center align-items-center checkbox_placement form-group'>
                                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                      </div>
                                    </form> */}

                                    <div className='profile-placeholder'>
                                      {contractName?.firstName
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        contractName?.lastName
                                          ?.charAt(0)
                                          .toUpperCase()}
                                    </div>

                                    <div className='user-info-output'>
                                      <div className='user-name blue-color-text'>
                                        {contractName?.firstName +
                                          ' ' +
                                          contractName?.lastName}
                                      </div>
                                      <div className='comment-time'>
                                        <i
                                          class='fa fa-phone pr-2'
                                          aria-hidden='true'
                                        ></i>
                                        {contractName?.phone}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='pt-2 m mb-0'>
                                  <div className='d-flex'>
                                    <div className='user-info-output'>
                                      <div className='assigned'>
                                        <div className='assign-label'>
                                          Assigned
                                        </div>
                                        <p>
                                          {moment(contractName?.createdAt
                                          ).format('Do MMM YY')}
                                          {/* <span>
                                  {' '}
                                  {moment(contractName?.createdAt)
                                    .add(1, 'months')
                                    .calendar()}
                                </span> */}
                                        </p>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                {contractor_history &&
                                  contractor_history[i]?.map(
                                    (item, i) => (
                                      <>
                                        <div className='contractor-status-align'
                                          stryle={{
                                            marginBottom: '6px',
                                          }}
                                        >
                                          <span
                                            style={{ color: 'green' }}
                                          >
                                            {item.jobType}
                                          </span>
                                          <span className='grey-text-date'>
                                            {moment(
                                              item?.createdAt
                                            ).format('Do MMM YY')}
                                          </span>
                                        </div>

                                      </>
                                    )
                                  )}
                              </p>
                            </Accordion.Body>
                          </>
                        )
                        : inputList &&
                        inputList[selectcontractorIndex] &&
                        inputList[selectcontractorIndex]['supervisor'] !=
                        contractName._id && (
                          <>
                            <div className='according-data-pop-c'>
                              <div className='check-part-pop'>
                                <form>
                                  <div onClick={(e) =>
                                    handleContarctor(
                                      e,
                                      contractName,
                                      selectcontractorIndex
                                    )
                                  } className='checkbox_placement'>
                                    <input type="checkbox"
                                      id="vehicle1"
                                      name="vehicle1"
                                      checked={inputList[selectcontractorIndex]['contractorId']?.includes(contractName?._id) ? true : false}
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className='accroding-data'>
                                <Accordion.Header>
                                  <div className='d-flex'>
                                    <div className='two-colum'>
                                      <div
                                        className='d-flex'
                                        id='contractorId'
                                      // onClick={(e) =>
                                      //   handleContarctor(
                                      //     e,
                                      //     contractName,
                                      //     selectcontractorIndex
                                      //   )
                                      // }
                                      >
                                        <div className='profile-placeholder'>
                                          {contractName?.firstName
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            contractName?.lastName
                                              ?.charAt(0)
                                              .toUpperCase()}
                                        </div>

                                        <div className='user-info-output'>
                                          <div className='user-name blue-color-text'>
                                            {contractName?.firstName +
                                              ' ' +
                                              contractName?.lastName}
                                          </div>
                                          <div className='comment-time'>
                                            <i
                                              class='fa fa-phone pr-2'
                                              aria-hidden='true'
                                            ></i>
                                            {contractName?.phone}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='pt-2 mb-0'>
                                      <div className='d-flex'>
                                        <div className='user-info-output'>
                                          <div className='assigned'>
                                            <div className='assign-label'>
                                              Assigned
                                            </div>
                                            <p>
                                              {moment(
                                                contractName?.createdAt
                                              ).format('Do MMM YY')}
                                              {/* <span>
                                            {' '}
                                            {moment(contractName?.createdAt)
                                              .add(1, 'months')
                                              .calendar()}
                                          </span> */}
                                            </p>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Header>
                              </div>
                            </div>
                            <Accordion.Body>
                              <p>
                                {contractor_history &&
                                  contractor_history[i]?.map(
                                    (item, i) => (
                                      <>
                                        <div className='contractor-status-align'
                                          stryle={{
                                            marginBottom: '6px',
                                          }}
                                        >
                                          <span
                                            style={{ color: 'green' }}
                                          >
                                            {item.jobType}
                                          </span>
                                          <span className='grey-text-date'>
                                            {moment(
                                              item?.createdAt
                                            ).format('Do MMM YY')}
                                          </span>
                                        </div>
                                      </>
                                    )
                                  )}
                              </p>
                            </Accordion.Body>
                          </>
                        )}

                    </Accordion.Item>
                  </>
                );
              })}
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button className='btn-new' onClick={handleClose}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
}

export default AddJob;
