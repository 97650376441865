export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_REQUEST = 'USER_REGISTER_SUCCESS';
export const USER_LOGIN_SUCCESS = 'USER_REGISTER_FAIL';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const START_LOADING_JOBS = 'LOADING_JOBS';
export const STOP_LOADING_JOBS = 'LOADING_JOBS';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAIL = 'CREATE_ADMIN_FAIL';
export const GET_ADMIN_LIST = 'GET_ADMIN_LIST';
export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const GET_CONTRACTOR_LIST = 'GET_CONTRACTOR_LIST';
export const CREATE_CONTRACTOR_SUCCESS = 'CREATE_CONTRACTOR_SUCCESS';
export const DELETE_ADMIN_SUCCESSFULLY = 'DELETE_ADMIN_SUCCESSFULLY';
export const DELETE_CONTRACTOR_SUCCESSFULLY = 'DELETE_CONTRACTOR_SUCCESSFULLY';
export const GET_SINGLE_ADMIN = 'GET_SINGLE_ADMIN';
export const GET_SINGLE_CONTRACTOR = 'GET_SINGLE_CONTRACTOR';
export const JOB_CREATED_SUCCESSFULLY = 'JOB_CREATED_SUCCESSFULLY';
export const GET_ALL_JOB = 'GET_ALL_JOB';
export const DELETE_JOB_SUCCESSFULLY = 'DELETE_JOB_SUCCESSFULLY';
export const VIEW_JOB_SUCCESSFULLY = 'VIEW_JOB_SUCCESSFULLY';
export const UPLAOD_DOCUMENT_SUCCESSFULLY = 'UPLAOD_DOCUMENT_SUCCESSFULLY';
export const GET_ALL_DOCUMENTS = 'GET_ALL_DOCUMENTS';
export const GET_ALL_HOLIDAY_LIST = 'GET_ALL_HOLIDAY_LIST';
export const APPROVED_SUCCESSFULLY = 'APPROVED_SUCCESSFULLY';
export const DELETE_DOCUMENT_SUCCESSFULLY = 'DELETE_DOCUMENT_SUCCESSFULLY';
export const GET_ISSUE_REQUEST = 'GET_ISSUE_REQUEST';
export const GET_ISSUE_SUCCESS = 'GET_ISSUE_SUCCESS';
export const ISSUE_SAVE_SUCCESSFULLY = 'ISSUE_SAVE_SUCCESSFULLY';
export const GET_FORM_SUCCESSFULLY = 'GET_FORM_SUCCESSFULLY';
export const GET_PRODUCT_SUCCESSFULLY = 'GET_PRODUCT_SUCCESSFULLY';
export const GET_CATEGORY_SUCCESSFULLY = 'GET_CATEGORY_SUCCESSFULLY';
export const CREATE_PRODUCT_SUCCESSFULLY = 'CREATE_PRODUCT_SUCCESSFULLY';
export const CREATE_PRODUCTLIST_SUCCESSFULLY =
  'CREATE_PRODUCTLIST_SUCCESSFULLY';
export const GET_SUBFORMS_REQUEST = 'GET_SUBFORMS_REQUEST';
export const GET_SUBFORMS_SUCCESS = 'GET_SUBFORMS_SUCCESS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST';
export const GET_JOBSECTION_SUCCESS = 'GET_JOBSECTION_SUCCESS';
export const GET_JOBSECTION_REQUEST = 'GET_JOBSECTION_REQUEST';
export const EDIT_VIEWJOB_SUCCESSFULLY = 'EDIT_VIEWJOB_SUCCESSFULLY';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_BY_ID_SUCCESS = 'GET_REPORTS_BY_ID_SUCCESS';
export const GET_REPORTS_SECTION_REQUEST = 'GET_REPORTS_SECTION_REQUEST';
export const GET_REPORTS_JOBTYPE_SUCCESS = 'GET_REPORTS_JOBTYPE_SUCCESS';
export const GET_REPORTS_JOBTYPE_REQUEST = 'GET_REPORTS_JOBTYPE_REQUEST';
export const GET_REPORTS_ALLJOBTYPE_SUCCESS = 'GET_REPORTS_ALLJOBTYPE_SUCCESS';
export const GET_REPORTS_ALLJOBTYPE_REQUEST = 'GET_REPORTS_ALLJOBTYPE_REQUEST';
export const GET_REPORTS_CHAT_SUCCESS = 'GET_REPORTS_CHAT_SUCCESS';
export const GET_REPORTS_CHAT_REQUEST = 'GET_REPORTS_CHAT_REQUEST';
export const GET_TIMESHEET_SUCCESS = 'GET_TIMESHEET_SUCCESS';
export const GET_TIMESHEET_REQUEST = 'GET_TIMESHEET_REQUEST';
export const GET_APPROVE_LIST_REQUEST = 'GET_APPROVE_LIST_REQUEST';
export const GET_CONTRACTOR_REF_NO_LIST_REQUEST =
  'GET_CONTRACTOR_REF_NO_LIST_REQUEST';
export const GET_CONTRACTOR_SECTION_SUCCESS = 'GET_CONTRACTOR_SECTION_SUCCESS';
export const GET_REPORT_CHAT_SUCCESS = 'GET_REPORT_CHAT_SUCCESS';
export const EDIT_REPORTCHAT_SUCCESSFULLY = 'EDIT_REPORTCHAT_SUCCESSFULLY';
export const GET_LATEST_ACTIVITY_SUCCESS = 'GET_LATEST_ACTIVITY_SUCCESS';
export const GET_SEARCH_SUCCESS = 'GET_SEARCH_SUCCESS';
export const GET_ALL_TIMESHEET_SUCCESS = 'GET_ALL_TIMESHEET_SUCCESS';
export const GET_ALL_JOBSECTION_SUCCESS = 'GET_ALL_JOBSECTION_SUCCESS';
export const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS';
export const GET_TIMESHEET_SEC_SUCCESS = 'GET_TIMESHEET_SEC_SUCCESS';
export const GET_CONTRACTOR_REF_NO_LIST_REQUEST2 =
  'GET_CONTRACTOR_REF_NO_LIST_REQUEST2';
export const GET_CONTRACTOR_REF_NO_LIST_SUCCESS =
  'GET_CONTRACTOR_REF_NO_LIST_SUCCESS';
export const ADD_TIMESHEET_SUCCESS = 'ADD_TIMESHEET_SUCCESS';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const TIMESHEET_DETAIL_ACCORDING_TO_DATE =
  'TIMESHEET_DETAIL_ACCORDING_TO_DATE';
export const GET_CALENDER_SUCCESS = "GET_CALENDER_SUCCESS";

export const GET_ADD_TIME_SUCCESS = "GET_ADD_TIME_SUCCESS";

export const GET_REPORTS_CHAT_SUCCESS_PDF = "GET_REPORTS_CHAT_SUCCESS_PDF";
export const GET_REPORTS_CHAT_REQUEST_PDF = "GET_REPORTS_CHAT_REQUEST_PDF";

export const GET_ALL_SECTION_FOR_PDF = "GET_ALL_SECTION_FOR_PDF";

export const GET_ALL_SECTION_SUCCESS_PDF = "GET_ALL_SECTION_SUCCESS_PDF";

export const JOB_MAINTAIN_STATE = 'JOB_MAINTAIN_STATE'

export const UPDATE_RISK_START = "UPDATE_RISK_START";
export const UPDATE_RISK_SUCCESS = "UPDATE_RISK_SUCCESS";

export const GLOBAL_FILTER = "GLOBAL_FILTER";