import React from 'react';
import logo from '../assets/img/logo.png';

export default function Term_condition() {
  return (
    <>
      <div>
        <div class='header-blue'>
          <div class='container'>
            <div class='logo-privacy'>
              <img src={logo} alt='' />
            </div>
          </div>
        </div>

        <div class='main-content-privacy'>
          <div class='container'>
            <div class='row'>
              <div class='col-md-12 mb-5'>
                <div class='page-hading-privacy'>
                  FLAME HOLD TERMS AND CONDITIONS
                </div>
              </div>
            </div>

            <div class='row'>
              <div class='col-md-12 mb-4'>
                <div class='privacy-heading'>Introduction</div>
                <div class='privacy-text'>
                  This is Flame Hold, an app brought to you by Flame Hold
                  Limited (" we", " us", " our"). We are registered in England
                  and Wales under registered company number 06518022 and our
                  address is Suite 9 Normanby Gateway, Lysaghts Way, Scunthorpe,
                  North Lincolnshire, DN15 9YG.
                </div>
              </div>

              <div class='col-md-12 mb-4'>
                <div class='privacy-text pl-3 mb-3'>
                  1. These Terms should be read in conjunction with our Platform
                  Terms of Use, Privacy Policy and Cookie Policy (which together
                  make up the " Agreement" between us). By accessing and using
                  the Flame Hold App, you accept and agree to the terms of this
                  Agreement. If you do not understand or agree with the
                  Agreement, you should not use the Flame Hold App.
                </div>
                <div class='privacy-text pl-3 mb-3'>
                  2. From time to time, we may refine or change aspects of the
                  Agreement, and any revised terms or policies will be made
                  available to you https://www.flamehold.co.uk and a
                  notification of such changes displayed in a prominent place on
                  the Flame Hold app and/or website. Please take a moment to
                  read the changes. If you continue to use Flame Hold after we
                  make the changes, you will be deemed to agree to the changes.
                  If you do not agree to the changes, you should not use Flame
                  hold.
                </div>
                <div class='privacy-text pl-3 mb-3'>
                  3. Certain words and phrases in these Client Terms have
                  specific definitions when they start with capital letters.
                  Where a defined term is used, it either has the meaning set
                  out in clause 17 below (Definitions) or the meaning given to
                  it in the relevant clause of these Client Terms.
                </div>
              </div>

              <div class='col-md-12 mb-4'>
                <div class='privacy-heading'>Duration of Agreement</div>
                <div class='privacy-text pl-3 mb-3'>
                  1. This Agreement commences on the Effective Date and
                  continues until the earlier of:
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  a. either of us serving notice to terminate the Agreement in
                  accordance with its terms; or
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  b. you no longer working in one of the Approved Professions.
                </div>
              </div>

              <div class='col-md-12 mb-4'>
                <div class='privacy-heading'>Your obligations</div>
                <div class='privacy-text pl-3 mb-3'>
                  1. As a user of Flame Hold and as our 'principal' in the
                  commercial agency relationship, you agree to:
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  a. act in good faith; or
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  b. not permit any other person to access your Flame Hold
                  account and
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  c. comply with all applicable laws.
                </div>

                <div class='privacy-text pl-3 mb-3'>
                  2. You confirm that you:
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  a. Are a contractor employed by Flame Hold Limited
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  b. are legally capable of entering into binding contracts;
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  c. will use Flame hold only for the purpose of the app.
                </div>
              </div>

              <div class='col-md-12 mb-4'>
                <div class='privacy-heading'>Privacy</div>
                <div class='privacy-text pl-3 mb-3'>
                  1. The privacy of your personal information is really
                  important to us.
                </div>
                <div class='privacy-text pl-3 mb-3'>
                  2. Any personal information that you provide to us or that we
                  collect about you will be processed in accordance with our
                  Privacy Policy https://flamehold.co.uk. This explains what
                  personal information we collect from or about you, why and how
                  we collect, store, use and share it (including with your
                  employer / work operator), your rights in relation to it, and
                  how to contact us and supervisory authorities if you have a
                  query or complaint about our use of it.
                </div>

                <div class='privacy-text pl-3 mb-3'>
                  3. You agree not to do (or omit to do) any act or thing which
                  (a) puts Flame Hold and/or you in breach of applicable data
                  protection law; and/or (b) is contrary to the Customer's right
                  to privacy under such laws.
                </div>
              </div>

              <div class='col-md-12 mb-4'>
                <div class='privacy-heading'>Confidentiality</div>
                <div class='privacy-text pl-3 mb-3'>
                  1. We each agree at all times (i) to keep confidential and not
                  disclose to any person any Confidential Information and (ii)
                  only to use such Confidential Information for the purposes of
                  performing our obligations or exercising our rights under this
                  Agreement.
                </div>
                <div class='privacy-text pl-3 mb-3'>
                  2. We may disclose Confidential Information to our personnel,
                  sub-contractors, representatives and/ or advisers (" Permitted
                  Disclosees") in connection with us carrying out our
                  obligations and/or exercising our rights under this Agreement.
                  We shall ensure that Permitted Disclosees are subject to
                  similar obligations of confidentiality.
                </div>
              </div>

              <div class='col-md-12 mb-4'>
                <div class='privacy-heading'>General</div>
                <div class='privacy-text pl-3 mb-3'>
                  1. If any court or competent authority finds that any
                  provision of the Agreement (or part of any provision) is
                  invalid, illegal or unenforceable, that provision or
                  part-provision will, to the extent required, be deemed to be
                  deleted, and the validity and enforceability of the other
                  provisions of the Agreement will not be affected.
                </div>

                <div class='privacy-text pl-3 mb-3'>
                  2. Nothing in the Agreement will establish any partnership or
                  joint venture between us, or (except as expressly stated) mean
                  that any party to this Agreement becomes the agent of another
                  third party, nor does the Agreement (except as expressly
                  stated) authorise any party to enter into any commitments for
                  or on behalf of any other third party.
                </div>

                <div class='privacy-text pl-3 mb-3'>
                  3. This Agreement does not give rise to any rights under the
                  Contracts (Rights of Third Parties) Act 1999 to enforce any
                  term of this Agreement.
                </div>

                <div class='privacy-text pl-3 mb-3'>
                  4. You may not transfer any of your rights or obligations
                  under these Client Terms to any other person. We may transfer
                  our rights under these terms and conditions where we
                  reasonably believe your rights will not be affected.
                </div>
                <div class='privacy-text pl-3 mb-3'>
                  5. Any notice (other than in legal proceedings) to be
                  delivered under the Agreement must be in writing and delivered
                  pre-paid first class post to the registered address or place
                  of business of the notified party, or sent by email to the
                  other party's main email address as notified to the sending
                  Party.
                </div>
                <div class='privacy-text pl-3 mb-3'>6. Notices:</div>
                <div class='privacy-text pl-5 mb-3'>
                  a. sent by post will be deemed to have been received, where
                  posted from and to addresses in the United Kingdom, on the
                  second Working Day and where posted from or to addresses
                  outside the United Kingdom, on the tenth Working Day following
                  the date of posting;
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  b. delivered by hand will be deemed to have been received at
                  the time the notice is left at the proper address;
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  c. sent by email will be deemed to have been received on the
                  next Working Day after sending.
                </div>

                <div class='privacy-text pl-3 mb-3'>
                  7. Unless the context requires a different interpretation:
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  a. all singular words include plural ones and vice versa;
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  b. all references to clauses, sub-clauses, schedules or
                  appendices are to the ones in these Client Term;
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  c. all references to a person includes firms, companies,
                  government entities, trusts and partnerships;
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  d. the term 'including' does not exclude anything not listed;
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  e. all references to statutory provisions include any changes
                  to those provisions;
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  f. no headings or sub-headings form part of these Client Terms
                  or the Agreement.
                </div>
                <div class='privacy-text pl-5 mb-3'>
                  g. This Agreement constitutes the entire agreement between us
                  and supersedes and extinguishes all previous drafts,
                  agreements, arrangements and understandings between us,
                  whether written or oral, relating to its subject matter.
                </div>
              </div>

              <div class='col-md-12 mb-4'>
                <div class='privacy-heading'>
                  Governing law and jurisdiction
                </div>
                <div class='privacy-text pl-3 mb-3'>
                  1. This Agreement shall be governed by and interpreted
                  according to the law of England and Wales and all disputes
                  arising under the Agreement (including non-contractual
                  disputes or claims) shall be subject to the exclusive
                  jurisdiction of the English courts.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
