import React, { useEffect } from 'react';
import { getReportsJobtype } from '../../Redux/Action/SuperAdminAction';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Report_type = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const uniqueJobTypes = useSelector((state) => state?.adminList?.GetJobTypes?.data);
  const client = useSelector((state) => state?.adminList?.GetJobTypes?.clientname?.clientId?.clientName);
  
  useEffect(() => {
    localStorage.setItem('report_type', props.match.params.id);
    dispatch(getReportsJobtype(props.match.params.id));
  }, [props.match.params.id]);

  return (
    <>
      {/* <!-- Page Wrapper --> */}
      <div class='page-wrapper'>
        {/* <!-- Page Content --> */}
        <div class='content container-fluid'>
          {/* <!-- Page Header --> */}

          <div class='page-header'>
            <div class='row align-items-center d-flex'>
              <div class='col-md-6'>
                <h3 class='page-title'>{client}</h3>
              </div>
              <div class='col-md-6'>
                <div class='form-group mb-0'>
                  <select class='form-control' id=''>
                    <option>0001</option>
                    <option>0002</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-12'>
              <div
                onClick={() =>
                  history.push(
                    `/report_number/${localStorage.getItem('report_number')}`
                  )
                }
              >
                <button class='btn add-btn pull-left'>Back</button>
              </div>
            </div>
          </div>

          {/* <!-- /Page Header --> */}
          <div class='row'>
            <div class='col-md-12 d-flex'>
              <div class=' card-table flex-fill'>
                <div class='card-body'>
                  <div class='table-responsive'>
                    <table class=' table table-stripped mb-0'>
                      <thead class='custom_color'>
                        <tr>
                          <th colspan='2'>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uniqueJobTypes?.map((item, i) => {
                          return (
                            <>
                              <tr class='text-middle-custom'>
                                <td
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    'Electrical/Alarm' == item?._id?.jobType
                                      ? history.push(
                                        `/report_type_select_section/${item?._id?.job_id}/Electrical-Alarm`
                                      )
                                      : history.push(
                                        `/report_type_select_section/${item?._id?.job_id}/${item?._id?.jobType}`
                                      )
                                  }
                                >
                                  <h2>
                                    <a href=''>{item?._id?.jobType}</a>
                                  </h2>
                                </td>
                                <td
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    'Electrical/Alarm' == item?._id?.jobType
                                      ? history.push(
                                        `/report_type_select_section/${item?._id?.job_id}/Electrical-Alarm`
                                      )
                                      : history.push(
                                        `/report_type_select_section/${item?._id?.job_id}/${item?._id?.jobType}`
                                      )
                                  }
                                  class='arrow_end'
                                >
                                  <i
                                    class='fa fa-angle-right'
                                    aria-hidden='true'
                                  ></i>
                                </td>
                              </tr>
                            </>
                          );
                        })}

                        {/* <tr class="text-middle-custom">
                                                    <td><h2><a href="">Survey</a></h2></td>
                                                    <td class="arrow_end"><NavLink to="/report_type_survey"><i class="fa fa-angle-right" aria-hidden="true"></i></NavLink></td>
                                                </tr>
                                                <tr class="text-middle-custom">
                                                    <td><h2><a href="">Completed Forms</a></h2></td>
                                                    <td class="arrow_end" style={{cursor:"pointer"}}><NavLink to="/report_completed_forms"><i class="fa fa-angle-right" aria-hidden="true"></i></NavLink></td>
                                                </tr>
 */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Page Content --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </>
  );
};

export default Report_type;
