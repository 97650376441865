import React, { useEffect } from 'react';
import { getReportById } from '../../Redux/Action/SuperAdminAction';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const ReportNumber = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const uniqueReports = useSelector((state) => state?.adminList?.GetAllReportsById?.data);

  useEffect(() => {
    localStorage.setItem('report_number', props.match.params.id);
    dispatch(getReportById(props.match.params.id));
  }, [props.match.params.id]);

  return (
    <>
      {/* <!-- Page Wrapper --> */}
      <div class='page-wrapper'>
        {/* <!-- Page Content --> */}
        <div class='content container-fluid'>
          {/* <!-- Page Header --> */}

          <div class='page-header'>
            <div class='row align-items-center d-flex'>
              <div class='col-md-6'>
                <h3 class='page-title'>Report</h3>
              </div>
              <div class='col-md-6'>
                <div class='input-group'>
                  <div class='input-group-prepend'>
                    <span class='input-group-text'>
                      <i class='fa fa-search' aria-hidden='true'></i>
                    </span>
                  </div>
                  <input
                    type='text'
                    class='form-control border-l-none'
                    placeholder='Search'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-12'>
              <div onClick={() => history.push(`/reports`)}>
                <button class='btn add-btn pull-left'>Back</button>
              </div>
            </div>
          </div>

          {/* <!-- /Page Header --> */}
          <div class='row'>
            <div class='col-md-12 d-flex'>
              <div class=' card-table flex-fill'>
                <div class='card-body'>
                  <div class='table-responsive'>
                    <table class=' table table-stripped mb-0'>
                      <thead class='custom_color'>
                        <tr>
                          <th>Job Number</th>
                          <th>Client</th>
                          <th colspan='3'>Last Edited</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uniqueReports &&
                          uniqueReports?.map((item, i) => {
                            return (
                              <>
                                <tr class='text-middle-custom' key={item?._id}>
                                  <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      history.push(
                                        `/report_type/${item?.job_id?._id}`
                                      )
                                    }
                                  >
                                    <h2>
                                      <a href=''>
                                        {item?.job_id?.JobRefNo.toString().length == 1
                                          ? '000' + item?.job_id?.JobRefNo
                                          : '' || item?.job_id?.JobRefNo.toString().length == 2
                                            ? '00' + item?.job_id?.JobRefNo
                                            : '' || item?.job_id?.JobRefNo.toString().length == 3
                                              ? '0' + item?.job_id?.JobRefNo
                                              : '' || item?.job_id?.JobRefNo.toString().length == 4
                                                ? item?.job_id?.JobRefNo
                                                : ''}
                                      </a>
                                    </h2>
                                  </td>
                                  <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      history.push(
                                        `/report_type/${item?.job_id?._id}`
                                      )
                                    }
                                  >
                                    {item?.job_id?.clientId?.clientName}
                                  </td>
                                  <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      history.push(
                                        `/report_type/${item?.job_id?._id}`
                                      )
                                    }
                                  >
                                    {moment(item?.updatedAt).format(
                                      'D MMM YYYY'
                                    )}
                                  </td>

                                  <td
                                    class='arrow_end'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      history.push(
                                        `/report_type/${item?.job_id?._id}`
                                      )
                                    }
                                  >
                                    <i
                                      class='fa fa-angle-right'
                                      aria-hidden='true'
                                    ></i>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Page Content --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </>
  );
};

export default ReportNumber;
