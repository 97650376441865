import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/assets/css/style.css';
import './components/assets/css/bootstrap.min.css';
import './components/assets/css/font-awesome.min.css';
import './components/assets/css/line-awesome.min.css';
import './components/assets/plugins/morris/morris.css';
import { Provider } from 'react-redux';
import store from './Redux/store';
import { SocketContext, socket } from './Service/content/socket';
import { registerServiceWorker } from './serviceWorker';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <SocketContext.Provider value={socket}>
        <App />
      </SocketContext.Provider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
