import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  getdetailofjob,
  getClinetList,
  getContractorList,
  editViewJob,
  deleteJobSection,
  updateRiskAss,
} from '../../Redux/Action/SuperAdminAction';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { getJobRefDigits, msToTime } from '../../utils/commonFiles';
import User from '../assets/img/svg-img/user.svg';
import pause from '../assets/img/svg-img/pause.svg';
import accept from '../assets/img/svg-img/accepted.svg';
import jobdone from '../assets/img/svg-img/job_done.svg';
import jobstarted from '../assets/img/svg-img/job_started.svg';
import { toast } from '../ToastContainer/Toast';
const moment2 = require('moment-timezone');
const PDFJS = window.pdfjsLib;

function EditJob() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  var retrievedData = localStorage.getItem('dataarr');
  var gobDetails = JSON.parse(retrievedData);
  const [selectcontractor, setselectcontractor] = useState(false);
  const [selectcontractorIndex, setselectcontractorIndex] = useState();
  const handleClose = () => setselectcontractor(false);
  const [typeSuper, setType] = useState('');
  const [addressApi, setAddressApi] = useState('');
  const [pdfImg, setPdfImg] = useState();
  const [floor1, setFloor1] = useState();
  const [floor2, setFloor2] = useState();
  const [pdf, setPdf] = React.useState("");
  const [typeImg, setTypeImg] = useState();
  const [inputList, setInputList] = useState('');
  const [forvar, setforvar] = useState('');
  const [sectionOpen, setsectionOpen] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [secLength, setSecLength] = useState([]);
  const [addsection, setaddSectionforcreate] = useState(false);
  const [images, setImages] = React.useState([]);

  const clientName = useSelector((state) => state.adminList.clientList);
  const contractor_history = useSelector((state) => state.adminList.contractorHistory);
  const getAllSection = useSelector((state) => state?.adminList?.GetAllJobSection?.data);
  const contractorName = useSelector((state) => state?.adminList?.contractorList);

  const handleShow = (x, i) => {
    setselectcontractorIndex(i);
    setselectcontractor(true);
    setType('');
  };

  const handleShow2 = (x, i) => {
    setselectcontractorIndex(i);
    setselectcontractor(true);
    setType('super');
  };

  const maintainStateOnPageRefresh = () => {
    setforvar(getAllSection && getAllSection[0]?.job_id);
    setInputList(getAllSection && getAllSection[0]?.job_id == undefined ? [] : getAllSection);
  };

  window.addEventListener('load', (event) => {
    maintainStateOnPageRefresh();
  });

  const handleInputChange = (e, i) => {
    var ddd = inputList?.map((user, index) =>
      index == i
        ? Object.assign(user, { [e.target.name]: e.target.value, "updatedCreateBy": localStorage.getItem("userId") })
        : user
    );
    setInputList(ddd);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    dispatch(deleteJobSection(list[index]._id));
    list.splice(index, 1);
    setInputList(list);
  };

  const handleChangeForm = (e, type) => {
    if (type == 'custom') {
      setforvar({
        ...forvar,
        ["addressLine1"]: addressApi?.addresses[e.target.value]?.line_1,
        ["addressLine2"]: addressApi?.addresses[e.target.value]?.line_2,
        ["town_or_city"]: addressApi?.addresses[e.target.value]?.town_or_city,
        ["latitude"]: addressApi?.latitude,
        ["longitude"]: addressApi?.longitude
      });
    } else if (type == 'file') {
      setforvar({
        ...forvar,
        ["pdf_file"]: e,
      });

    } else if (type == 'floor1') {
      setforvar({
        ...forvar,
        ["floor_img1"]: e,
      });
      setFloor1(e?.name);
    } else if (type == 'floor2') {
      setforvar({
        ...forvar,
        ["floor_img2"]: e,
      });
      setFloor2(e?.name);
    } else {
      const { name, value } = e.target;
      setforvar({
        ...forvar,
        [name]: e.target.value,
      });
    }
  };

  const postCodeClick = async (e) => {
    try {
      if ("initial_loader" == e) {

      } else {
        e.preventDefault()
      }
      const url = `https://api.getaddress.io/find/${forvar?.pinCode}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      const response = await fetch(url);
      const json = await response.json();
      setAddressApi(json);
    } catch (error) {
    }
  }

  const _handleUpload = (e, type) => {
    if (type == "file") {
      setPdfImg(e.target.files[0]?.name);
      let fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0])
      fileReader.onload = () => {
        let baseURL = fileReader.result;
        handleChangeForm(baseURL, type)
      };
    } else {
      handleChangeForm(e.target.files[0], type)
    }
  }

  async function renderPage() {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport
      };
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    handleChangeForm(imagesList[0], typeImg)
  }

  async function showPdf(event, type) {
    try {
      const file = event.target.files[0];
      if (type == "floor1") {
        setFloor1(event.target.files[0]?.name);
      } else if (type == "floor2") {
        setFloor2(event.target.files[0]?.name);
      }

      const uri = URL.createObjectURL(file);
      var _PDF_DOC = await PDFJS.getDocument({ url: uri });
      setPdf(_PDF_DOC);
      setTypeImg(type)

    } catch (error) {
      alert(error.message);
    }
  }

  function createSection(allEditSection) {
    return (
      <>
        {allEditSection?.length > 0
          ? allEditSection?.map((x, i) => (
            <div key={i} className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='com_sect'>
                      <div className='section-title-c'>
                        <strong>Section {i + 1}</strong>
                      </div>
                      <div className='mt-3 form-group'>
                        <label for=''>Complete By</label>
                        <input
                          value={x.completedBy}
                          name='completedBy'
                          onChange={(e) => handleInputChange(e, i)}
                          className='form-control'
                          placeholderText='Select Date'
                          type='date'
                        />
                      </div>
                      <div className=' form-group'>
                        <label for=''>Job Type</label>
                        <select
                          onChange={(e) => handleInputChange(e, i)}
                          name='jobType'
                          className='form-control'
                          id=''
                          value={x?.jobType}
                        >
                          <option value='' className='d-none'>
                            Select Job Type
                          </option>
                          <option value='Joinery'>Joinery</option>
                          <option value='Fire Stopping'>Fire Stopping</option>
                          <option value='Electrical/Alarm'>
                            Electrical/Alarm
                          </option>
                          <option value='Decorations'>Decorations</option>
                          <option value='Surveying'>Surveying</option>
                        </select>
                      </div>
                      {/* ORCHARD NUMBER OPTIONAL */}
                      <div className='mt-3 form-group'>
                        <label for=''>Orchard number(Optional)</label>
                        <input
                          value={x.chard_number}
                          name='chard_number'
                          onChange={(e) => handleInputChange(e, i)}
                          className='form-control'
                          type='text'
                        />
                      </div>
                      {/* END OF ORCHARD NUMBER OPTIONAL */}

                      <div className=' form-group'>
                        <label for=''>Assigned to</label>
                        <div
                          className='static-input form-control'
                          onClick={() => handleShow(x, i)}
                        >
                          {inputList[i]['comtractorName']
                            ? `${inputList[i]['comtractorName'].join(" ,")}`
                            : x?.contractorId?.firstName == undefined
                              ? 'Select Contractor'
                              : x?.contractorId?.firstName +
                              ' ' +
                              x?.contractorId?.lastName}
                        </div>
                      </div>

                      <div className=' form-group'>
                        <label for=''>Assigned Supervisor (optional)</label>
                        <div
                          className='static-input form-control'
                          onClick={() => handleShow2(x, i)}
                        >
                          {inputList[i]['comtractorName2']
                            ? inputList[i]['comtractorName2']
                            : x?.supervisor?.firstName == undefined
                              ? 'Select supervisor'
                              : x?.supervisor?.firstName +
                              ' ' +
                              x?.supervisor?.lastName}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label for=''>Details</label>
                        <textarea
                          value={x.details}
                          onChange={(e) => handleInputChange(e, i)}
                          name='details'
                          className='form-control'
                          id=''
                          cols='30'
                          rows='3'
                        ></textarea>
                      </div>

                      <div className=' form-group'>
                        <button
                          className='btn btn-danger'
                          onClick={() => handleRemoveClick(i)}
                        >
                          Remove Section
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
          : inputList?.length > 0 && inputList?.map((x, i) => (
            <div key={i} className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='com_sect'>
                      <div className='section-title-c'>
                        <strong>
                          Section{' '}
                          {secLength == false || secLength == 0
                            ? i
                            : secLength + 1}
                        </strong>
                      </div>
                      <div className='mt-3 form-group'>
                        <label for=''>Complete By</label>
                        <input
                          value={x.completedBy}
                          name='completedBy'
                          onChange={(e) => handleInputChange(e, i)}
                          className='form-control'
                          type='date'
                        />
                      </div>
                      <div className=' form-group'>
                        <label for=''>Job Type</label>
                        <select
                          onChange={(e) => handleInputChange(e, i)}
                          name='jobType'
                          className='form-control'
                          id=''
                        >
                          <option value='' className='d-none'>
                            Select Job Type
                          </option>
                          <option value='Joinery'>Joinery</option>
                          <option value='Fire Stopping'>Fire Stopping</option>
                          <option value='Electrical/Alarm'>
                            Electrical/Alarm
                          </option>
                          <option value='Decorations'>Decorations</option>
                          <option value='Surveying'>Surveying</option>
                        </select>
                      </div>

                      {/* ORCHARD NUMBER OPTIONAL */}
                      <div className='mt-3 form-group'>
                        <label for=''>Orchard number(Optional)</label>
                        <input
                          value={x.chard_number}
                          name='chard_number'
                          onChange={(e) => handleInputChange(e, i)}
                          className='form-control'
                          type='text'
                        />
                      </div>
                      {/* END OF ORCHARD NUMBER OPTIONAL */}

                      <div className=' form-group'>
                        <label for=''>Assigned to</label>
                        <div
                          className='static-input form-control'
                          onClick={() => handleShow(x, i)}
                        >
                          {inputList[i]['comtractorName']
                            ? inputList[i]['comtractorName']
                            : 'Select Contractor'}
                        </div>
                      </div>
                      <div className=' form-group'>
                        <label for=''>Assigned to SuperVisor</label>
                        <div
                          className='static-input form-control'
                          onClick={() => handleShow(x, i)}
                        >
                          {inputList[i]['comtractorName']
                            ? inputList[i]['comtractorName']
                            : 'Select Contractor'}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label for=''>Details</label>
                        <textarea
                          value={x.details}
                          onChange={(e) => handleInputChange(e, i)}
                          name='details'
                          className='form-control'
                          id=''
                          cols='30'
                          rows='3'
                        ></textarea>
                      </div>

                      <div className=' form-group'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }

  const addClick = (e) => {
    e.preventDefault();
    setInputList([
      ...inputList,
      {
        job_id: id || '',
        completedBy: '',
        contractorId: [],
        comtractorName: [],
        jobType: '',
        supervisor: '',
        supervisor_name: '',
        details: '',
      },
    ]);
    setsectionOpen(e.target.id);
    setaddSectionforcreate(true);
  };

  const handleContarctor = (e, contractor, selectcontractorIndex) => {
    const list = [...inputList];
    if (typeSuper == 'super') {
      list[selectcontractorIndex]['supervisor'] = contractor?._id;
      list[selectcontractorIndex]['comtractorName2'] =
        contractor?.firstName + ' ' + contractor?.lastName;
    } else {
      if (!list[selectcontractorIndex]['contractorId'].includes(contractor?._id)) {
        list[selectcontractorIndex]['contractorId'].push(contractor?._id)
        list[selectcontractorIndex]['comtractorName'].push(contractor?.firstName + ' ' + contractor?.lastName)

      } else {
        list[selectcontractorIndex]['contractorId'].splice(list[selectcontractorIndex]['contractorId'].indexOf(contractor?._id), 1);
        list[selectcontractorIndex]['comtractorName'].splice(list[selectcontractorIndex]['comtractorName'].indexOf(contractor?.firstName + ' ' + contractor?.lastName), 1);
      }
    }
    setInputList(list);
  };

  const submitDetail = (e, type) => {
    e.preventDefault();
    let data = {
      ...forvar,
    };
    data.clientId = data?.clientId?._id
    data.createBy = data?.createBy?._id
    data['job_id'] = data?._id
    data.job_sections = inputList;
   
    if (forvar && inputList) {

      for (let i = 0; i < data.job_sections.length; i++) {
        if (data.job_sections[i].completedBy == '') {
          return toast.info('Please select Complete By first.');
        } else if (data.job_sections[i].jobType == '') {
          return toast.info('Please select Job Type first.');
        }
      }

      let formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key == "job_sections") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      }
      if (forvar?.floor_img1?.type?.includes("image")
        || forvar?.floor_img1?.includes("data:") ||
        forvar?.floor_img2?.type?.includes("image") ||
        forvar?.floor_img2?.includes("data:")) {
        if (data?.job_sections?.length > 0) {
          // dispatch(updateRiskAss({ job_id: forvar?._id, type: "floor_img1" }));
          // dispatch(updateRiskAss({ job_id: forvar?._id, type: "floor_img2" }))
          localStorage.setItem("job_id", id)
          localStorage.setItem("editPreview", "editPreview")
          if (typeof forvar?.floor_img1 == 'string') {
            if (forvar?.floor_img1?.includes("public/uploads")) {
              data.floor_img1 = undefined
            }
          }
          if (typeof forvar?.floor_img2 == 'string') {
            if (forvar?.floor_img2?.includes("public/uploads")) {
              data.floor_img2 = undefined
            }
          }
          dispatch(
            editViewJob(data, history, id, "maintain_state")
          );
          history.push("/preview-image")
        }
      } else {
        localStorage.setItem("editPreview", "")
        formData.delete("floor_img1");
        formData.delete("floor_img2");
        dispatch(
          editViewJob(formData, history, id)
        );
      }
    }
    
  };

  var latestActivityArr = [];
  {
    gobDetails?.length &&
      gobDetails?.map((jobstatuscheck) => {
        return (
          <>
            {jobstatuscheck?.timesheet?.length > 0 &&
              jobstatuscheck?.timesheet?.map((item2, index) => {
                const differ = item2?.created_at
                jobstatuscheck.sortTimesheet = differ;
                return (
                  latestActivityArr.push({ jobstatuscheck, timeData: item2, sortTimesheet: item2?.created_at })
                );
              })
            }

          </>

        );
      })

    gobDetails?.length &&
      gobDetails?.map((jobstatuscheck) => {
        //  jobstatuscheck.sortTimesheet = Date.parse(jobstatuscheck?.createdAt);
        return (
          latestActivityArr.push({ jobstatuscheck, sortTimesheet: Date.parse(jobstatuscheck?.createdAt) })
        );
      })
  }
  latestActivityArr.sort((a, b) => new Date(b?.sortTimesheet) - new Date(a?.sortTimesheet));

  useEffect(() => {
    dispatch(getClinetList());
    dispatch(getContractorList());
  }, []);

  useEffect(() => {
    pdf && renderPage(typeImg);
    // eslint-disable-next-line
  }, [pdf, typeImg]);

  useEffect(() => {
    dispatch(getdetailofjob(id));
  }, [id]);

  useEffect(() => {
    postCodeClick("initial_loader")
  }, [forvar?.pinCode])

  useEffect(() => {
    const mainSection = [];
    getAllSection?.map((item, i) => {
      const tempArr = [], tempId = [];
      item?.contractorId?.map((data, index) => {
        tempArr?.push(data?.firstName + " " + data?.lastName)
        tempId?.push(data?._id)
      })
      mainSection?.push({ ...item, contractorId: tempId, comtractorName: tempArr })
    })
    setInputList(mainSection && mainSection[0]?.job_id == undefined ? [] : mainSection);
    setforvar(mainSection && mainSection[0]?.job_id == undefined ? mainSection && mainSection[0] : mainSection && mainSection[0]?.job_id);
  }, [getAllSection]);

  return (
    <div className='page-wrapper'>
      <form className='content container-fluid'>
        <div className=''>
          {(Array.isArray(gobDetails) && gobDetails.length) &&
            <>
              <h3 className='page-title second-page-title'>
                {gobDetails[0]?.job_id?.JobRefNo.toString().length <= 4 ? getJobRefDigits[gobDetails[0]?.job_id?.JobRefNo.toString().length] + gobDetails[0]?.job_id?.JobRefNo : ''}
              </h3>
            </>
          }
          <ul className='cusom_ul'>
            {(Array.isArray(gobDetails) && gobDetails.length) && <h3 className='m-0 font18'>
              {gobDetails[0]?.job_id?.clientId?.clientName}
            </h3>}
            <p className='m-0'>
              <strong>Target Completion date(s);</strong>
            </p>
            {gobDetails?.length &&
              gobDetails?.map((contractor) => {
                return (
                  <span>
                    {contractor?.jobType} work carried by{' '}
                    <strong>
                      {
                        contractor?.contractorId?.map((item, i) => {
                          return (
                            item?.firstName == undefined
                              ? 'No contractor assigned'
                              : item?.firstName +
                              ' ' +
                              item?.lastName + ', by'
                          )
                        })
                      }
                    </strong>{' '}
                    {msToTime(contractor?.completedBy) > 24
                      ? ' ' +
                      moment(contractor?.completedBy).format('D MMM YYYY')
                      : moment(contractor?.completedBy).fromNow()}
                  </span>
                );
              })}
          </ul>
        </div>
        <div className='page-header second-page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title'>Client Details</h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>Information on the client and job site.</span>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>

          <div className='col-md-6'>
            <div className='form-group'>
              <label className='col-form-label'>
                Client <span className='text-danger'>*</span>
              </label>
              <select
                name='clientId'
                className='form-control'
                onChange={(e) => handleChangeForm(e)}
                value={forvar?.clientId?._id}
              >
                <option value='' className='d-none'>
                  Select Client
                </option>
                {clientName?.data?.map((clientList) => (
                  <>
                    <option value={clientList?._id}>
                      {clientList?.clientName}
                    </option>
                  </>
                ))}
              </select>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='card m-0'>
              <div class='card-header d-flex justify-content-between justify-center align-items-center'>
                <h5 class='m-0'>Latest Activity</h5>
              </div>
              <div className='overflow_scroll'>
                {
                  latestActivityArr?.map((item2, index) => {
                    return (
                      <>
                        {
                          (item2?.timeData?.status == 8 || item2?.jobstatuscheck?.jobstatus == 2) ? "" : item2?.timeData == undefined ?
                            <div className='activity'>
                              <div className='activity-box'>
                                <ul className='activity-list'>
                                  {/* {jobstatuscheck.jobstatus == 1 && ( */}
                                  <li className='pt-2  p-0'>
                                    <div className='left-line-c'></div>
                                    <div className='activity-user'>
                                      {item2?.jobstatuscheck?.jobstatus == 1 ? (
                                        <a
                                          type='submit'
                                          title='Lesley Grauer'
                                          data-toggle='tooltip'
                                          className='avatar lightly-blue-bg'
                                        >
                                          <img src={User} alt='' />
                                        </a>
                                      ) : item2?.jobstatuscheck?.jobstatus == 2 ? (
                                        <a
                                          type='submit'
                                          title='Lesley Grauer'
                                          data-toggle='tooltip'
                                          className='avatar lightly-blue-bg'
                                        >
                                          <img src={User} alt='' />
                                        </a>
                                      ) : item2?.jobstatuscheck?.jobstatus == 3 ? (
                                        <a
                                          type='submit'
                                          title='Lesley Grauer'
                                          data-toggle='tooltip'
                                          className='avatar lightly-blue-bg'
                                        >
                                          <img src={User} alt='' />
                                        </a>
                                      ) : item2?.jobstatuscheck?.jobstatus == 4 ? (
                                        <a
                                          type='submit'
                                          title='Lesley Grauer'
                                          data-toggle='tooltip'
                                          className='avatar lightly-blue-bg'
                                        >
                                          <img src={User} alt='' />
                                        </a>
                                      ) : (
                                        item2?.jobstatuscheck?.jobstatus == 5 && (
                                          <a
                                            type='submit'
                                            title='Lesley Grauer'
                                            data-toggle='tooltip'
                                            className='avatar lightly-blue-bg'
                                          >
                                            <img src={User} alt='' />
                                          </a>
                                        )
                                      )}
                                    </div>
                                    <div className='activity-content'>
                                      <div className='timeline-content d-flex justify-content-between latest-join'>
                                        <div className='createby'>
                                          <a type='submit' className='name' />{' '}
                                          <a
                                            className='timeline-text-c'
                                            type='submit'
                                          >
                                            {item2?.jobstatuscheck?.jobType}
                                            <br />
                                            {item2?.jobstatuscheck?.jobstatus == 1
                                              ? (
                                                item2?.jobstatuscheck?.updatedCreateBy != null ? `Job created by  ${item2?.jobstatuscheck?.updatedCreateBy?.firstName} ${item2?.jobstatuscheck?.updatedCreateBy?.lastName}` :
                                                  `Job created by  ${item2?.jobstatuscheck?.job_id?.createBy?.firstName} ${item2?.jobstatuscheck?.job_id?.createBy?.lastName}`)
                                              : item2?.jobstatuscheck?.jobstatus != 1 &&

                                              (item2?.jobstatuscheck?.updatedCreateBy != null ? `Job created by  ${item2?.jobstatuscheck?.updatedCreateBy?.firstName} ${item2?.jobstatuscheck?.updatedCreateBy?.lastName}`
                                                :
                                                `Job created by  ${item2?.jobstatuscheck?.job_id?.createBy?.firstName} ${item2?.jobstatuscheck?.job_id?.createBy?.lastName}`)}

                                            {/* {localStorage
                                                .getItem('roles')
                                                .toUpperCase()}{' '} */}
                                          </a>
                                          <span></span>
                                        </div>
                                        <div className='time'>
                                          <span>
                                            {/* { `${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[0].split("/")[1]}-
                                              ${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[0].split("/")[0]}-
                                              ${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[0].split("/")[2]} 
                                                 ${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[1].split(":")[0]}: 
                                                ${new Date(item2?.jobstatuscheck?.updatedAt).toLocaleString('en-US', { timeZone: 'Europe/London' }).split(",")[0].split("/")[1]}`}
                                              */}
                                            {/* {moment(
                                                item2?.jobstatuscheck?.updatedAt
                                              ).format('MMMM Do YYYY, h:mm   a')} */}
                                            {moment2(item2?.sortTimesheet).tz('Europe/London').format('MMMM Do YYYY, h:mm   a')}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  {/* )} */}
                                  {/* {jobstatuscheck.jobstatus == 2 && (
      
                                      <li className='pt-2  p-0'>
                                       
                                        <div className='activity-user'>
                                          <a
                                            type='submit'
                                            className='avatar dark-blue-bg-two'
                                            title='Jeffery Lalor'
                                            data-toggle='tooltip'
                                          >
                                            <img src={Pause} alt='' />
                                          </a>
                                        </div>
                                        <div className='activity-content'>
                                          <div className='timeline-content d-flex justify-content-between'>
                                            <div className='createby'>
                                              <a
                                                type='submit'
                                                className='name timeline-text-c'
                                              >
                                                Joinery Accepted{' '}
                                                {jobstatuscheck?.contractorId
                                                  ?.firstName +
                                                  ' ' +
                                                  jobstatuscheck?.contractorId
                                                    ?.lastName}
                                              </a>
                                            </div>
                                            <div className='time'>
                                              <span>
                                                {moment(
                                                  jobstatuscheck.contractor
                                                    ?.completedBy
                                                ).format('D MMM YYYY')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                    {jobstatuscheck.jobstatus == 3 && (
                                      <li className='pt-2  p-0'>
                                        <div className='activity-user'>
                                          <a
                                            type='submit'
                                            title='Catherine Manseau'
                                            data-toggle='tooltip'
                                            className='avatar light-green-bg'
                                          >
                                            <img src={File} alt='' />
                                          </a>
                                        </div>
                                        <div className='activity-content'>
                                          <div className='timeline-content d-flex justify-content-between'>
                                            <div className='createby'>
                                              <a
                                                type='submit'
                                                className='name timeline-text-c'
                                              >
                                                Joinery Started{' '}
                                                {jobstatuscheck?.contractorId
                                                  ?.firstName +
                                                  ' ' +
                                                  jobstatuscheck?.contractorId
                                                    ?.lastName}
                                              </a>
                                            </div>
                                            <div className='time'>
                                              <span>
                                                {moment(
                                                  jobstatuscheck.contractor
                                                    ?.completedBy
                                                ).format('D MMM YYYY')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )}
      
                                    {jobstatuscheck.jobstatus == 4 && (
                                      <li className='pt-2  p-0'>
                                        <div className='activity-user'>
                                          <a
                                            href='javascript:void(0)'
                                            title='Jeffery Lalor'
                                            data-toggle='tooltip'
                                            className='avatar light-yellow-bg'
                                          >
                                            <img src={Play} alt='' />
                                          </a>
                                        </div>
                                        <div className='activity-content'>
                                          <div className='timeline-content d-flex justify-content-between'>
                                            <div className='createby'>
                                              <a
                                                type='submit'
                                                className='name timeline-text-c'
                                              >
                                                Joinery Paused{' '}
                                                {jobstatuscheck?.contractorId
                                                  ?.firstName +
                                                  ' ' +
                                                  jobstatuscheck?.contractorId
                                                    ?.lastName}
                                              </a>
                                            </div>
                                            <div className='time'>
                                              <span>
                                                {moment(
                                                  jobstatuscheck.contractor
                                                    ?.completedBy
                                                ).format('D MMM YYYY')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                    {jobstatuscheck.jobstatus == 5 && (
                                      <li className='pt-2  p-0'>
                                        <div className='activity-user'>
                                          <a
                                            style={{ background: 'green' }}
                                            href='javascript:void(0)'
                                            title='Jeffery Lalor'
                                            data-toggle='tooltip'
                                            className='avatar'
                                          >
                                            <img src={User} alt='' />
                                          </a>
                                        </div>
                                        <div className='activity-content'>
                                          <div className='timeline-content d-flex justify-content-between'>
                                            <div className='createby'>
                                              <a
                                                type='submit'
                                                className='name timeline-text-c'
                                              >
                                                Joinery Completed{' '}
                                                {jobstatuscheck?.contractorId
                                                  ?.firstName +
                                                  ' ' +
                                                  jobstatuscheck?.contractorId
                                                    ?.lastName}
                                              </a>
                                            </div>
                                            <div className='time'>
                                              <span>
                                                {moment(
                                                  jobstatuscheck.contractor
                                                    ?.completedBy
                                                ).format('D MMM YYYY')}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )} */}
                                </ul>
                              </div>
                            </div>
                            :
                            <div className='activity'>
                              <div className='activity-box'>
                                <ul className='activity-list'>
                                  <li className='pt-2  p-0'>
                                    <div className='left-line-c'></div>
                                    <div className='activity-user'>
                                      {item2?.timeData?.status == 1 ? (
                                        <a
                                          type='submit'
                                          title='Lesley Grauer'
                                          data-toggle='tooltip'
                                          className='avatar lightly-blue-bg'
                                        >
                                          <img src={User} alt='' />
                                        </a>
                                      ) : item2?.timeData?.status == 2 ? (
                                        <a
                                          type='submit'
                                          title='Lesley Grauer'
                                          data-toggle='tooltip'
                                          className='avatar dark-blue-bg'
                                        >
                                          <img src={accept} alt='' />
                                        </a>
                                      ) : item2?.timeData?.status == 3 ? (
                                        <a
                                          type='submit'
                                          title='Lesley Grauer'
                                          data-toggle='tooltip'
                                          className='avatar light-green-bg'
                                        >
                                          <img src={jobstarted} alt='' />
                                        </a>
                                      ) : item2?.timeData?.status == 4 ? (
                                        <a
                                          type='submit'
                                          title='Lesley Grauer'
                                          data-toggle='tooltip'
                                          className='avatar light-yellow-bg'
                                        >
                                          <img src={pause} alt='' />
                                        </a>
                                      ) : (
                                        item2?.timeData?.status == 5 && (
                                          <a
                                            type='submit'
                                            title='Lesley Grauer'
                                            data-toggle='tooltip'
                                            className='avatar lightly-green-bg'
                                          >
                                            <img src={jobdone} alt='' />
                                          </a>
                                        )
                                      )}
                                    </div>
                                    <div className='activity-content'>
                                      <div className='timeline-content d-flex justify-content-between latest-join'>
                                        <div className='createby'>
                                          <a
                                            type='submit'
                                            className='name'
                                          />{' '}
                                          <a
                                            className='timeline-text-c'
                                            type='submit'
                                          >
                                            {item2?.jobstatuscheck?.jobType}
                                            <br />
                                            {item2?.timeData?.status == 1
                                              ? 'Job created by'
                                              : item2?.timeData?.status == 2
                                                ? ' Accepted'
                                                : item2?.timeData?.status == 3
                                                  ? ' Started'
                                                  : item2?.timeData?.status == 4
                                                    ? ' Paused'
                                                    : item2?.timeData?.status == 5 &&
                                                    ' Completed'}
                                            {'  '}

                                            {item2?.jobstatuscheck?.contractordetail?.map(
                                              (ite32, index) => {
                                                return (
                                                  <>
                                                    {ite32?.firstName +
                                                      ' ' +
                                                      ite32?.lastName}
                                                  </>
                                                );
                                              }
                                            )}
                                          </a>
                                          <span></span>
                                        </div>
                                        <div className='time'>
                                          <span>
                                            {moment2(item2?.sortTimesheet).tz('Europe/London').format('MMMM Do YYYY, h:mm   a')}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                        }
                      </>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>

        <div className='page-header mt-5 second-page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title'>Job Address </h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>Information on the client and job site. </span>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-5'>
            <div className='form-group mb-2'>
              <label className='col-form-label mb-0'>
                Post Code Search <span className='text-danger' >*</span>
              </label>
              <div className='d-flex justify-content-between'>
                <div className='w80'>
                  <input
                    type='text'
                    className='form-control select-drop-input'
                    onChange={(e) => handleChangeForm(e)}
                    name='pinCode'
                    value={forvar?.pinCode}
                  />
                  <select
                    className='custom-select-in'
                    onChange={(e) => handleChangeForm(e, "custom")}
                  >
                    {addressApi?.addresses?.length > 0 && addressApi?.addresses?.map((item, i) => {
                      return (
                        <option value={i} >{`${item?.line_1},${item?.line_2},${item?.town_or_city}`}</option>
                      )
                    })}
                  </select>


                </div>
                <div className='w20'>
                  <button type="submit" onClick={(e) => postCodeClick(e)} className='btn btn-primary submit-btn-part'>Search</button>
                </div>

              </div>

            </div>
            <div className='form-group'>
              <label className='col-form-label mb-0'>
                Address<span className='text-danger'>*</span>
              </label>
              {/* <input
                type='text'
                className='form-control'
                onChange={(e) => handleChangeForm(e)}
                name='addressLine1'
                value={forvar.addressLine1}
              /> */}

              <input
                type='text'
                className='form-control mb-2'
                onChange={(e) => handleChangeForm(e)}
                name='addressLine1'
                placeholder='First Line'
                value={forvar?.addressLine1}
              />
              <input
                type='text'
                className='form-control mb-2'
                onChange={(e) => handleChangeForm(e)}
                name='addressLine2'
                placeholder='Second Line'
                value={forvar?.addressLine2}
              />
              <input
                type='text'
                className='form-control mb-2'
                name='pinCode'
                placeholder='City/Town'
                onChange={(e) => handleChangeForm(e)}
                value={forvar?.town_or_city}
              />
              <input
                type='text'
                className='form-control'
                name='pinCode'
                placeholder='Post Code'
                onChange={(e) => handleChangeForm(e)}
                value={forvar?.pinCode}
              />
            </div>
          </div>
          <div className='col-md-5'>
            <div className='form-group mb-2'>
              <label className='col-form-label mb-0'>
                Site Details <span className='text-danger'>*</span>
              </label>
              <textarea
                type='text'
                className='form-control full-height'
                onChange={(e) => handleChangeForm(e)}
                name='sitedetail'
                value={forvar?.sitedetail}
              />
            </div>
            <div className='form-group'>
              <label className='col-form-label mb-0'>
                Parking <span className='text-danger'>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                onChange={(e) => handleChangeForm(e)}
                name='parking'
                value={forvar?.parking}
              />
            </div>
          </div>
        </div>
        <div className='page-header mt-5 second-page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title'>
                Flamehold & Contractors{' '}
              </h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>
                    Decide which communications you'd like to receive and how.
                  </span>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='col-form-label'>
                <strong>Asbestos on Site </strong>
                <span className='text-danger'>*</span>
              </label>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='radio-group'>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='asbestosOnSite'
                      value='Yes'
                      checked={'Yes' === forvar?.asbestosOnSite}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Yes</label>
                  </div>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='asbestosOnSite'
                      value='No'
                      checked={'No' === forvar?.asbestosOnSite}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>No</label>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='radio-group'>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='asbestosOnSite'
                      value='Unknown'
                      // defaultChecked={forvar?.asbestosOnSite}
                      checked={'Unknown' === forvar?.asbestosOnSite}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Unknown</label>
                  </div>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='asbestosOnSite'
                      value={'Presumed High'}
                      checked={'Presumed High' == forvar?.asbestosOnSite}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Presumed High</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label className='col-form-label'>
                <strong>FRA Checked</strong>{' '}
                <span className='text-danger'>*</span>
              </label>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                <div className='radio-group'>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='fraChecked'
                      value={'Yes'}
                      checked={'Yes' == forvar?.fraChecked}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Yes</label>
                  </div>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='fraChecked'
                      onChange={(e) => handleChangeForm(e)}
                      checked={'No' == forvar?.fraChecked}
                      value={'No'}
                    />
                    <label for=''>No</label>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='radio-group'>
                  <div className='form-group'>
                    <input
                      type='radio'
                      name='fraChecked'
                      value={'Unknown'}
                      checked={'Unknown' == forvar?.fraChecked}
                      onChange={(e) => handleChangeForm(e)}
                    />
                    <label for=''>Unknown</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="assessment_card">
          <h3 className='mb-0'>
            Risk Assessment
          </h3>
          <p className=''>
            Upload a risk assessment for the job
          </p>
          <div className="d-flex">
            <div className="input_card floor_input_card">
              <p className="text-cenetr mb-0 floor_input">{pdfImg == undefined ? ((forvar?.pdf_file == "false" || forvar?.pdf_file == null || forvar?.pdf_file == undefined || forvar?.pdf_file == "") ? "" : forvar?.pdf_file?.replace("public/uploads/", "")) : pdfImg}</p>
            </div>
            <div className="upload-btn-wrapper">
              <button className="btn">Upload</button>
              <input type="file"
                onChange={(e) => {
                  _handleUpload(e, "file");
                }}
                name="myfile" />
            </div>
            <div className="upload-btn-wrapper">
              <button className="btn" onClick={(e) => {
                e.preventDefault()
                dispatch(updateRiskAss({ job_id: forvar?._id, type: "pdf_file" }))
                window.location.reload();
              }}>Delete</button>
            </div>

          </div>

        </div>

        <div className="assessment_card mt-4 assessment_card2">
          <h3 className='mb-0'>
            Floor Plans
          </h3>
          <p className=''>
            Upload plans for the property
          </p>
          <div className='d-flex '>
            <div className="d-flex">
              <div className="input_card floor_input_card">
                <p className="text-cenetr mb-0 floor_input">{floor1 == undefined ?
                  ((forvar?.floor_img1 == "false" || forvar?.floor_img1 == null || forvar?.floor_img1 == undefined || forvar?.floor_img1 == "") ? "" : forvar?.floor_img1?.replace("public/uploads/", ""))
                  : floor1}</p>
              </div>
              <div className="upload-btn-wrapper">
                <button className="btn">Upload</button>
                <input type="file"
                  onChange={(e) => {
                    e.target.files[0]?.type == "application/pdf" ? showPdf(e, 'floor1') :
                      _handleUpload(e, "floor1");

                  }}
                  name="myfile" />

              </div>
              <div className="upload-btn-wrapper">
                {forvar?.floor_img1 &&
                <button className="btn" onClick={(e) => {
                  e.preventDefault()
                  dispatch(updateRiskAss({ job_id: forvar?._id, type: "floor_img1" }))
                  window.location.reload();
                }}>Delete</button> }
              </div>
            </div>
            <div className="d-flex ms-4">
              <div className="input_card floor_input_card">
                <p className="text-cenetr mb-0 floor_input">{floor2 == undefined ? ((forvar?.floor_img2 == "false" || forvar?.floor_img2 == null || forvar?.floor_img2 == undefined || forvar?.floor_img2 == "") ? "" : forvar?.floor_img2?.replace("public/uploads/", "")) : floor2}</p>
              </div>
              <div className="upload-btn-wrapper">
                <button className="btn">Upload</button>
                <input type="file"
                  onChange={(e) => {

                    e.target.files[0]?.type == "application/pdf" ? showPdf(e, 'floor2') :
                      _handleUpload(e, "floor2")
                  }}
                  name="myfile" />
              </div>
            </div>
            <div className="upload-btn-wrapper">
            {forvar?.floor_img2 &&
              <button className="btn" onClick={(e) => {
                e.preventDefault()
                dispatch(updateRiskAss({ job_id: forvar?._id, type: "floor_img2" }))
                window.location.reload();
              }}>Delete</button> }
            </div>



          </div>



        </div>
        <div className='page-header mt-5 second-page-header'>
          <div className='row align-items-center'>
            <div className='col-10 '>
              <h3 className='page-title second-page-title'>Job Sections</h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>
                    Use a permanent address where you can receive mail.{' '}
                  </span>{' '}
                </li>
              </ul>
            </div>

          </div>
        </div>
        {inputList &&
          inputList.length > 0 &&
          createSection(inputList && inputList)}



        <div className='mt-4 mb-5 text-end'>
          <button
            className='btn btn-primary p-2 '
            id='addclick'
            onClick={(e) => addClick(e)}
          >
            {' '}
            Add Section
            {/* {localStorage.getItem("lastname")} */}
          </button>
        </div>
        {/* {showSection == true && createSection()} */}
        <div className='border-t-c'></div>
        <div className='button-fixed-b'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='mt-3'>
                <button
                  className='btn custom_btn  mr-3 btn-md'
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack()
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={!inputList}
                  onClick={(e) => submitDetail(e)}
                  type='submit'
                  className='btn btn-primary btn-md'
                >
                  Save
                </button>
              </div>
            </div>
            {/* <div className='col-md-3'>
              <div className='mt-2 mb-2'>
                <button
                  onClick={(e) => {
                    submitDetail(e, "maintain_state");
                    localStorage.setItem("job_id", id)
                  }}
                  className='floor-btn'>
                  Floor Plans
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <Modal show={selectcontractor} onHide={handleClose} animation={false} className="add_contractor">
          <Modal.Header closeButton>
            <Modal.Title>Contractor</Modal.Title>
          </Modal.Header>
          <Modal.Body className='overflowScroll h-455'>
            <Accordion defaultActiveKey='0'>
              {contractorName?.data?.map((contractName, i) => {
                return (
                  <>
                    <Accordion.Item eventKey={i}>
                      {typeSuper == 'super'
                        ? inputList &&
                        inputList[selectcontractorIndex] &&
                        inputList[selectcontractorIndex]['contractorId'] !=
                        contractName._id && (
                          <>
                            <Accordion.Header>
                              <div className='d-flex'>
                                <div className='two-colum'>
                                  <div
                                    className='d-flex'
                                    id='contractorId'
                                    onClick={(e) =>
                                      handleContarctor(
                                        e,
                                        contractName,
                                        selectcontractorIndex
                                      )
                                    }
                                  >


                                    <div className='profile-placeholder'>
                                      {contractName?.firstName
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        contractName?.lastName
                                          ?.charAt(0)
                                          .toUpperCase()}
                                    </div>

                                    <div className='user-info-output'>
                                      <div className='user-name blue-color-text'>
                                        {contractName?.firstName +
                                          ' ' +
                                          contractName?.lastName}
                                      </div>
                                      <div className='comment-time'>
                                        <i
                                          class='fa fa-phone pr-2'
                                          aria-hidden='true'
                                        ></i>
                                        {contractName?.phone}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='mb-0'>
                                  <div className='d-flex'>
                                    <div className='user-info-output'>
                                      <div className='assigned'>
                                        <div className='assign-label'>
                                          Assigned
                                        </div>
                                        <p>
                                          {moment(
                                            contractName?.createdAt
                                          ).format('Do MMM YY')}
                                          {/* <span>
                                  {' '}
                                  {moment(contractName?.createdAt)
                                    .add(1, 'months')
                                    .calendar()}
                                </span> */}
                                        </p>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p >
                                {contractor_history &&
                                  contractor_history[i]?.map(
                                    (item, i) => (
                                      <>
                                        <div className='contractor-status-align'
                                          style={{
                                            marginBottom: '6px',
                                          }}
                                        >
                                          <span
                                            style={{ color: 'green' }}
                                          >
                                            {item.jobType}
                                          </span>
                                          <span>
                                            {moment(
                                              item?.createdAt
                                            ).format('Do MMM YY')}
                                          </span>
                                        </div>
                                      </>
                                    )
                                  )}
                              </p>
                            </Accordion.Body>

                          </>
                        )
                        : inputList &&
                        inputList[selectcontractorIndex] &&
                        inputList[selectcontractorIndex]['supervisor'] !=
                        contractName._id && (
                          <>
                            <div className='according-data-pop-c'>
                              <div className='check-part-pop'>
                                <form>
                                  <div className=' d-flex justify-content-center align-items-center checkbox_placement form-group'>
                                    <input type="checkbox"
                                      id="vehicle1"
                                      name="vehicle1"
                                      checked={inputList[selectcontractorIndex]['contractorId']?.includes(contractName?._id) ? true : false}
                                      onClick={(e) =>
                                        handleContarctor(
                                          e,
                                          contractName,
                                          selectcontractorIndex
                                        )
                                      }
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className='accroding-data'>
                                <Accordion.Header>
                                  <div className='d-flex'>
                                    <div className='two-colum'>
                                      <div
                                        className='d-flex'
                                        id='contractorId'
                                      // onClick={(e) =>
                                      //   handleContarctor(
                                      //     e,
                                      //     contractName,
                                      //     selectcontractorIndex
                                      //   )
                                      // }
                                      >


                                        <div className='profile-placeholder'>
                                          {contractName?.firstName
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            contractName?.lastName
                                              ?.charAt(0)
                                              .toUpperCase()}
                                        </div>

                                        <div className='user-info-output'>
                                          <div className='user-name blue-color-text'>
                                            {contractName?.firstName +
                                              ' ' +
                                              contractName?.lastName}
                                          </div>
                                          <div className='comment-time'>
                                            <i
                                              class='fa fa-phone pr-2'
                                              aria-hidden='true'
                                            ></i>
                                            {contractName?.phone}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='mb-0'>
                                      <div className='d-flex'>
                                        <div className='user-info-output'>
                                          <div className='assigned'>
                                            <div className='assign-label'>
                                              Assigned
                                            </div>
                                            <p>
                                              {moment(
                                                contractName?.createdAt
                                              ).format('Do MMM YY')}
                                              {/* <span>
                                        {' '}
                                        {moment(contractName?.createdAt)
                                          .add(1, 'months')
                                          .calendar()}
                                      </span> */}
                                            </p>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Header>
                              </div>

                            </div>


                            <Accordion.Body>
                              <p>
                                {contractor_history &&
                                  contractor_history[i]?.map(
                                    (item, i) => (
                                      <>
                                        <div className='contractor-status-align'
                                          stryle={{
                                            marginBottom: '6px',
                                          }}
                                        >
                                          <span
                                            style={{ color: 'green' }}
                                          >
                                            {item.jobType}
                                          </span>
                                          <span>
                                            {moment(
                                              item?.createdAt
                                            ).format('Do MMM YY')}
                                          </span>
                                        </div>
                                      </>
                                    )
                                  )}
                              </p>
                            </Accordion.Body>
                          </>
                        )}


                    </Accordion.Item>
                  </>
                );
              })}
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button className='btn-new' onClick={handleClose}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
}

export default EditJob;
