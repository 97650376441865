import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import {
  getContractorList,
  getTimeSheet,
} from '../../Redux/Action/SuperAdminAction';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from '../../components/ToastContainer/Toast';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import { PDFExport } from '@progress/kendo-react-pdf';

function TimeSheet() {

  const dispatch = useDispatch();
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 7);

  const [rangeStart, setRangeStart] = useState('');
  const [rangeEnd, setRangeEnd] = React.useState('');
  const [contractorId, setContractorId] = React.useState('');
  const pdfExportComponent = React.useRef(null);

  const [mainbtn, setMainBtn] = React.useState('');

  const contractiorlist = useSelector((state) => state.adminList.contractorList);
  const searchTimesheetData = useSelector((state) => state?.adminList?.GetTimeSheet?.total);

  const selectStartDate = (d) => {
    setRangeStart(d);
  };

  const selectEndDate = (d) => {
    setRangeEnd(d);
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  const handleChangeForm = (e) => {
    setMainBtn('');
    setContractorId('');
    setRangeStart('');
    setRangeEnd('');
    setContractorId(e.target.value);
  };

  const handleTimeSheet = () => {
    if (rangeStart == '' || rangeEnd == '' || contractorId == '') {
      toast.error('Please select all the fields.');
    } else {
      setMainBtn('true');
      dispatch(
        getTimeSheet({
          startdate: convert(rangeStart),
          enddate: convert(rangeEnd),
          contractorid: contractorId,
        })
      );
    }
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const columns = [
    {
      id: 1,
      name: 'DATE',
      selector: (row) => row?.sheet?.uniqueTime == undefined ? "" : moment(row?.sheet?.uniqueTime).format('D/M/YYYY'),

      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: 'JOB NO.',
      selector: (row) => {
        return (
          (row?.sheet?.totalTimesheet?.section_id?.job_id?.JobRefNo.toString().length == 1 && '00' + row?.sheet?.totalTimesheet?.section_id?.job_id?.JobRefNo) ||
          (row?.sheet?.totalTimesheet?.section_id?.job_id?.JobRefNo.toString().length == 2 && '0' + row?.sheet?.totalTimesheet?.section_id?.job_id?.JobRefNo) ||
          (row?.sheet?.totalTimesheet?.section_id?.job_id?.JobRefNo.toString().length == 3 && row?.sheet?.totalTimesheet?.section_id?.job_id?.JobRefNo)
        );
      },
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: 'ORCHARD NO.',
      selector: (row) => row?.sheet?.totalTimesheet?.section_id?.chard_number,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: 'TYPE',
      selector: (row) => row?.sheet?.totalTimesheet?.section_id?.jobType,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: 'CLIENT',
      selector: (row) => row?.sheet?.totalTimesheet?.section_id?.job_id?.clientId?.clientName,
    },
    {
      id: 4,
      name: 'CONTRACTOR',
      selector: (row) => row?.sheet?.contractorName?.firstName == undefined ? "" : row?.sheet?.contractorName?.firstName + " " + row?.sheet?.contractorName?.lastName,
    },
    {
      id: 4,
      name: 'SATRT TIME',
      selector: (row) => row?.start,
    },
    {
      id: 4,
      name: 'END TIME',
      selector: (row) => row?.end,
    },
    {
      id: 4,
      name: 'TOTAL TIME',
      selector: (row) => row?.prettyHourMinute,
    },
  ];

  const timesheetComponent = (
    <div className='row'>
      <div className='col-md-12 d-flex1'>
        <div className=' card-table flex-fill'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='form-group'>
                  <label for=''>Contractor</label>
                  <select
                    name='category'
                    className='form-control'
                    id=''
                    onChange={(e) => handleChangeForm(e)}

                  // onChange={(e) => handleDispatch(e.target.value)}
                  >
                    <option value=''>Please select contractor</option>
                    {contractiorlist?.data?.map((contractiorlist) => (
                      <>
                        <option value={contractiorlist._id}>
                          {contractiorlist?.firstName +
                            ' ' +
                            contractiorlist?.lastName}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div>

              {/* <div className='col-6'>
                <div className='form-group'>
                  <label for=''>Job Types</label>

                  <select
                    name='jobtype'
                    className='form-control'
                    onChange={(e) => handleTimesheet(e.target.value)}
                  >
                    <option value='' className='d-none'>
                      Select Client
                    </option>
                    {getDropdown?.map((clientList) => (
                      <>
                        <option value={clientList?._id}>
                          {clientList?._id}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div> */}
              <div className='col-md-1'></div>
              <div className='col-md-5'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='form-group'>
                      <label for=''>Date Range</label>
                      <DatePicker
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        selectsStart
                        selected={rangeStart}
                        placeholderText='Start Date'
                        //  startDate={rangeStart}
                        //  endDate={rangeEnd}
                        onChange={selectStartDate}
                      // format='yyyy-MM-dd'
                      />
                    </div>
                  </div>

                  <div className='col-6'>
                    <div className='form-group'>
                      <label for=''>Date Range</label>
                      <DatePicker
                        selectsEnd
                        dateFormat='dd/MM/yyyy'
                        selected={rangeEnd}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        placeholderText='End Date'
                        className='form-control'
                        onChange={selectEndDate}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className='col-md-1'></div>
              <div className='col-md-2'>
                <div className='form-group'>
                  <label for=''>Total Time</label>
                  <div className='total-time-view form-control'>
                    {searchTimesheetData?.totalTime}
                  </div>
                </div>
              </div>

              <div
                className='col-12 mb-4'
              // onClick={() => handleTimeSheet()}
              >

                <div className='row' style={{ marginTop: '10px' }}>
                  <div className='col-md-12 d-flex1'>
                    <div className=' card-table flex-fill'>
                      <div className='card-body'>

                        <div className='table-responsive'>
                          <DataTable
                            columns={columns}
                            data={searchTimesheetData?.lastTimesheetArr?.length > 0 ? searchTimesheetData?.lastTimesheetArr : [{
                              start: "",
                              end: "",
                              prettyHourMinute: "",
                              sheet: ""
                            }]}
                            defaultSortFieldId={1}
                            sortIcon={<SortIcon style={{ fill: "white" }} />}
                            pagination={searchTimesheetData?.lastTimesheetArr?.length > 0 ? 1 : ""}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <Button onClick={() => handleTimeSheet()} className='btn btn-primary btn-new'>
                  Search
                </Button>
              </div>

              {/* <div className='col-md-12'>
                <div className='total_hours mt-3'>
                  <label for=''>Total Hours</label>
                  <div className='d-flex'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='15 hours'
                      readonly
                    />
                    <Button className=' btn btn-primary'>Download</Button>
                  </div>
                </div>
              </div> */}
            </div>

            {/* {searchTimesheetData?.map((item, i) => {
              return (
                <>
                  {mainbtn != '' &&
                    contractorId != '' &&
                    rangeStart != '' &&
                    rangeEnd != '' && (
                      <div
                        className='row'
                        onClick={() => {
                          dispatch(
                            timesheetlistdata(
                              contractorId,
                              // new Date(item?.date).getTime()
                              moment(item?.date).format('M/D/YYYY')
                            )
                          );
                          history.push(
                            `day_detail/${contractorId}/${new Date(
                              item?.date
                            ).getTime()}`
                          );
                        }}
                      >
                        <div className='col-md-12 mt-4'>
                          <div className='card'>
                            <div className='card-body'>
                              <Link className='list_none'>
                                <div className='time_data_show  p-3 d-flex align-items-center  justify-content-between'>
                                  <div>
                                    <label className='label-c-flame'>
                                      Date
                                    </label>
                                    <h3 className='m-0 m-10'>
                                      {moment(item?.date).format(
                                        'dddd, Do MMMM'
                                      )}
                                    </h3>
                                  </div>
                                  <div>
                                    <label className='label-c-flame'>
                                      Total Hours Worked
                                    </label>
                                    <h3 className='m-0 m-10'>
                                      {item.totalSum}
                                    </h3>
                                  </div>
                                  <p className='m-0'>
                                    <i className='la la-angle-right fontweight'></i>
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>

  );

  const pdfExportCom = (
    <PDFExport
      ref={pdfExportComponent}
      paperSize='auto'
      margin={40}
      fileName={`Report for ${new Date().getFullYear()}`}
      author='KendoReact Team'
    >
      <div className='row'>
        <div className='col-md-12 d-flex1'>
          <div className=' card-table flex-fill'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label for=''>Contractor</label>
                    <select
                      name='category'
                      className='form-control'
                      id=''
                      onChange={(e) => handleChangeForm(e)}

                    // onChange={(e) => handleDispatch(e.target.value)}
                    >
                      <option value=''>Please select contractor</option>
                      {contractiorlist?.data?.map((contractiorlist) => (
                        <>
                          <option value={contractiorlist._id}>
                            {contractiorlist?.firstName +
                              ' ' +
                              contractiorlist?.lastName}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                </div>

                {/* <div className='col-6'>
                <div className='form-group'>
                  <label for=''>Job Types</label>

                  <select
                    name='jobtype'
                    className='form-control'
                    onChange={(e) => handleTimesheet(e.target.value)}
                  >
                    <option value='' className='d-none'>
                      Select Client
                    </option>
                    {getDropdown?.map((clientList) => (
                      <>
                        <option value={clientList?._id}>
                          {clientList?._id}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div> */}
                <div className='col-md-1'></div>
                <div className='col-md-5'>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='form-group'>
                        <label for=''>Date Range</label>
                        <DatePicker
                          dateFormat='dd/MM/yyyy'
                          className='form-control'
                          selectsStart
                          selected={rangeStart}
                          placeholderText='Start Date'
                          //  startDate={rangeStart}
                          //  endDate={rangeEnd}
                          onChange={selectStartDate}
                        // format='yyyy-MM-dd'
                        />
                      </div>
                    </div>

                    <div className='col-6'>
                      <div className='form-group'>
                        <label for=''>Date Range</label>
                        <DatePicker
                          selectsEnd
                          dateFormat='dd/MM/yyyy'
                          selected={rangeEnd}
                          startDate={rangeStart}
                          endDate={rangeEnd}
                          placeholderText='End Date'
                          className='form-control'
                          onChange={selectEndDate}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className='col-md-1'></div>
                <div className='col-md-2'>
                  <div className='form-group'>
                    <label for=''>Total Time</label>
                    <div className='total-time-view form-control'>
                      {searchTimesheetData?.totalTime}
                    </div>
                  </div>
                </div>

                <div
                  className='col-12 mb-4'
                // onClick={() => handleTimeSheet()}
                >

                  <div className='row' style={{ marginTop: '10px' }}>
                    <div className='col-md-12 d-flex1'>
                      <div className=' card-table flex-fill'>
                        <div className='card-body'>

                          <div className='table-responsive hidden-table'>
                            <DataTable
                              columns={columns}
                              data={searchTimesheetData?.lastTimesheetArr?.length > 0 ? searchTimesheetData?.lastTimesheetArr : [{
                                start: "",
                                end: "",
                                prettyHourMinute: "",
                                sheet: ""
                              }]}
                              defaultSortFieldId={1}
                              sortIcon={<SortIcon style={{ fill: "white" }} />}
                            //  pagination={searchTimesheetData?.lastTimesheetArr?.length > 0 ? 1 : ""}
                            />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <Button onClick={() => handleTimeSheet()} className='btn btn-primary btn-new'>
                    Search
                  </Button>
                </div>

                {/* <div className='col-md-12'>
                <div className='total_hours mt-3'>
                  <label for=''>Total Hours</label>
                  <div className='d-flex'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='15 hours'
                      readonly
                    />
                    <Button className=' btn btn-primary'>Download</Button>
                  </div>
                </div>
              </div> */}
              </div>

              {/* {searchTimesheetData?.map((item, i) => {
              return (
                <>
                  {mainbtn != '' &&
                    contractorId != '' &&
                    rangeStart != '' &&
                    rangeEnd != '' && (
                      <div
                        className='row'
                        onClick={() => {
                          dispatch(
                            timesheetlistdata(
                              contractorId,
                              // new Date(item?.date).getTime()
                              moment(item?.date).format('M/D/YYYY')
                            )
                          );
                          history.push(
                            `day_detail/${contractorId}/${new Date(
                              item?.date
                            ).getTime()}`
                          );
                        }}
                      >
                        <div className='col-md-12 mt-4'>
                          <div className='card'>
                            <div className='card-body'>
                              <Link className='list_none'>
                                <div className='time_data_show  p-3 d-flex align-items-center  justify-content-between'>
                                  <div>
                                    <label className='label-c-flame'>
                                      Date
                                    </label>
                                    <h3 className='m-0 m-10'>
                                      {moment(item?.date).format(
                                        'dddd, Do MMMM'
                                      )}
                                    </h3>
                                  </div>
                                  <div>
                                    <label className='label-c-flame'>
                                      Total Hours Worked
                                    </label>
                                    <h3 className='m-0 m-10'>
                                      {item.totalSum}
                                    </h3>
                                  </div>
                                  <p className='m-0'>
                                    <i className='la la-angle-right fontweight'></i>
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              );
            })} */}
            </div>
          </div>
        </div>
      </div>
    </PDFExport>
  )

  useEffect(() => { dispatch(getTimeSheet({ noData: "true" })) }, [])
  useEffect(() => dispatch(getContractorList()), []);

  return (
    <div>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>Time Sheet</h3>
              </div>
              <div className='col-auto float-right ml-auto'>
                <Link to='/day_detail' className='btn add-btn'>
                  Add Time
                </Link>
                <div
                  className='btn add-btn'
                  style={{ marginRight: '10px' }}
                  onClick={exportPDFWithComponent}
                >
                  Export
                </div>
              </div>
            </div>
          </div>
          {timesheetComponent}
          <div
            style={{
              position: 'absolute',
              left: '-100000px',
              top: 0,
            }}
          >
            {pdfExportCom}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeSheet;