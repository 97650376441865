import moment from 'moment';
import React from 'react';
import logo from '../../../src/components/assets/img/logo786.png';

const Pdf_Report_3 = ({ exportData, uniqueChatProducts, workCarriedOut }) => {
    return (
        <>
            <div class="main-table-sec font-family-arial">
                <table cellSpacing={0} cellPadding={0} style={{ width: '210mm' }}>
                    <tbody>
                        <tr>
                            <td></td>
                        </tr>
                        <tr >
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px', textAlign: 'center' }}>
                                <h3 style={{ fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif !important' }}>Certificate of Fire</h3>
                                <h4 style={{ fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif !important' }}>Protection works</h4>

                            </td>
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                                <img src={logo} alt="" style={{ textAlign: 'center', width: '150px' }} />
                            </td>
                        </tr>

                        <tr >
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px'}}>
                                <h4 style={{ fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif !important' }}>{exportData?.job_id?.JobRefNo.toString().length == 1
                                    ? '000' + exportData?.job_id?.JobRefNo
                                    : '' || exportData?.job_id?.JobRefNo.toString().length == 2
                                        ? '00' + exportData?.job_id?.JobRefNo
                                        : '' || exportData?.job_id?.JobRefNo.toString().length == 3
                                            ? '0' + exportData?.job_id?.JobRefNo
                                            : '' || exportData?.job_id?.JobRefNo.toString().length == 4
                                                ? exportData?.job_id?.JobRefNo
                                                : ''} Certificate</h4>
                                <h3 style={{ fontSize: '16px' }}>No.010.2022</h3>
                                <p>{exportData?.job_id?.clientId?.clientName}</p>
                                <p>{exportData?.job_id?.clientId?.streetAddress}</p>
                                <p>{exportData?.job_id?.clientId?.city}</p>
                                <p>{exportData?.job_id?.clientId?.pinCode}</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>Priority Fields, Kettleby,Brigg</p>
                                <p>Lincolnshire DN20 9HN</p>
                            </td>
                        </tr>

                        <tr>
                            <td rowSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <h3 style={{ fontSize: '16px', fontFamily: 'Arial, Helvetica, sans-serif !important' }}>Property Address</h3>
                                <p>{exportData?.job_id?.addressLine1}</p>
                                <p>{exportData?.job_id?.addressLine2}</p>
                                <p>{exportData?.job_id?.clientId?.city}</p>
                                <p>{exportData?.job_id?.pinCode}</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>Tel: 01652 413110</p>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>Contact: {exportData?.job_id?.clientId?.firstName + " " + exportData?.job_id?.clientId?.lastName}</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>Areas of Application:</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>Sprinkler Pipes above flats 12 and 13</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>Products applied:</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>{uniqueChatProducts?.length > 0 ? uniqueChatProducts?.map((item, i) => {
                                    return (
                                        <>
                                            {item?.chat_id?.product_id?.name},
                                        </>
                                    )
                                }) : "No Product Used!"}</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p >Specifics:</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>See Overleaf:</p>
                            </td>
                        </tr>



                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p >Installer Details</p>
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p >Name:</p>
                                <p>Phil Asquith</p>
                            </td>
                            <td rowSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p >Address</p>
                                <p>Priory Fields</p>
                                <p>Kettleby</p>
                                <p>Brigg</p>
                                <p>Lincolnshire</p>
                                <p>DN20 9HN</p>
                            </td>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>Tel: 01652 413110</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p >Email: <a href="#" >phil@flamehold.co.uk</a></p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p ><span >Company Name :</span> Flame Hold Ltd</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p ><span >Qualification :</span> 12 - GIFireE,  FPA Fire Risk Assessor & </p>
                                <p >FIRAS Accreditation - Certificate No. FD5012; FIRAS ID - S69545</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>I/We hereby certify that the Passive Fire Protection products/systems, supplied for the above project have been supplied & fitted
                                    by the Flame Hold Ltd.<br />  To the best of my knowledgeand belief-based on the information supplied- to be the appropriate products/systems for the project to provide Passive Fire<br /> Protection to achieve 30-minute Fire ratings</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p>Signature of Installer:</p>
                            </td>
                            <td colSpan={2} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p ><span>Date: </span>{moment().format("DD.MM.YYYY")}</p>
                            </td>
                        </tr>
                        <div className="page-break"></div>       
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p> All fire Prevention measures should be checked and preventive maintenance carried out.Failure to do so may impair the performance.</p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p className='word-elips'>{workCarriedOut} </p>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{ fontSize: '14px', fontFamily: 'Arial, Helvetica, sans-serif !important', padding: '16px' }}>
                                <p >This section has been intentionally left blank</p>
                            </td>
                        </tr>


                       
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Pdf_Report_3;







