import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-image-lightbox/style.css';
import { getCompleteQuestions } from '../../Redux/Action/SuperAdminAction';

const QuestionList = (props) => {
    const getQuesD = useSelector((state) => state?.adminList?.GetQuestions?.data);

    const getBackAllQues = (form_id, section_id) => {
        props.setQuestiondata(false);
    }

    return (
        <>
            <div className='card  card-width mb-3'>
                <div className='card-body p-2'>
                    <p className='arrow_right fontsizeback m-0 ' style={{ "cursor": "pointer" }} onClick={() => getBackAllQues()}> <i class="fa back_class mr-1 fa-angle-left" aria-hidden="true"></i> Back</p>
                </div>
            </div>
            {getQuesD?.map((item, i) => {
                return (
                    <>
                        <div className='colal_form'>
                            <div class="accordion" id="accordionExample">

                                <div class="card mb-2 form-card-flame ">
                                    <div key={i} class="card-header d-flex justify-content-between align-items-center" id="headingOne">
                                        <h2 class="mb-0 ">
                                            <div class="black btn btn-link btn-block text-left p-0 flame-btn" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <div class="question-group">
                                                    <div class="question-form">
                                                        <div className='qus-label'>Question :</div> <div className='qus-output'>{item?.question}</div>
                                                    </div>
                                                    <div class="answer-form">
                                                        <div className='qus-label'>Answer :</div><div className='qus-output'>{item?.answer}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <p className='arrow_right m-0' style={{ "cursor": "pointer" }} onClick={() => getBackAllQues()} > <i class="fa fa-angle-left" aria-hidden="true"></i> </p>


                                    </div>
                                </div>
                            </div>
                        </div>


                    </>
                )
            })}

        </>
    )
}

const SubForm = (props) => {
    const dispatch = useDispatch();
    const [question, setQuestiondata] = useState(false);
    const getAllQues = (form_id, section_id) => {
        dispatch(getCompleteQuestions(form_id, section_id))
        setQuestiondata(true);
    }

    const getBacktoAllQues = (form_id, section_id) => {
        props.setSeeAllSubForm(false);
    }

    return (
        <>
            {
                question == false ?
                    <>
                        <div className='card  card-width mb-3'>
                            <div className='card-body p-2'>
                                <p className='arrow_right fontsizeback m-0 ' style={{ "cursor": "pointer" }} onClick={() => getBacktoAllQues()}> <i class="fa back_class mr-1 fa-angle-left" aria-hidden="true"></i> Back</p>
                            </div>
                        </div>
                        {props?.allSubForm?.map((item, i) => {
                            return (
                                <>

                                    <div className='colal_form'>
                                        <div class="accordion" id="accordionExample">

                                            <div class="card mb-2 form-card-flame ">
                                                <div key={i} class="card-header d-flex justify-content-between align-items-center" id="headingOne">


                                                    <h2 class="mb-0 ">

                                                        <button onClick={() => getAllQues(item?._id, props?.section_id_data)} class=" black btn btn-link btn-block text-left p-0 flame-btn" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            {item?.sub_form_name}
                                                        </button>
                                                    </h2>

                                                    <p className='arrow_right m-0'>{item?.status == 1 ? <i class="fa fa-check-circle green-check" aria-hidden="true"></i> : ""} </p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </>
                            )
                        })}
                    </>
                    :

                    <QuestionList setQuestiondata={setQuestiondata} />
            }
        </>
    )
}

export default SubForm;