import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { createAdmin } from '../../Redux/Action/SuperAdminAction';
function AddAdmin() {
  const history = useHistory();
  const dispatch = useDispatch();
  
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('FirstName is Required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      lastName: Yup.string()
        .required('LastName is Required')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is Required!'),
      phone: Yup.string()
        .required('Phone is Required')
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          'Phone number is not valid'
        ),
    }),
    onSubmit: (values) => {
      dispatch(createAdmin(values, history));
    },
  });
  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header second-page-header'>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title second-page-title'>
                Personal Information
              </h3>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item '>
                  {' '}
                  <span>
                    Use a permanent address where you can recieve mail.
                  </span>{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  First Name <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='firstName'
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <p style={{ color: 'red' }}>{formik.errors.firstName}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Last Name <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='lastName'
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <p style={{ color: 'red' }}>{formik.errors.lastName}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='email'
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p style={{ color: 'red' }}>{formik.errors.email}</p>
                ) : null}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>
                  Phone Number <span className='text-danger'>*</span>
                </label>
                <input
                  className='form-control'
                  type='text'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  name='phone'
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p style={{ color: 'red' }}>{formik.errors.phone}</p>
                ) : null}
              </div>
            </div>
            <div className='border-t-c'>
              <div className='col-md-12'>
                <div className='mt-3'>
                  <button
                    className='btn custom_btn  mr-3 btn-md'
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  <button
                    className='btn btn-primary btn-md'
                    disabled={
                      !formik.isValid ||
                      (Object.keys(formik.touched).length === 0 &&
                        formik.touched.constructor === Object)
                    }
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddAdmin;
