import React from 'react';

const Report_view_issue = () => {
  return (
    <>
      {/* <!-- Page Wrapper --> */}
      <div class='page-wrapper'>
        {/* <!-- Page Content --> */}
        <div class='content container-fluid'>
          {/* <!-- Page Header --> */}

          <div class='page-header'>
            <div class='row align-items-center d-flex'>
              <div class='col-md-5'>
                <h3 class='page-title'>Onward Homes</h3>
              </div>
              <div class='col-md-4'>
                <div class='form-group mb-0'>
                  <select class='form-control' id=''>
                    <option>0001</option>
                    <option>0002</option>
                  </select>
                </div>
              </div>
              <div class='col-md-3 d-flex justify-content-end'>
                <h4>Issue 1</h4>
              </div>
            </div>
          </div>

          {/* <!-- /Page Header --> */}
          <div class='row'>
            <div class='col-md-12 d-flex'>
              <div class=' card-table flex-fill'>
                <div class='card-body'>
                  <div class='table-responsive'>
                    <table class=' table table-stripped mb-0'>
                      <thead class='custom_color'>
                        <tr>
                          <th>Issue</th>
                          <th>Image</th>
                          <th colspan='3'>Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class='text-middle-custom'>
                          <td class='text-center'>1</td>
                          <td class='table-td-img'>
                            <img
                              src='assets/img/profiles/avatar-21.jpg'
                              alt=''
                            />
                          </td>
                          <td class='comment-scroll-hide'>
                            <div class='comment-part-td slimscroll'>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit.Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit.Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit.Lorem ipsum dolor sit
                              amet, consectetur adipiscing elit.Lorem ipsum
                              dolor sit amet, consectetur adipiscing elit.Lorem
                              ipsum dolor sit amet, consectetur adipiscing
                              elit.Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit.Lorem ipsum dolor sit amet,
                              consectetur adipiscing elit.
                            </div>
                          </td>
                          <td class='text-right'>
                            <a class='blue-text-an' href='#'>
                              Edit
                            </a>
                          </td>
                          <td class='arrow_end'>
                            <i class='la la-trash'></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class='row mt-4'>
                    <div class='col-md-12 d-flex justify-content-end'>
                      <button class='blue-custom-btn'>Save</button>
                      <button class='blue-custom-btn'>
                        Export / View as PDF
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Page Content --> */}
      </div>
      {/* <!-- /Page Wrapper --> */}
    </>
  );
};

export default Report_view_issue;
